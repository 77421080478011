import circle1 from '../../assets/images/aimtell/circle_1.png';
import circle2 from '../../assets/images/aimtell/circle_2.png';
import circle3 from '../../assets/images/aimtell/circle_3.png';
import circle4 from '../../assets/images/aimtell/circle_4.png';
import iconArrowRight from '../../assets/images/icons/icon-arrow-right-white.png';

export const useStyles = () => ({
	aimtellBanner: {
		backgroundImage: 'linear-gradient(to right, #313897 , #27D294)',
		position: 'relative',
		cursor: 'pointer',
	},
	aimtellBgC1: {
		display: 'block',
		width: '6%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: '12%',
		backgroundImage: `url(${circle1})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top center',
	},
	aimtellBgC2: {
		display: 'block',
		width: '24%',
		height: '100%',
		position: 'absolute',
		bottom: 0,
		left: '20%',
		backgroundImage: `url(${circle2})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'bottom center',
	},
	aimtellBgC3: {
		display: 'block',
		width: '3%',
		height: '100%',
		position: 'absolute',
		bottom: 0,
		right: '32%',
		backgroundImage: `url(${circle3})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'bottom center',
	},
	aimtellBgC4: {
		display: 'block',
		width: '30%',
		height: '100%',
		position: 'absolute',
		bottom: 0,
		right: 0,
		backgroundImage: `url(${circle4})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top right',
	},
	aimtellBannerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
		maxWidth: 1150,
		padding: 10,
		position: 'relative',
		zIndex: 10,
	},
	aimtellBannerText: {
		fontSize: 24,
		fontWeight: '700',
		lineHeight: '36px',
		color: '#ffffff',
		maxWidth: 460,
		textAlign: 'center',
	},
	aimtellBannerClock: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px 30px',
	},
	aimtellBannerClocktem: {
		minWidth: 80,
		minHeight: 80,
		margin: '0 2px',
		background: '#22276B',
		borderRadius: 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 5,
		fontSize: 22,
		fontWeight: 700,
		color: '#ffffff',
	},
	aimtellBannerClocktemText: {
		fontSize: 10,
		fontWeight: 600,
		color: '#A8ACE3',
	},
	aimtellBannerCTA: {
		position: 'relative',
		marginLeft: 10,
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '17px',
		color: '#ffffff',
		background: '#FF8F00',
		borderRadius: 30,
		padding: '15px 60px 15px 30px',
		minWidth: 150,
		textAlign: 'center',
		'&::after': {
			content: '""',
			display: 'block',
			width: 16,
			height: 16,
			position: 'absolute',
			top: 14,
			right: 30,
			backgroundImage: `url(${iconArrowRight})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		}
	},
	'@media (max-width: 959px)': {
		aimtellBgC4: {
			display: 'none',
		},
		aimtellBannerClocktem: {
			minWidth: 60,
			minHeight: 60,
		},
	}
});