import React from 'react';

function Crown() {

	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_3039_35023)">
				<path fillRule="evenodd" clipRule="evenodd" d="M8.00145 0.400026C8.21315 0.400573 8.40889 0.512638 8.51654 0.694921L11.4239 5.61802L14.6228 3.03333C14.8026 2.88804 15.0499 2.85898 15.2585 2.95861C15.4671 3.05825 15.5999 3.26886 15.5999 3.50002V11.5C15.5999 11.8314 15.3313 12.1 14.9999 12.1H0.999902C0.668532 12.1 0.399902 11.8314 0.399902 11.5V3.50002C0.399902 3.26831 0.533333 3.0573 0.742678 2.95796C0.952023 2.85862 1.19986 2.8887 1.37935 3.03525L4.54194 5.61725L7.48485 0.692257C7.59344 0.510532 7.78976 0.39948 8.00145 0.400026ZM0.999902 13.9C0.668532 13.9 0.399902 14.1687 0.399902 14.5C0.399902 14.8314 0.668532 15.1 0.999902 15.1H14.9999C15.3313 15.1 15.5999 14.8314 15.5999 14.5C15.5999 14.1687 15.3313 13.9 14.9999 13.9H0.999902Z" fill="#525B60"/>
			</g>
			<defs>
				<clipPath id="clip0_3039_35023">
					<rect width="16" height="16" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default Crown;