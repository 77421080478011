import React from 'react';
import PropTypes from 'prop-types';

function TechAdvisor({fillColor}) {

	return (
		<svg width="122" height="34" viewBox="0 0 122 34" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.68042 11.4863V13.9376H4.15061V22.3768H6.9563V13.9376H10.4117V11.4863H0.68042Z" fill={fillColor || '#D50918'}/>
			<path d="M19.988 22.3694V19.9255H14.8122V18.0797H19.8698V15.6358H14.8122V13.9376H19.988V11.4863H12.0065V22.3694H19.988Z" fill={fillColor || '#D50918'}/>
			<path d="M27.1425 22.5694C29.771 22.5694 31.1886 21.0484 31.89 19.6455L29.4756 18.5085C29.1139 19.3724 28.1836 20.0885 27.1425 20.0885C25.3631 20.0885 24.1079 18.7152 24.1079 16.9358C24.1079 15.1564 25.3631 13.7831 27.1425 13.7831C28.1836 13.7831 29.1139 14.4993 29.4756 15.3632L31.89 14.2187C31.1738 12.7864 29.771 11.2949 27.1425 11.2949C23.7978 11.2949 21.2505 13.5616 21.2505 16.9211C21.2505 20.2879 23.7978 22.5694 27.1425 22.5694Z" fill={fillColor || '#D50918'}/>
			<path d="M43.2899 22.3694V11.4863H40.4769V15.5546H35.9582V11.4863H33.1526V22.3694H35.9582V17.9985H40.4769V22.3694H43.2899Z" fill={fillColor || '#D50918'}/>
			<path d="M65.4772 19.9255H63.7125V13.9376H65.4919C67.4855 13.9376 68.497 15.2445 68.497 16.9205C68.497 18.5375 67.3821 19.9255 65.4772 19.9255ZM65.4919 22.3694C68.9031 22.3694 71.3691 20.2947 71.3691 16.9205C71.3691 13.5463 68.9031 11.4863 65.4772 11.4863H60.9069V22.3694H65.4919Z" fill={fillColor || '#D50918'}/>
			<path d="M86.8373 11.4863H84.0316V22.3694H86.8373V11.4863Z" fill={fillColor || '#D50918'}/>
			<path d="M93.1869 22.5697C96.1107 22.5697 97.7203 21.1151 97.7203 18.9297C97.7203 14.8836 91.5699 15.8951 91.5699 14.507C91.5699 14.0492 91.9317 13.7244 92.7439 13.7244C93.8219 13.7244 95.0106 14.1009 95.9261 14.8466L97.4619 12.8384C96.3027 11.8416 94.7891 11.3174 93.0392 11.3174C90.2483 11.3174 88.6978 12.9491 88.6978 14.7802C88.6978 18.974 94.8629 17.7852 94.8629 19.2693C94.8629 19.7935 94.2722 20.1701 93.3124 20.1701C91.8283 20.1701 90.6175 19.5203 89.8348 18.7377L88.3508 20.842C89.4509 21.883 90.9866 22.5697 93.1869 22.5697Z" fill={fillColor || '#D50918'}/>
			<path d="M104.484 20.0889C102.675 20.0889 101.515 18.7008 101.515 16.9361C101.515 15.1567 102.675 13.7834 104.484 13.7834C106.278 13.7834 107.437 15.1567 107.437 16.9361C107.437 18.7008 106.278 20.0889 104.484 20.0889ZM104.484 22.5697C107.813 22.5697 110.294 20.2513 110.294 16.9435C110.294 13.6358 107.813 11.3174 104.484 11.3174C101.154 11.3174 98.658 13.6358 98.658 16.9435C98.658 20.2513 101.161 22.5697 104.484 22.5697Z" fill={fillColor || '#D50918'}/>
			<path d="M116.504 16.2717H114.281V13.9386H116.504C117.235 13.9386 117.855 14.3594 117.855 15.0978C117.862 15.8657 117.242 16.2717 116.504 16.2717ZM120.764 22.3704L118.541 18.376C119.553 18.0142 120.712 17.0027 120.712 15.1126C120.712 13.0895 119.339 11.4873 116.925 11.4873H111.476V22.3704H114.281V18.7156H115.714L117.545 22.3704H120.764Z" fill={fillColor || '#D50918'}/>
			<path d="M53.9296 10.4756L47.9269 22.385H51.1091L53.937 16.6407L56.7648 22.385H59.947L53.9296 10.4756Z" fill={fillColor || '#D50918'}/>
			<path d="M79.7196 11.4863L76.8917 17.2306L74.0639 11.4863H70.8817L76.877 23.4031L82.9018 11.4863H79.7196Z" fill={fillColor || '#D50918'}/>
		</svg>
	);
}

TechAdvisor.propTypes = {
	fillColor: PropTypes.string
};

export default TechAdvisor;