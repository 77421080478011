import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withNamespaces } from 'react-i18next';
import { Container, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './CouponSectionV3Styles';
import CouponBoxV3 from './CouponBoxV3';

function CouponSectionV3({
	t,
	classes,
	planConfig,
	selectedPackage,
	selectedPaymentMethod,
	isCouponCodeFieldOpened,
	couponCode,
	couponByCode,
	couponValidPlan,
	couponByCodeIsLoading,
	couponMonthly,
	couponYearly,
	couponYearly2,
	onChangeFreemiumInterstitialStep,
	onOpenCouponCodeField,
	onChangeCouponCodeField,
	onSubmitCouponCode
}) {
	return selectedPackage.packageIdentifier ? (
		<Container maxWidth="md" component="div" className={classes.planFreeOpenBtnContainer}>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<CouponBoxV3 
					classes={classes} 
					selectedPackage={selectedPackage} 
					selectedPaymentMethod={selectedPaymentMethod}
					couponCode={couponCode}
					couponByCode={couponByCode}
					couponValidPlan={couponValidPlan}
					couponMonthly={couponMonthly}
					couponYearly={couponYearly}
					couponYearly2={couponYearly2}
					couponByCodeIsLoading={couponByCodeIsLoading}
					isCouponCodeFieldOpened={isCouponCodeFieldOpened}
					onOpenCouponCodeField={onOpenCouponCodeField} 
					onChangeCouponCodeField={onChangeCouponCodeField} 
					onSubmitCouponCode={onSubmitCouponCode} />

				{planConfig.showFreeBtn ? 
					<Box className={classes.planFreeOpenBtnContainer}>
						<div className={clsx(classes.planButton)} onClick={() => onChangeFreemiumInterstitialStep(1)}>{t('Try Free Plan')}</div>
					</Box> : null
				}
			</Box>
		</Container> 
	) : null;
}

CouponSectionV3.propTypes = {
	selectedPaymentMethod: PropTypes.string,
	couponYearly: PropTypes.object,
	couponMonthly: PropTypes.object,
	couponYearly2: PropTypes.object,
	couponCode: PropTypes.string,
	couponByCode: PropTypes.object,
	couponValidPlan: PropTypes.string,
	couponByCodeIsLoading: PropTypes.bool,
	isCouponCodeFieldOpened: PropTypes.bool,
	planConfig: PropTypes.object,
	selectedPackage: PropTypes.object,
	classes: PropTypes.object,
	onChangeCouponCodeField: PropTypes.func,
	onSubmitCouponCode: PropTypes.func,
	onOpenCouponCodeField: PropTypes.func,
	onChangeFreemiumInterstitialStep: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(CouponSectionV3));