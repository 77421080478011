import iconMoneyBack from '../../../assets/images/icon-moneyback-guarantee-check.png';

export const useStyles = () => ({
	bold: {
		fontWeight: 700,
	},
	center: {
		textAlign: 'center',
	},
	interstitialWrapper: {
		fontFamily: 'Spartan, sans-serif',
		fontWeight: 400,
		color: '#283339',
		width: '100%',
		maxWidth: 1076,
		minHeight: 230,
		background: '#FFFFFF',
		boxSizing: 'border-box',
		boxShadow: '0px 10px 20px rgba(147, 153, 156, 0.15)',
		borderRadius: 16,
		margin: '20px auto',
	},
	header: {
		position: 'relative',
		fontSize: 18,
		fontWeight: 700,
		padding: '10px 30px',
		textAlign: 'center',
		color: '#ffffff',
		background: '#12AF5A',
		borderRadius: '16px 16px 0 0',
	},
	body: {
		padding: '40px 30px',
	},
	title: {
		fontSize: 36,
		fontWeight: 700,
		textAlign: 'center',
		marginBottom: 15,
	},
	subTitle: {
		fontSize: 16,
		fontWeight: 500,
		textAlign: 'center',
		marginBottom: 50,
	},
	features: {
		display: 'flex',
		borderTop: '1px solid #93999C',
		paddingTop: 20,
		paddingBottom: 20,
	},
	feature: {
		borderRight: '1px solid #93999C',
		paddingRight: 20,
		marginRight: 20,
		flex: 'auto',
		'& li': {
			padding: 0,
		},
		'&:nth-last-child(1)': {
			border: 'none',
			paddingRight: 0,
			marginRight: 0,
		},
	},
	featureIcon: {
		minWidth: 45,
		marginTop: 0,
	},
	featureIconHead: {
		minWidth: 45,
		marginBottom: 15,
	},
	featureItemHead: {
		marginBottom: 15,
		'& span': {
			fontSize: 18,
			fontWeight: 700,
		},
		'& p': {
			fontSize: 14,
			fontWeight: 500,
			color: '#525B60',
		}
	},
	featureItemRow: {
		fontSize: 14,
		fontWeight: 600,
		color: '#525B60',
		'& span': {
			fontSize: 14,
			fontWeight: 600,
			color: '#525B60',
		},
	},
	featureItemRowIcons: {
		display: 'flex',
		alignItems: 'flex-end',
		'& img': {
			marginLeft: 10,
		}
	},
	btn: {
		background: '#FF8F00',
		borderRadius: 30,
		color: '#ffffff',
		cursor: 'pointer',
		maxWidth: 320,
		padding: 15,
		margin: '10px auto 20px',
		textAlign: 'center',
		fontSize: 14,
		fontWeight: 700,

	},
	mbgWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 20,
	},
	mbg: {
		position: 'relative',
		fontSize: 15,
		fontWeight: 700,
		lineHeight: '24px',
		color: '#283339',
		padding: '5px 15px 5px 30px',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 4,
			left: 0,
			width: 23,
			height: 22,
			backgroundSize: 'cover',
			backgroundImage: `url(${iconMoneyBack})`,
		},
	},
	recommendedWrapper: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		color: '#93999C',
		fontSize: 14,
		fontWeight: 700,
		padding: 10,
		marginBottom: 20,
		'&>*': {
			margin: '0 12px',
		}
	},
	linkGrey: {
		display: 'inline-block',
		color: '#93999C',
		fontSize: 13,
		fontWeight: 600,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	logosRow: {
		paddingTop: 5,
		paddingBottom: 5,
		fontSize: 14,
		lineHeight: '22px',
		color: '#93999C',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
	},
	logoCell: {
		margin: 4,
		flex: '1 1 auto',
		'& svg': {
			width: '100%',
			maxWidth: '100%',
		}
	},
	'@media (max-width: 959px)': {
		interstitialWrapper: {
			flexDirection: 'column',
			textAlign: 'center',
		},
		title: {
			fontSize: 24,
			marginTop: 30,
		},
		btn: {
			maxWidth: '100%',
		},
		features: {
			flexDirection: 'column',
			maxWidth: 400,
			margin: '0 auto',
			'& $feature': {
				border: 'none',
			}
		},
	},
});