import iconCheckGreen from '../../../assets/images/icon-check-green.png';
import iconError from '../../../assets/images/icon-error.png';
import iconCoupon from '../../../assets/images/icons/icon-coupon.png';

export const useStyles = () => ({
	planButton: {
		background: '#D4D6D7',
		borderRadius: 30,
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 700,
		color: '#ffffff',
		padding: '18px 30px',
		cursor: 'pointer',
	},
	couponContainer: {
		alignItems: 'flex-start',
	},
	couponInitBtn: {
		position: 'relative',
		color: '#525B60',
		fontSize: 14,
		fontWeight: 600,
		cursor: 'pointer',
		paddingLeft: 25,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-3px',
			left: 0,
			width: 24,
			height: 24,
			backgroundImage: `url(${iconCoupon})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	couponBtn: {
		color: '#fff',
		backgroundColor: '#ff8f00',
		border: 'none',
		padding: 5,
		fontSize: 12,
		fontWeight: 700,
		borderRadius: 6,
		textTransform: 'initial',
		lineHeight: '13px',
		maxHeight: '28px',
		boxShadow: 'none',
		marginTop: 1,
		marginLeft: 7,
		'&:hover': {
			backgroundColor: '#ff8f00',
			border: 'none',
		},
	},
	couponSection: {
		maxWidth: 350,
		'& .MuiOutlinedInput-root input.MuiOutlinedInput-input': {
			fontSize: 12,
			padding: 6
		}
	},
	couponSectionMessagesBox: {
		paddingTop: '0px !important',
		paddingBottom: '0px !important',
	},
	couponCodeMessage: {
		fontSize: 11,
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
	},
	couponCodeSuccess: {
		position: 'relative',
		color: '#28D799',
		paddingLeft: 20,
		marginTop: 3,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			left: 0,
			width: 16,
			height: 14,
			backgroundImage: `url(${iconCheckGreen})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	couponCodeError: {
		position: 'relative',
		color: '#E52866',
		paddingLeft: 20,
		marginTop: 3,
		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			display: 'block',
			width: 15,
			height: 14,
			backgroundImage: `url(${iconError})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		}
	},

	'@media (max-width: 500px)': {
		couponContainer: {
			alignItems: 'flex-start',
			flexDirection: 'column',
		}
	},
	'@media (max-width: 460px)': {
		couponSection: {
			width: '100%',
			maxWidth: '100%',
			marginBottom: 10,
		}
	},
});