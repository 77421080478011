import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api';

export const packagesSlice = createSlice({
	name: 'packages',
	initialState: {
		packagesIsLoading: false,
		packagesList: [],
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(api.endpoints.onGetPackagesList.matchPending, (state) => {
				state.packagesIsLoading = true;
			})
			.addMatcher(api.endpoints.onGetPackagesList.matchFulfilled, (state, { payload }) => {
				state.packagesList = payload;
				state.packagesIsLoading = false;
			})
			.addMatcher(api.endpoints.onCheckSecurity.matchFulfilled, (state, { payload }) => {
				if (!payload.is_blocked) {
					state.packagesIsLoading = true;
				}
			})
			.addMatcher(api.endpoints.onGetPackagesList.matchRejected, (state) => {
				state.packagesList = [];
				state.packagesIsLoading = false;
			});
	},
});

export default packagesSlice.reducer;