import iconQuestionMark from '../../../assets/images/icon-question-mark.png';
import iconInfoImg from '../../../assets/images/icon-search-cc.png';
import iconError from '../../../assets/images/icon-error.png';

export const useStyles = () => ({
	hide: {
		display: 'none',
	},
	errorField: {

	},
	errorIcon: {
		display: 'block',
		position: 'relative',
		top: '0px',
		paddingLeft: 25,
		fontSize: 11,
		fontWeight: 600,
		color: '#E52866',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-2px',
			left: 3,
			width: 15,
			height: 13,
			backgroundImage: `url(${iconError})`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},

	},
	expDate: {

	},
	formRoot: {

	},
	fieldContainer: {
		marginTop: 23,
	},
	fieldLabel: {
		width: 'calc(100% + 15px)',
		fontSize: 12,
		color: '#283339',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		textTransform: 'uppercase',
	},
	expDateLabel: {
		letterSpacing: 0.24,
	},
	iframeLabel: {
		display: 'block',
		fontSize: 13,
		fontWeight: 700,
		color: '#283339',
		marginBottom: 6,
	},
	cvvContainer: {
		position: 'relative',
		'& $errorText': {
			top: 80,
		}
	},
	cvvWrapper: {
		height: 53,
		width: '100%',
		position: 'relative',
		borderRadius: 6,
		background: '#ffffff',
		border: '1px solid #D4D6D7',
		'&$errorField': {
			border: '1px solid #E52866',
		},
	},
	cvvInfo: {
		display: 'none',
		position: 'absolute',
		bottom: '30px',
		right: '-60px',
		zIndex: 20,
		width: 250,
		padding: 20,
		fontSize: 12,
		color: '#333333',
		fontWeight: 400,
		fontFamily: 'Spartan, sans-serif',
		boxSizing: 'border-box',
		background: '#ffffff',
		borderRadius: 10,
		boxShadow: '-4.33px 2.5px 6.5px rgba(1,1,1,0.17)',
		'&::after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			bottom: '-5px',
			right: 63,
			width: 15,
			height: 15,
			background: '#ffffff',
			transform: 'rotate(45deg)',
		},
	},
	cvvInfoImg: {
		maxWidth: 37,
		minWidth: 37,
		height: 27,
		margin: '3px 10px 0 0',
		backgroundImage: `url(${iconInfoImg})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	cvvInfoIcon: {
		position: 'absolute',
		top: '-2px',
		left: 35,
		zIndex: 10,
		width: 16,
		height: 17,
		backgroundImage: `url(${iconQuestionMark})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		'&:hover $cvvInfo': {
			display: 'flex',
		},
	},
	numberContainer: {
		position: 'relative',
		'& $errorText': {
			top: 83,
		}

	},
	numberWrapper: {
		height: 53,
		width: '100%',
		borderRadius: 6,
		background: '#ffffff',
		border: '1px solid #D4D6D7',
		'&$errorField': {
			border: '1px solid #E52866',
		},

	},
	checkboxError: {
		color: '#f44336',
	},
	errorText: {
		position: 'absolute',
		top: 71,
		left: 0,
		width: '100%',
		color: '#f44336',
		fontSize: '0.75rem',
		lineHeight: 1,
	},
	paymentWallMethodsWrapper: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	paymentWallMethodItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		minWidth: 70,
		maxWidth: 70,
		height: 48,
		padding: 5,
		fontSize: 12,
		fontWeight: 600,
		color: '#283339',
		textAlign: 'center',
		borderRadius: 4,
		border: '1px solid #E9EAEB',
		margin: 3,
		cursor: 'pointer',
	},
	paymentWallMethodItemSelected: {
		border: '2px solid #767CD4',
		padding: 4,
		cursor: 'initial',
	},
	paymentWallIcon: {
		display: 'block',
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		margin: '0 auto',
	},
	countryWarning: {
		fontSize: 14,
		fontWeight: 500,
		lineHeight: '20px',
		textAlign: 'center',
	},
	'@media (max-width: 760px)': {
		cvvInfo: {
			position: 'absolute',
			bottom: '30px',
			right: '-30px',
			'&::after': {
				content: '""',
				position: 'absolute',
				bottom: '-5px',
				right: 33,
			},
		},
	},
	'@media (max-width: 600px)': {
		cvvInfo: {
			position: 'absolute',
			bottom: '30px',
			left: '-40px',
			right: 'auto',
			'&::after': {
				content: '""',
				position: 'absolute',
				bottom: '-5px',
				left: 40,
				right: 'auto',
			},
		},
	},
});