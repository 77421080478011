import React, {useState, useEffect, useRef} from 'react';
import { 
	Container,
	AppBar,
	Toolbar,
	Typography,
	Box 
} from '@material-ui/core';
import clsx  from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './HeaderStyles';
import { LANGUAGES } from '../../config/main';
import { withNamespaces } from 'react-i18next';
import Crown from '../UI/svg/Crown';
import MenuIcon from '../UI/svg/MenuIcon';

import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import privadovpnLogo from '../../assets/images/privadovpn-logo.png';
import privadovpnLogoDark from '../../assets/images/privadovpn-logo-dark.png';

const { REACT_APP_WEB, REACT_APP_URL } = process.env;

function Header({
	t,
	V3,
	logoUrl, 
	appUrl, 
	centered, 
	showSubHeader,
	queryParams,
	onChangeLanguage
}) {
	const [isRightMenuOpened, handleToggleRightMenu] = useState(false);
	const [isLeftMenuOpened, handleToggleLeftMenu] = useState(false);
	const rightMenuRef = useRef(null);
	const leftMenuRef = useRef(null);
	const classes = useStyles();
	const _privadovpnLogo = V3 ? privadovpnLogoDark : privadovpnLogo;
	const _logoUrl = logoUrl ? (REACT_APP_WEB + logoUrl) : appUrl ? (REACT_APP_URL + appUrl) : '';

	useEffect(() => {
		function hideMenu(event) {
			if (rightMenuRef.current && !rightMenuRef.current.contains(event.target)) {
				handleToggleRightMenu(false);
			}
			if (leftMenuRef.current && !leftMenuRef.current.contains(event.target)) {
				handleToggleLeftMenu(false);
			}
		}

		document.addEventListener('mousedown', hideMenu);
		return () => {
			document.removeEventListener('mousedown', hideMenu);
		};
	}, [rightMenuRef]);

	const toggleRightMenu = () => {
		handleToggleRightMenu(!isRightMenuOpened);
	};

	const toggleLeftMenu = () => {
		handleToggleLeftMenu(!isLeftMenuOpened);
	};

	return (
		<>
			<AppBar position="static" color="default" elevation={0} className={clsx(classes.appBar, {[classes.V3]: V3})}>
				<Container maxWidth={V3 ? 'xl' : 'lg'} component="main">
					<Toolbar className={classes.toolbar}>
						<Typography variant="h6" color="inherit" noWrap className={clsx(classes.toolbarTitle, {[classes.logoCenter]: centered})}>
							{_logoUrl ?
								<a color="textPrimary" href={_logoUrl} rel="noopener noreferrer" className={classes.logoLink}>
									<img src={_privadovpnLogo} alt="logo" className={classes.logo} />
								</a> :
								<div className={classes.logoLink}>
									<img src={_privadovpnLogo} alt="logo" className={classes.logo} />
								</div>
							}
						</Typography>
						{!showSubHeader && LANGUAGES.length ?
							<LanguageSwitcher
								V3={V3}
								languages={LANGUAGES}
								onChangeLanguage={onChangeLanguage}
							/> : null
						}
					</Toolbar>
				</Container>
			</AppBar>
			{showSubHeader && queryParams?.username ?
				<Box className={classes.subHeaderWrapper}>
					<Container maxWidth="lg" className={classes.subHeaderContainer}>
						<Box className={clsx(classes.subHeaderLeft, {[classes.subHeaderLeftMenuActive]: isLeftMenuOpened})} ref={leftMenuRef}>
							<Box className={classes.userBarItem}>
								<span className={classes.userAvatar}>{(queryParams?.username || '').substr(2, 1)}</span> {queryParams?.username}
							</Box>
							{queryParams?.username ? <Box className={classes.userBarSeparator} /> : null}
							<span className={clsx(classes.arrowBtn)} onClick={toggleLeftMenu}></span>
							<ul className={clsx(classes.subHeaderMenuWrapper, classes.subHeaderMenuLeft)}>
								<li className={classes.userBarItem}>
									{queryParams?.email || ''}
								</li>
								<li className={clsx(classes.userBarItem, classes.userBarItemBg)}>
									{(queryParams?.current_plan || '').indexOf('PREMIUM') > -1 ? <Crown /> : null}
									{(queryParams?.current_plan || '').indexOf('PREMIUM') > -1 ? 'PREMIUM' : 'FREE'}
								</li>
							</ul>
						</Box>
						<Box className={clsx(classes.subHeaderRight, {[classes.subHeaderRightMenuActive]: isRightMenuOpened})} ref={rightMenuRef}>
							<span className={clsx(classes.kebabMenu)} onClick={toggleRightMenu}>
								<MenuIcon />
							</span>
							<ul className={clsx(classes.subHeaderMenuWrapper, classes.subHeaderMenuRight)}>
								<li>
									<a color="textPrimary" href={REACT_APP_WEB + '/software'} target="_blank" rel="noopener noreferrer" onClick={() => handleToggleRightMenu(false)}>
										{t('Download VPN')}
									</a>
								</li>
								<li>
									<a color="textPrimary" href="https://blog.privadovpn.com/" target="_blank" rel="noopener noreferrer" onClick={() => handleToggleRightMenu(false)}>
										{t('Blog')}
									</a>
								</li>
								<li>
									<a color="textPrimary" href="https://support.privadovpn.com/home/" target="_blank" rel="noopener noreferrer" onClick={() => handleToggleRightMenu(false)}>
										{t('Support')}
									</a>
								</li>
							</ul>
							{LANGUAGES.length ?
								<LanguageSwitcher
									V3={true}
									languages={LANGUAGES}
									onChangeLanguage={onChangeLanguage}
								/> : null
							}
						</Box>
					</Container>
				</Box>: null
			}
		</>
		
	);
}

Header.propTypes = {
	V3: PropTypes.bool,
	centered: PropTypes.bool,
	showSubHeader: PropTypes.bool,
	queryParams: PropTypes.object,
	logoUrl: PropTypes.string,
	appUrl: PropTypes.string,
	onChangeLanguage: PropTypes.func,
	t: PropTypes.func
};

export default withNamespaces()(Header);