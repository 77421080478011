import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	'@global': {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: 'none',
		},
	},
	appBar: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		background: '#151935',
	},
	toolbar: {
		padding: 0,
	},
	toolbarTitle: {
		flexGrow: 1,
	},
	logoCenter: {
		textAlign: 'center',
	},
	logoLink: {
		textDecoration: 'none',
		color: '#ddd',
		display: 'inline-block',
		margin: '15px 0 !important',
		padding: '0 !important',
		maxHeight: 49,
		'&:hover': {
			textDecoration: 'none',
		},
	},
	logo: {
		width: 250,
		maxWidth: '100%',
		border: 'none',
		verticalAlign: 'middle',
	},
	V3: {
		'&$appBar': {
			background: '#ffffff',
		},
	},
	subHeaderWrapper: {
		marginLeft: '-15px',
		marginRight: '-15px',
	},
	subHeaderContainer: {
		paddingTop: 15,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	subHeaderLeft: {
		position: 'relative',
		display:'flex',
		alignItems: 'center',
	},
	userBarItem: {
		display: 'flex',
		alignItems: 'center',
		color: '#525B60',
		fontSize: 14,
		fontWeight: 600,
		'& svg': {
			marginTop: '-5px',
			marginRight: 5,
		}
	},
	userBarItemBg: {
		background: '#D4D6D7',
		padding: '10px 16px 6px',
		borderRadius: 22,
		marginLeft: 10,
	},
	userBarSeparator: {
		width: 0,
		height: 32,
		borderLeft: '1px solid #D4D6D7',
		margin: '0 15px',
	},
	userAvatar: {
		textTransform: 'uppercase',
		minWidth: 40,
		height: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#023992',
		borderRadius: 20,
		fontSize: 14,
		lineHeight: '26px',
		fontWeight: 600,
		color: '#ffffff',
		paddingTop: 5,
		marginRight: 5,
	},
	subHeaderRight: {
		position: 'relative',
		display:'flex',
		alignItems: 'center',
	},
	subHeaderMenuWrapper: {
		display:'flex',
		alignItems: 'center',
		marginTop: 3,
		marginRight: 10,
		whiteSpace: 'nowrap',
		'& a': {
			color: '#525B60',
			padding: '5px 10px',
			fontSize: 14,
			lineHeight: 2,
			fontWeight: 600,
			textDecoration: 'none',
		}
	},
	kebabMenu: {
		height: 24,
		cursor: 'pointer',
		display: 'none',
	},
	arrowBtn: {
		width: 9,
		height: 9,
		marginLeft: 10,
		cursor: 'pointer',
		display: 'none',
		'&:after': {
			content: '""',
			width: 9,
			height: 9,
			background: 'url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 fill=%22%23525B60%22 viewBox=%220 0 14 22%22%3E%3Cpath d=%22M0 3l8 8-8 8 3 3L14 11 3 0z%22/%3E%3C/svg%3E") no-repeat 50%',
			transform: 'rotate(90deg)',
		},
	},
	'@media (max-width: 900px)': {
		userBarSeparator: {
			display: 'none',
		},
		subHeaderMenuLeft: {
			display: 'none',
			position: 'absolute',
			top: 35,
			left: 155,
			background: '#ffffff',
			alignItems: 'flex-start',
			flexDirection: 'column',
			boxShadow: '0 13px 32px rgba(109, 113, 115, 0.3)',
			borderRadius: 10,
			padding:  20,
		},
		userBarItemBg: {
			marginLeft: 0,
			marginTop: 10,
		},
		subHeaderLeftMenuActive: {
			'& $subHeaderMenuLeft': {
				display: 'flex',
			},
			'& $arrowBtn': {
				transform: 'rotate(-180deg)',
			},
		},
		arrowBtn: {
			display: 'flex',
		}
	},
	'@media (max-width: 1020px)': {
		subHeaderMenuRight: {
			display: 'none',
			position: 'absolute',
			top: 30,
			right:  80,
			background: '#ffffff',
			alignItems: 'flex-end',
			flexDirection: 'column',
			boxShadow: '0 13px 32px rgba(109, 113, 115, 0.3)',
			borderRadius: 10,
			padding:  20,
		},
		subHeaderRightMenuActive: {
			'& $subHeaderMenuRight': {
				display: 'flex',
			}
		},
		kebabMenu: {
			display: 'initial',
		}
	},
}));