import React from 'react';
import PropTypes from 'prop-types';

function VpnGids({fillColor}) {

	return (
		<svg width="103" height="33" viewBox="0 0 103 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2426_124530)">
				<path d="M11.6902 12.2285H15.7665V16.3048H11.6902V12.2285Z" fill={fillColor || '#43C09A'}/>
				<path d="M15.7664 16.3049V17.324C15.7373 18.4595 15.4753 19.4786 15.1259 20.3812H11.6901V16.3049H15.7664ZM7.61387 12.2286H11.6901V16.3049H7.61387V12.2286ZM3.5376 8.15234H7.61387V12.2286H3.5376V8.15234Z" fill={fillColor || '#2892D7'}/>
				<path d="M7.61401 8.15234H11.6903V12.2286H7.61401V8.15234Z" fill={fillColor || '#43C09A'}/>
				<path d="M15.0968 20.3817C14.9512 20.7311 14.7765 21.0805 14.6018 21.4007C13.8157 22.7401 12.7384 23.7592 11.6902 24.5453C9.73939 25.9429 7.81772 26.467 7.75949 26.467C7.70125 26.4961 7.64302 26.4961 7.61391 26.4961C7.55567 26.4961 7.49744 26.4961 7.46832 26.467C7.38098 26.4378 -0.509521 24.2832 -0.509521 16.946V8.15286H3.56675V12.2291H7.64302V16.3054H11.7193V20.3817H15.0968ZM13.7283 7.13379H16.7855V10.191H13.7283V7.13379Z" fill={fillColor || '#223770'}/>
				<path d="M17.8337 6.11426H19.8719V8.15239H17.8337V6.11426Z" fill={fillColor || '#43C09A'}/>
				<path d="M18.3286 9.17188H20.8617V11.705H18.3286V9.17188Z" fill={fillColor || '#2892D7'}/>
				<path d="M30.2509 12.6455H32.7087L28.4986 21.5525H27.7248L23.5115 12.6455H25.953L28.102 17.5624L30.2509 12.6455Z" fill={fillColor || '#223770'}/>
				<path d="M41.4086 13.7422C42.1921 14.6026 42.5854 15.6823 42.5854 16.9813C42.5854 18.2803 42.1921 19.3667 41.4086 20.2405C40.6218 21.1142 39.64 21.5494 38.4598 21.5494C37.3935 21.5494 36.545 21.1973 35.9143 20.4963V24.4996H33.593V12.6458H35.7257V13.7355C36.3109 12.8817 37.2212 12.4531 38.4598 12.4531C39.6985 12.4531 40.6218 12.8817 41.4086 13.7455V13.7422ZM40.2317 17.0013C40.2317 16.287 40.0269 15.7089 39.614 15.2637C39.2011 14.8186 38.6744 14.596 38.034 14.596C37.3935 14.596 36.9026 14.8053 36.4962 15.2272C36.0898 15.6491 35.885 16.2338 35.885 16.9847C35.885 17.7355 36.0898 18.3235 36.4962 18.7488C36.9026 19.1774 37.4163 19.39 38.034 19.39C38.6517 19.39 39.1881 19.1674 39.6075 18.7222C40.0269 18.277 40.2349 17.7023 40.2349 17.0013H40.2317Z" fill={fillColor || '#223770'}/>
				<path d="M51.8508 15.9481V21.3401H49.5491V16.2637C49.5491 15.7621 49.4028 15.3534 49.1102 15.0445C48.8176 14.7355 48.4372 14.5794 47.9658 14.5794C47.4944 14.5794 47.1076 14.7355 46.8052 15.0445C46.5029 15.3534 46.3501 15.7621 46.3501 16.2637V21.3401H44.0288V12.6458H46.1615V13.4365C46.802 12.782 47.6342 12.4531 48.6551 12.4531C49.6759 12.4531 50.4204 12.7687 50.9926 13.4C51.5648 14.0312 51.8508 14.8817 51.8508 15.9481V15.9481Z" fill={fillColor || '#223770'}/>
				<path d="M60.0663 12.6452H62.199V20.3728C62.199 21.7648 61.8023 22.8379 61.0123 23.5887C60.2223 24.3395 59.1495 24.7116 57.797 24.7116C56.204 24.7116 54.8126 24.1734 53.6194 23.097L54.8386 21.4459C55.8236 22.1934 56.7469 22.5688 57.6052 22.5688C58.3042 22.5688 58.8569 22.3927 59.2633 22.0405C59.6696 21.6884 59.8745 21.1801 59.8745 20.5123V20.0372C59.2568 20.7283 58.392 21.0738 57.2801 21.0738C56.1 21.0738 55.1247 20.6685 54.3574 19.8612C53.5902 19.0538 53.2065 18.0173 53.2065 16.7515C53.2065 15.4858 53.5902 14.4392 54.3574 13.6419C55.1247 12.8446 56.1 12.4492 57.2801 12.4492C58.4602 12.4492 59.4453 12.8778 60.0663 13.7316V12.6419V12.6452ZM59.9102 16.7216C59.9102 16.0073 59.7054 15.4525 59.299 15.0605C58.8926 14.6685 58.379 14.4725 57.7613 14.4725C57.1436 14.4725 56.5941 14.6784 56.1813 15.0871C55.7684 15.4957 55.5636 16.0472 55.5636 16.7382C55.5636 17.4293 55.7716 17.9907 56.191 18.4259C56.6104 18.8578 57.1338 19.0771 57.7645 19.0771C58.3952 19.0771 58.8829 18.8678 59.2958 18.4459C59.7087 18.0239 59.9135 17.4492 59.9135 16.725L59.9102 16.7216Z" fill={fillColor || '#43C09A'}/>
				<path d="M63.7856 9.48289C63.7856 9.06096 63.9287 8.70881 64.2148 8.42974C64.5009 8.14735 64.852 8.00781 65.2649 8.00781C65.6778 8.00781 66.0289 8.14735 66.3215 8.42974C66.6141 8.71213 66.7604 9.06096 66.7604 9.48289C66.7604 9.90482 66.6141 10.237 66.3215 10.5194C66.0289 10.8018 65.6778 10.9414 65.2649 10.9414C64.852 10.9414 64.5041 10.8018 64.2148 10.5194C63.9254 10.237 63.7856 9.89485 63.7856 9.48289V9.48289ZM64.1107 21.34V12.6457H66.432V21.34H64.1107V21.34Z" fill={fillColor || '#43C09A'}/>
				<path d="M74.5108 8.16699H76.832V21.3397H74.6993V20.2666C74.1141 21.1204 73.2038 21.549 71.9652 21.549C70.7265 21.549 69.787 21.1171 69.01 20.2566C68.2297 19.3962 67.8396 18.3098 67.8396 16.9975C67.8396 15.6852 68.2297 14.5989 69.01 13.7384C69.7902 12.8779 70.7753 12.446 71.9652 12.446C73.0413 12.446 73.8898 12.7916 74.5108 13.4826V8.16699ZM73.9353 18.7583C74.3417 18.3364 74.5465 17.7517 74.5465 17.0008C74.5465 16.25 74.3417 15.662 73.9288 15.2367C73.5159 14.8082 73.0055 14.5955 72.3976 14.5955C71.7896 14.5955 71.2435 14.8181 70.8241 15.2633C70.4047 15.7085 70.1966 16.2832 70.1966 16.9842C70.1966 17.6852 70.4014 18.2799 70.8143 18.7218C71.2272 19.167 71.7539 19.3895 72.3943 19.3895C73.0348 19.3895 73.5257 19.1802 73.9321 18.7583H73.9353Z" fill={fillColor || '#43C09A'}/>
				<path d="M77.97 19.8651L79.1404 18.4265C79.8718 19.2106 80.6716 19.6026 81.5299 19.6026C81.8517 19.6026 82.1118 19.5428 82.3134 19.4199C82.5149 19.297 82.6125 19.1176 82.6125 18.885C82.6125 18.7089 82.554 18.5727 82.4337 18.473C82.3134 18.3734 82.0826 18.2471 81.7379 18.0943L80.8277 17.7089C80.0149 17.3701 79.4232 16.998 79.0558 16.5926C78.6885 16.1873 78.5064 15.6757 78.5064 15.0544C78.5064 14.2471 78.7925 13.6126 79.3647 13.1475C79.9369 12.6857 80.6944 12.4531 81.6339 12.4531C82.8953 12.4531 83.9129 12.885 84.6932 13.7521L83.526 15.2106C82.9538 14.6591 82.3166 14.3834 81.6177 14.3834C81.0097 14.3834 80.7074 14.5893 80.7074 14.998C80.7074 15.1608 80.7691 15.297 80.8959 15.4033C81.0227 15.5096 81.2568 15.6325 81.6014 15.7721L82.4272 16.1242C83.2757 16.4764 83.8836 16.8518 84.2575 17.2471C84.6281 17.6458 84.8167 18.1541 84.8167 18.7754C84.8167 19.6425 84.5013 20.3202 83.8706 20.8119C83.2529 21.3036 82.4629 21.5494 81.4974 21.5494C79.9726 21.5494 78.799 20.988 77.9732 19.8617L77.97 19.8651Z" fill={fillColor || '#43C09A'}/>
				<path d="M86.026 19.9177C86.026 19.4593 86.182 19.0739 86.4909 18.7583C86.7997 18.4427 87.1833 18.2832 87.6417 18.2832C88.1001 18.2832 88.4642 18.4427 88.7666 18.7583C89.0689 19.0739 89.2217 19.4626 89.2217 19.9177C89.2217 20.3729 89.0722 20.7749 88.7731 21.0872C88.474 21.3961 88.0969 21.5523 87.6385 21.5523C87.1801 21.5523 86.7965 21.3961 86.4876 21.0872C86.1788 20.7782 86.0227 20.3862 86.0227 19.9177H86.026Z" fill={fillColor || '#223770'}/>
				<path d="M98.6887 15.9481V21.3401H96.387V16.2637C96.387 15.7621 96.2407 15.3534 95.9481 15.0445C95.6555 14.7355 95.2751 14.5794 94.8037 14.5794C94.3323 14.5794 93.9455 14.7355 93.6431 15.0445C93.3408 15.3534 93.188 15.7621 93.188 16.2637V21.3401H90.8667V12.6458H92.9994V13.4365C93.6399 12.782 94.4721 12.4531 95.493 12.4531C96.5138 12.4531 97.2583 12.7687 97.8305 13.4C98.4026 14.0312 98.6887 14.8817 98.6887 15.9481V15.9481Z" fill={fillColor || '#223770'}/>
				<path d="M100.539 21.3397V8.16699H102.86V21.3397H100.539Z" fill={fillColor || '#223770'}/>
			</g>
			<defs>
				<clipPath id="clip0_2426_124530">
					<rect width="102.926" height="32.6102" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
}

VpnGids.propTypes = {
	fillColor: PropTypes.string
};

export default VpnGids;