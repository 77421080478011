import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './LogosStyles';
import clsx  from 'clsx';
import { withNamespaces } from 'react-i18next';
import {
	Typography,
	Box
} from '@material-ui/core';

import Techradar from '../svg/Techradar';
import TomsGuide from '../svg/TomsGuide';
import PcMag from '../svg/PcMag';
import DigitalTrends from '../svg/DigitalTrends';
import Forbes from '../svg/Forbes'; 
import SecurityOrg from '../svg/SecurityOrg';
import Chip from '../svg/Chip';
import Netzwelt from '../svg/Netzwelt';
import PcMagazin from '../svg/PcMagazin';
import ComputerBild from '../svg/ComputerBild';
import VpnGids from '../svg/VpnGids';

import TrustPilot from '../svg/TrustPilot';
import GooglePlay from '../svg/GooglePlay';
import AppStore from '../svg/AppStore';
import Amazon from '../svg/Amazon';

function Logos({ country, t }) {
	const classes = useStyles();

	return (
		<Box className={classes.logosContainer}>
			<Typography className={classes.logosTitle}>{t('Recommended by:')}</Typography>
			{country === 'DE' ?
				<Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<Chip />
						</Box>
						<Box className={classes.logoCell}>
							<Netzwelt />
						</Box>
						<Box className={classes.logoCell}>
							<PcMagazin />
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<ComputerBild />
						</Box>
						<Box className={classes.logoCell}>
							<Techradar />
						</Box>
						<Box className={classes.logoCell}>
							<DigitalTrends />
						</Box>
					</Box>
				</Box>: null
			}

			{country === 'NL' ?
				<Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<VpnGids />
						</Box>
						<Box className={classes.logoCell}>
							<Techradar />
						</Box>
						<Box className={classes.logoCell}>
							<PcMag />
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<DigitalTrends />
						</Box>
						<Box className={classes.logoCell}>
							<TomsGuide />
						</Box>
						<Box className={classes.logoCell}>
							<Forbes />
						</Box>
					</Box>
				</Box>: null
			}

			{country !== 'NL' && country !== 'DE' ?
				<Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<Techradar />
						</Box>
						<Box className={classes.logoCell}>
							<TomsGuide />
						</Box>
						<Box className={classes.logoCell}>
							<PcMag />
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<DigitalTrends />
						</Box>
						<Box className={classes.logoCell}>
							<Forbes />
						</Box>
						<Box className={classes.logoCell}>
							<SecurityOrg />
						</Box>
					</Box>
				</Box>: null
			}

			<div className={classes.logosSeparator}></div>

			<Box display="flex" justify="center" className={classes.logosRow}>
				<TrustPilot />
			</Box>

			<div className={classes.logosSeparator}></div>

			<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
				<Box className={classes.logoCell}>
					<GooglePlay />
				</Box>
				<Box className={classes.logoCell}>
					<AppStore />
				</Box>
				<Box className={clsx(classes.logoCell, classes.logoAmazon)}>
					<Amazon />
				</Box>
			</Box>
		</Box>
	);
}

Logos.propTypes = {
	country: PropTypes.string,
	t: PropTypes.func
};

export default withNamespaces()(memo(Logos));