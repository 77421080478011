import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import i18next from 'i18next';
import { LANGUAGES } from '../config/main';
import modules from './../services/modules';

const { utils } = modules;
const { REACT_APP_API, REACT_APP_API_KEY } = process.env;

const orderFormApi = axios.create({
	baseURL: `${REACT_APP_API}/api/v1/`,
	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' }
});

export const api = createApi({
	reducerPath: 'api',
	tagTypes: ['SecurityCheck', 'Currency', 'Packages'],
	endpoints: builder => ({
		onCheckSecurity: builder.mutation({
			queryFn: async (_args, { getState }) => {
				const _securityDetails = getState().securityCheck.securityDetails;
				try {
					const response = await orderFormApi.post(`security/check?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, {api_key: REACT_APP_API_KEY});
					if(response?.data?.status === 'OK') {
						const _data = response?.data?.data || {};
						_data.current_currency = 'USD';
						return { data: _data };
					} else {
						return { data: _securityDetails };
					}
				} catch (error) {
					if(error?.response?.data?.status === 'Forbidden') {
						utils.handleError('sch - Forbidden', error);
						return { data: {..._securityDetails, is_blocked: true} };
					} else {
						utils.handleError('sch', error);
						return { data: _securityDetails };
					}
				}
			},
			invalidatesTags: ['SecurityCheck'],
		}),
		onGetCurrency: builder.mutation({
			queryFn: async (_args, { getState }) => {
				const { countryCode, savedCurrency } = _args;
				try {
					const response = await orderFormApi.post('billing/get-currency', {api_key: REACT_APP_API_KEY, country_code: countryCode});
					let available_currencies = response.data?.data?.available_currencies || [];
					if(savedCurrency && available_currencies.indexOf(savedCurrency) === -1) {
						available_currencies.unshift(savedCurrency);
					}
					return {data: available_currencies};
				} catch (error) {
					return { data: getState().currency.availableCurrencies };
				}
			},
			invalidatesTags: ['Currency'],
		}),
		onGetPackagesList: builder.mutation({
			queryFn: async () => {
				try {
					const response = await orderFormApi.post(`packages?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, {api_key: REACT_APP_API_KEY});
					return { data: Array.isArray(response?.data?.data) ? response?.data?.data : [] };
				} catch (error) {
					utils.handleError('pac', error);
					return { data: [] };
				}
			},
			invalidatesTags: ['Packages'],
		}),
	})
});

export const { endpoints, useGetPackagesListQuery } = api;