import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const Blocked = ({t}) => (
	<div className="page-content blocked-page">
		<p>{t('Sorry, we cannot accept an order from you at this time.')}</p>
	</div>
);

Blocked.propTypes = {
	t: PropTypes.func
};

export default withNamespaces()(Blocked);