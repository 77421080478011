import React, { useEffect } from 'react';
import {
	Grid,
	Container,
	TextField,
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem,
	Box
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PaymentInfoV3 from '../PaymentInfoV3/PaymentInfoV3';
import { withNamespaces } from 'react-i18next';
import utils from '../../../services/utils';
import { PlanTypes } from '../../../config/plan-types';
import { CountryList } from '../../../config/countryList';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FormsV3Styles';

const { REACT_APP_SEPA_DIRECT_DEBIT } = process.env;

function SepaDirectDebitFormV3({
	t,
	mbg,
	isApplicableInTheCountry,
	upgradeMode,
	planConfig,
	savingPercent,
	saving2Percent,
	classes,
	saving,
	currency,
	country,
	selectedPackage,
	formFields,
	paymentFields,
	withPassword,
	fieldsError,
	fieldsTouched,
	btnDisabled,
	btnProcessing,
	couponCode,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponSavingPercent,
	couponRenewalPrice,
	couponMonthly,
	couponYearly,
	couponYearly2,
	isProxyDetected,
	allowedCountries,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onChangeFormFields,
	onChangeCountry,
	onBlur,
	onInit,
	onSubmit,
	selectedPaymentMethod
}) {

	useEffect(() => {
		if(!paymentFields[selectedPaymentMethod] && selectedPaymentMethod === REACT_APP_SEPA_DIRECT_DEBIT) {
			onInit({
				[selectedPaymentMethod] : {
					firstName: '',
					lastName: '',
					iban: '',
					address: '',
					city: '',
					postcode: '',
					termsAndConditions: false
				}
			});
		}
	}, [onInit, paymentFields, selectedPaymentMethod]);

	const _pf = paymentFields[selectedPaymentMethod] || {};
	const _pft = fieldsTouched[selectedPaymentMethod] || {};
	const _pfe = fieldsError[selectedPaymentMethod] || {};
	const _ff = {...formFields} || {};
	const _ft = {...fieldsTouched['formFields']} || {};
	const _fe = {...fieldsError['formFields']} || {};

	if(!withPassword) {
		delete _ff.password;
		delete _ft.password;
		delete _fe.password;
	}

	const _countryList = CountryList.filter(c => allowedCountries.indexOf(c.value) > -1).map(c => ({...c, viewValue: t(c.viewValue)})).sort((a, b) => (a.viewValue || '').toLowerCase().localeCompare((b.viewValue || '').toLowerCase()));
	const _couponByPlan = utils.executeCouponByPlan(selectedPackage, PlanTypes, couponYearly, couponYearly2, couponMonthly);
	const _paymentNotEligible = _couponByPlan.code && utils.checkPaymentEligibility(_couponByPlan.payment_methods, REACT_APP_SEPA_DIRECT_DEBIT);
	const disabled = (Object.keys(_pfe).length + Object.keys(_fe).filter(i => i !== 'termsAndConditions').length > 0) || (Object.keys(_ff).filter(i => i !== 'termsAndConditions').length + Object.keys(_pf).length !== Object.keys(_pft).length + Object.keys(_ft).filter(i => i !== 'termsAndConditions').length) || !country || btnDisabled || btnProcessing || _paymentNotEligible;

	return (
		<Container component="main">
			<Box pb={2}>
				<Grid container spacing={2}>
					<Grid item xs={12}  sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('First Name')}
							placeholder={t('Enter first name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="firstName"
							classes={{root: classes.fieldContainer}}
							value={_pf.firstName || ''}
							error={!!_pfe.firstName}
							helperText={(_pfe.firstName) ? <span className={classes.errorIcon}>{_pfe.firstName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'firstName')}
							onBlur={() => onBlur('firstName', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('Last Name')}
							placeholder={t('Enter last name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="lastName"
							classes={{root: classes.fieldContainer}}
							value={_pf.lastName || ''}
							error={!!_pfe.lastName}
							helperText={(_pfe.lastName) ? <span className={classes.errorIcon}>{_pfe.lastName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'lastName')}
							onBlur={() => onBlur('lastName', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('Address')}
							placeholder={t('Enter address')}
							InputLabelProps={{
								shrink: true,
							}}
							name="address"
							classes={{root: classes.fieldContainer}}
							value={_pf.address || ''}
							error={!!_pfe.address}
							helperText={(_pfe.address) ? <span className={classes.errorIcon}>{_pfe.address}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'address')}
							onBlur={() => onBlur('address', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('City')}
							placeholder={t('Enter city')}
							InputLabelProps={{
								shrink: true,
							}}
							name="city"
							classes={{root: classes.fieldContainer}}
							value={_pf.city || ''}
							error={!!_pfe.city}
							helperText={(_pfe.city) ? <span className={classes.errorIcon}>{_pfe.city}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'city')}
							onBlur={() => onBlur('city', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" classes={{root: classes.fieldContainer}} fullWidth error={!country}>
							<InputLabel shrink>{t('Country')}</InputLabel>
							<Select
								value={country || ''}
								onChange={e => onChangeCountry(e.target.value)}
								label={t('Select Country')}
								displayEmpty
								renderValue={
									country !== '' ? undefined : () => <div className={classes.placeholder}>{t('Select Country')}</div>
								}
							>
								{_countryList.map(c => (
									<MenuItem
										key={c.value}
										className={clsx(classes.countrySelectItem, {[classes.countrySelectItemActive]: c.value === country})}
										value={c.value}>{c.viewValue}</MenuItem>
								))}
							</Select>

							<FormHelperText>{(!country) ? <span className={classes.errorIcon}>{t('This field is required')}</span> : ''}</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="postcode"
							label={t('ZIP/Postal Code')}
							placeholder={t('Enter ZIP/Postal code')}
							InputLabelProps={{
								shrink: true,
							}}
							name="postcode"
							classes={{root: classes.fieldContainer}}
							value={_pf.postcode || ''}
							error={!!_pfe.postcode}
							helperText={(_pfe.postcode) ? <span className={classes.errorIcon}>{_pfe.postcode}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'postcode')}
							onBlur={() => onBlur('postcode', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="iban"
							label="IBAN"
							placeholder={t('Enter your IBAN')}
							InputLabelProps={{
								shrink: true,
							}}
							name="iban"
							classes={{root: classes.fieldContainer}}
							value={_pf.iban || ''}
							error={!!_pfe.iban}
							helperText={(_pfe.iban) ? <span className={classes.errorIcon}>{_pfe.iban}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'iban')}
							onBlur={() => onBlur('iban', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} style={{display: (isApplicableInTheCountry ? 'none' : 'block')}}>
						<div className={classes.countryWarning}>
							{t('Based on your current location, the selected payment method may not be available.')}
						</div>
					</Grid>

					<Grid item xs={12}>
						<PaymentInfoV3
							_pf={_pf}
							_pft={_pft}
							_pfe={_pfe}
							paymentNotEligible={_paymentNotEligible}
							paymentDisplayName="Sepa"
							saving={saving}
							savingPercent={savingPercent}
							saving2Percent={saving2Percent}
							selectedPackage={selectedPackage}
							currency={currency}
							couponByPlan={_couponByPlan}
							couponCode={couponCode}
							couponPrice={couponPrice}
							couponGracePeriod={couponGracePeriod}
							couponValidPlan={couponValidPlan}
							couponSavingPercent={couponSavingPercent}
							couponRenewalPrice={couponRenewalPrice}
							upgradeMode={upgradeMode}
							planConfig={planConfig}
							disabled={disabled}
							isProxyDetected={isProxyDetected}
							mbg={mbg}
							info={true}
							btnProcessing={btnProcessing}
							onChangePaymentMethod={onChangePaymentMethod}
							onChangeCouponCodeField={onChangeCouponCodeField}
							onChangeFormFields={onChangeFormFields}
							onSubmit={onSubmit}
						/>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}

SepaDirectDebitFormV3.propTypes = {
	i18n: PropTypes.object,
	mbg: PropTypes.bool,
	isApplicableInTheCountry: PropTypes.bool,
	upgradeMode: PropTypes.bool,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	currency: PropTypes.string,
	country: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.object,
	selectedPackage: PropTypes.object,
	formFields: PropTypes.object,
	paymentFields: PropTypes.object,
	withPassword: PropTypes.bool,
	fieldsError: PropTypes.object,
	fieldsTouched: PropTypes.object,
	btnDisabled: PropTypes.bool,
	btnProcessing: PropTypes.bool,
	selectedPaymentMethod: PropTypes.string,
	couponYearly: PropTypes.object,
	couponCode: PropTypes.string,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponMonthly: PropTypes.object,
	couponYearly2: PropTypes.object,
	isProxyDetected: PropTypes.bool,
	allowedCountries: PropTypes.array,
	onChangePaymentMethod: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onChangeCountry: PropTypes.func,
	onBlur: PropTypes.func,
	onInit: PropTypes.func,
	onSubmit: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(SepaDirectDebitFormV3));