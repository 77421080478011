import React from 'react';
import PropTypes from 'prop-types';
import clsx  from 'clsx';
import {
	Box
} from '@material-ui/core';

import TrustPilotVertical from '../../UI/svg/TrustPilotVertical';
import SafeAndSecure from '../../UI/svg/SafeAndSecure';

function LogosTrustV3({ classes }) {

	return (
		<Box className={classes.logosContainer}>
			<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
				<Box className={classes.logoCell}>
					<SafeAndSecure />
				</Box>
				<Box className={clsx(classes.logoCell, classes.logoAmazon)}>
					<TrustPilotVertical />
				</Box>
			</Box>
		</Box>
	);
}

LogosTrustV3.propTypes = {
	classes: PropTypes.object
};

export default LogosTrustV3;