import hsBgAlert from '../../assets/images/hs_bg_alert.png';
import bannerBg1 from '../../assets/images/banner_bg_1.png';
import iconArrowRight from '../../assets/images/icons/icon-arrow-right-white.png';

export const useStyles = () => ({
	textGreen: {
		fontSize: 36,
		lineHeight: '48px',
		color: '#73E58A',
	},
	saleTimerBanner: {
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat', 
		background: `#181E22 url(${hsBgAlert})`,
		position: 'relative',
		cursor: 'pointer',
	},
	bannerBg1: {
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat', 
		background: `#181E22 url(${bannerBg1})`,
	},
	saleTimerBannerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		maxWidth: 1150,
		padding: 10,
		position: 'relative',
		zIndex: 10,
	},
	saleTimerBannerPhones: {
		width: 104,
	},
	saleTimerBannerText: {
		fontSize: 24,
		fontWeight: '700',
		lineHeight: '36px',
		color: '#ffffff',
		maxWidth: 460,
	},
	saleTimerBannerClock: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#101416',
		borderRadius: 8,
		border: '1px solid #525B60',
		margin: '10px 30px',
		padding: '5px 10px',
		color: '#ffffff',
	},
	saleTimerBannerClockItem: {
		minWidth: 70,
		minHeight: 60,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4px 15px 2px',
		fontSize: 40,
		lineHeight: '45px',
		fontWeight: 700,
	},
	saleTimerBannerClockItemText: {
		fontSize: 12,
		fontWeight: 600,
		lineHeight: '16px',
		color: '#93999C',
		textTransform: 'lowercase',
	},
	saleTimerBannerClockItemSeparator: {
		fontSize: 20,
		fontWeight: 700,
		marginTop: '-25px',
	},
	saleTimerBannerCTA: {
		position: 'relative',
		marginLeft: 10,
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '17px',
		color: '#ffffff',
		background: '#FF8F00',
		borderRadius: 30,
		padding: '15px 60px 15px 30px',
		minWidth: 150,
		textAlign: 'center',
		'&::after': {
			content: '""',
			display: 'block',
			width: 16,
			height: 16,
			position: 'absolute',
			top: 14,
			right: 30,
			backgroundImage: `url(${iconArrowRight})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		}
	},
	'@media (max-width: 800px)': {
		saleTimerBannerContainer: {
			justifyContent: 'center'
		},
	}
});