import React from 'react';
import PropTypes from 'prop-types';
import { 
	Box, 
	List, 
	ListItem, 
	ListItemIcon, 
	ListItemText
} from '@material-ui/core';
import clsx from 'clsx';
import Infinity from '../../UI/svg/Infinity';
import Devices from '../../UI/svg/Devices';
import ControlTower from '../../UI/svg/ControlTower';
import CheckGreen from '../../UI/svg/CheckGreen';
import { withNamespaces, Trans } from 'react-i18next';
import { CURRENCY } from '../../../config/main';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FreemiumInterstitialV3Styles';
import osIconsGrey from '../../../assets/images/icons-os-grey.png';

import Techradar from '../../UI/svg/Techradar';
import TomsGuide from '../../UI/svg/TomsGuide';
import PcMag from '../../UI/svg/PcMag';
import DigitalTrends from '../../UI/svg/DigitalTrends';
import TechAdvisor from '../../UI/svg/TechAdvisor';
import SecurityOrg from '../../UI/svg/SecurityOrg';


function FreemiumInterstitial({
	t, 
	classes, 
	serversDetails: {cities_count=61}, 
	currency,
	savingPercent,
	price,
	onChangeFreemiumInterstitialStep
}) {

	const _currency = CURRENCY[currency] || currency;
	const transCitiesCount = cities_count || 58;
	const transFreeModalPrice = _currency + price;

	return (
		<Box className={classes.interstitialWrapper}>
			<Box className={classes.content}>
				<div className={classes.header}>
					<Trans i18nKey="transFreeModalSave">
						Save {{savingPercent}} with this limited time offer.
					</Trans>
				</div>
				<div className={classes.body}>
					<div className={classes.title}>
						<Trans i18nKey="transFreeModalPrice">
							Get {{transFreeModalPrice}}/mo Unlimited VPN
						</Trans>
					</div>
					<div className={classes.subTitle}>
						<Trans i18nKey="transFreeModalSubheader">
							Get today&apos;s special deal and <span className={classes.bold}>save {{savingPercent}}</span> on Unlimited VPN. Plan includes unlimited data, apps for all your devices with up to 10 connections, ad blocker, and {{transServersCount: transCitiesCount}} worldwide server locations.
						</Trans>
					</div>
					<div className={classes.features}>
						<div className={classes.feature}>
							<List>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIconHead}><Infinity /></ListItemIcon>
									<ListItemText className={classes.featureItemHead}
										primary={t('Unlimited VPN')}
										secondary={t('Unlimited data & unlimited devices.')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<ListItemText className={classes.featureItemRow}
										primary={t('Unlimited Data')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<ListItemText className={classes.featureItemRow}
										primary={t('Zero-Log')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<span className={classes.featureItemRow}>
										<Trans i18nKey="transCitiesCount" transCitiesCount={transCitiesCount}>
											Servers in {{transCitiesCount}} Cities
										</Trans>
									</span>
								</ListItem>
							</List>
						</div>

						<div className={classes.feature}>
							<List>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIconHead}><Devices /></ListItemIcon>
									<ListItemText className={classes.featureItemHead}
										primary={t('Apps For Your Devices')}
										secondary={t('Supported on all your devices.')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<ListItemText className={classes.featureItemRow}
										primary={t('Unlimited Devices')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<ListItemText className={classes.featureItemRow}
										primary={t('10 Connections')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<div className={clsx(classes.featureItemRow, classes.featureItemRowIcons)}>
										<span>{t('Apps for')}</span>
										<img src={osIconsGrey} alt="" width="156" />
									</div>
								</ListItem>
							</List>
						</div>

						<div className={classes.feature}>
							<List>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIconHead}><ControlTower /></ListItemIcon>
									<ListItemText className={classes.featureItemHead}
										primary={t('Control Tower')}
										secondary={t('Control your connection.')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<ListItemText className={classes.featureItemRow}
										primary={t('Ad Blocker')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<ListItemText className={classes.featureItemRow}
										primary={t('Threat Protection')} />
								</ListItem>
								<ListItem alignItems="flex-start" disableGutters>
									<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
									<ListItemText className={classes.featureItemRow}
										primary={t('Parental Controls')} />
								</ListItem>
							</List>
						</div>
					</div>

					<Box className={classes.btn} onClick={() => onChangeFreemiumInterstitialStep(0)}>
						<Trans i18nKey="transFreeModalBtnPrice">
							Get {{transFreeModalPrice}} VPN
						</Trans>
					</Box>

					<Box className={classes.mbgWrapper}>
						<Box className={classes.mbg}>{t('30-Day Money Back Guarantee')}</Box>
					</Box>

					<Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							Recommended by:
						</Box>
						<Box className={classes.logoCell}>
							<Techradar fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<TomsGuide fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<DigitalTrends fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<PcMag fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<TechAdvisor fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<SecurityOrg fillColor={'#93999C'} fillColor1={'#D4D6D7'} />
						</Box>
					</Box>
				</div>
			</Box>
		</Box>
	);
}

FreemiumInterstitial.propTypes = {
	i18n: PropTypes.object,
	classes: PropTypes.object,
	serversDetails: PropTypes.object,
	savingPercent: PropTypes.string,
	price: PropTypes.number,
	currency: PropTypes.string,
	onChangeFreemiumInterstitialStep: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(FreemiumInterstitial));