import axios from 'axios';
import i18next from 'i18next';
import utils from '../utils';
import { LANGUAGES } from '../../config/main';

const { REACT_APP_API, REACT_APP_API_KEY, REACT_APP_PAYPAL_FLEXIBLE, REACT_APP_SEPA_DIRECT_DEBIT } = process.env;


const orderFormApi = axios.create({
	baseURL: `${REACT_APP_API}/api/v1/`,
	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' }
});

const CancelToken = axios.CancelToken;
let cancelCheckEmail;

const API = {
	/* Packages */
	getPackagesList: () => {
		return orderFormApi.post(`packages?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, {api_key: REACT_APP_API_KEY});
	},

	/* Payment Methods */
	getPaymentMethods: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`billing/get-payment-methods-sorted?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Create Saved Payment */
	createSavedPayment: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`${(data.billing_method === REACT_APP_PAYPAL_FLEXIBLE) ? 'paypal' : (data.billing_method === REACT_APP_SEPA_DIRECT_DEBIT) ? 'sepa' : 'payment'}/saved/pay?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Create Payment */
	createPayment: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`payment/create?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Create Paypal Payment */
	createPaypalPayment: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`paypal/flexible/create?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Create iDeal Payment */
	createIdealPayment: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`ideal/create?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Create Sepa Payment */
	createSepaPayment: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`sepa/create?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Create Coinbase Payment */
	createCoinbasePayment: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`coinbase/create?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Create PaymentWall Payment */
	createPaymentWall: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`paymentwall/create?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Create Freemimum */
	createFreemium: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`create_freemium?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Reactivate Freemimum */
	reactivateFreemium: username => {
		return axios.post(
			`reactivate_freemium?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`,
			{username, api_key: REACT_APP_API_KEY},
			{
				baseURL: `${REACT_APP_API}/api/v1/`,
				headers: {
					'Accept': 'application/json',
					'X-VPN-Username': username
				}
			});
	},

	/* User Auth */
	userAuth: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`user/orderform-auth?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}` + (data.token ? ('&token=' + data.token) : ''), data);
	},

	/* Security Check */
	checkSecurity: () => {
		return orderFormApi.post(`security/check?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, {api_key: REACT_APP_API_KEY});
	},

	/* Check Email */
	checkEmail: data => {
		if (cancelCheckEmail != undefined) {
			cancelCheckEmail('canceledCheckEmailRequest');
		}
		data.api_key = REACT_APP_API_KEY;
		return axios.post(
			`email/check?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`,
			data,
			{
				baseURL: `${REACT_APP_API}/api/v1/`,
				headers: { 'Accept': 'application/json' },
				cancelToken: new CancelToken(function executor(c) {
					cancelCheckEmail = c;
				})
			});
	},

	/* Check Order */
	checkOrder: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`order/check?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Get IP */
	getIp: () => {
		return axios.get('https://check.privadovpn.com/');
	},

	/* Send Abandon Email */
	sendAbandonEmailRequest: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`email/abandonment?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Get Coupon */
	getCoupon: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`coupon-info?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* Get Servers Details */
	getServersDetails: () => {
		return axios.get('https://check.privadovpn.com/servers');
	},

	/* Verify Email */
	verifyEmail: data => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`email/verify?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* iDeal Bank List */
	getIDealBankList: () => {
		return orderFormApi.post(`ideal-bank-list?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, {api_key: REACT_APP_API_KEY});
	},

	/* Get Currency */
	getCurrency: (countryCode) => {
		return orderFormApi.post('billing/get-currency', {api_key: REACT_APP_API_KEY, country_code: countryCode});
	},

	/* Save Language */
	saveLanguage: (data) => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post('user/current_language', data);
	},

	/* get recaptcha key  */
	getRecaptchaKey: (data) => {
		data.api_key = REACT_APP_API_KEY;
		return orderFormApi.post(`get-recaptcha-key?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, data);
	},

	/* get recaptcha key unconditionally  */
	getUnconditionallyRecaptchaKey: () => {
		return orderFormApi.post(`get-recaptcha-key-unconditionally?lang=${utils.initLang(LANGUAGES, i18next.language || 'en')}`, {api_key: REACT_APP_API_KEY});
	}
};

export default API;
