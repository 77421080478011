import React from 'react';
import PropTypes from 'prop-types';
import { 
	Box, 
	List, 
	ListItem, 
	ListItemIcon, 
	ListItemText
} from '@material-ui/core';
import clsx from 'clsx';
import Infinity from '../UI/svg/Infinity';
import Devices from '../UI/svg/Devices';
import ControlTower from '../UI/svg/ControlTower';
import CheckGreen from '../UI/svg/CheckGreen';
import { withNamespaces, Trans } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FreemiumModalStyles';
import osIconsGrey from '../../assets/images/icons-os-grey.png';


function FreemiumModal({
	t, 
	classes, 
	serversDetails: {cities_count=61}, 
	currency,
	freemiumModalPrice,
	freemiumModalPercent,
	onCloseFreemiumPopup
}) {

	const transCitiesCount = cities_count || 58;
	const transFreeModalPrice = currency + freemiumModalPrice;

	return (
		<Box className={classes.popupBackdrop} onClick={onCloseFreemiumPopup} >
			<Box className={classes.popup} onClick={e => onCloseFreemiumPopup(e, true)} >
				<Box className={classes.content}>
					<div className={classes.header}>
						<Trans i18nKey="transFreeModalSave">
							Save {{savingPercent: freemiumModalPercent}} with this limited time offer.
						</Trans>
						<span className={classes.closeBtn} onClick={onCloseFreemiumPopup}>&times;</span>
					</div>
					<div className={classes.body}>
						<div className={classes.title}>
							<Trans i18nKey="transFreeModalPrice">
								Get {{transFreeModalPrice}}/mo Unlimited VPN
							</Trans>
						</div>
						<div className={classes.subTitle}>
							<Trans i18nKey="transFreeModalSubheader">
								Get today&apos;s special deal and <span className={classes.bold}>save {{freemiumModalPercent}}</span> on Unlimited VPN. Plan includes unlimited data, apps for all your devices with up to 10 connections, ad blocker, and {{transServersCount: transCitiesCount}} worldwide server locations.
							</Trans>
						</div>
						<div className={classes.features}>
							<div className={classes.feature}>
								<List>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIconHead}><Infinity /></ListItemIcon>
										<ListItemText className={classes.featureItemHead}
											primary={t('Unlimited VPN')}
											secondary={t('Unlimited data & unlimited devices.')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<ListItemText className={classes.featureItemRow}
											primary={t('Unlimited Data')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<ListItemText className={classes.featureItemRow}
											primary={t('Zero-Log')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<span className={classes.featureItemRow}>
											<Trans i18nKey="transCitiesCount" transCitiesCount={transCitiesCount}>
												Servers in {{transCitiesCount}} Cities
											</Trans>
										</span>
									</ListItem>
								</List>
							</div>

							<div className={classes.feature}>
								<List>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIconHead}><Devices /></ListItemIcon>
										<ListItemText className={classes.featureItemHead}
											primary={t('Apps For Your Devices')}
											secondary={t('Supported on all your devices.')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<ListItemText className={classes.featureItemRow}
											primary={t('Unlimited Devices')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<ListItemText className={classes.featureItemRow}
											primary={t('10 Connections')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<div className={clsx(classes.featureItemRow, classes.featureItemRowIcons)}>
											<span>{t('Apps for')}</span>
											<img src={osIconsGrey} alt="" width="156" />
										</div>
									</ListItem>
								</List>
							</div>

							<div className={classes.feature}>
								<List>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIconHead}><ControlTower /></ListItemIcon>
										<ListItemText className={classes.featureItemHead}
											primary={t('Control Tower')}
											secondary={t('Control your connection.')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<ListItemText className={classes.featureItemRow}
											primary={t('Ad Blocker')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<ListItemText className={classes.featureItemRow}
											primary={t('Threat Protection')} />
									</ListItem>
									<ListItem alignItems="flex-start" disableGutters>
										<ListItemIcon className={classes.featureIcon}><CheckGreen /></ListItemIcon>
										<ListItemText className={classes.featureItemRow}
											primary={t('Parental Controls')} />
									</ListItem>
								</List>
							</div>
						</div>

						<Box className={classes.btn}>
							<Trans i18nKey="transFreeModalBtnPrice">
								Get {{transFreeModalPrice}} VPN
							</Trans>
						</Box>

						<Box className={classes.mbgWrapper}>
							<Box className={classes.mbg}>{t('30-Day Money Back Guarantee')}</Box>
						</Box>

						<Box className={classes.center}>
							<Box className={classes.linkGrey} onClick={e => onCloseFreemiumPopup(e, false, true)}>{t('Continue with Free Plan')}</Box>
						</Box>
					</div>
				</Box>
			</Box>
		</Box>
	);
}

FreemiumModal.propTypes = {
	i18n: PropTypes.object,
	classes: PropTypes.object,
	serversDetails: PropTypes.object,
	freemiumModalPercent: PropTypes.string,
	freemiumModalPrice: PropTypes.string,
	currency: PropTypes.string,
	onCloseFreemiumPopup: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(FreemiumModal));