import checkMarkGreen from '../../../assets/images/checked-green.png';
import checkMarkGreenRounded from '../../../assets/images/icon-checked-green-rounded.png';
import paymentIconCC from '../../../assets/images/payments-sprite2.png';
import paymentIconVisa from '../../../assets/images/paymentcons/icon-visa.png';
import paymentIconMasterCard from '../../../assets/images/paymentcons/icon-master-card.png';
import paymentIconDinersClub from '../../../assets/images/paymentcons/icon-diners-club.png';
import paymentIconDiscover from '../../../assets/images/paymentcons/icon-discover.png';
import paymentIconJcb from '../../../assets/images/paymentcons/icon-jcb.png';
import paymentIconAmex from '../../../assets/images/paymentcons/icon-amex.png';
import paymentIconPayPal from '../../../assets/images/paymentIconPayPal.png';
import paymentIconSepa from '../../../assets/images/paymentIconSepa1.png';
import paymentIconIdeal from '../../../assets/images/paymentIconIdeal.png';
import paymentIconCoinbase from '../../../assets/images/paymentIconCoinbase.png';
import iconError from '../../../assets/images/icon-error.png';

export const useStyles = (theme) => ({
	paymentSection: {
		'& label.MuiFormLabel-root': {
			display: 'block',
			transform: 'translate(0px, -20px)',
			fontSize: 12,
			fontWeight: 600,
			color: '#6D7173',
		},
		'& .MuiFormControl-root .MuiOutlinedInput-root': {
			borderRadius: 6,
			background: '#F9F9F9',
			border: '1px solid #D4D6D7',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
			padding: '18px 14px',
			color: '#283339',
			fontSize: 13,
			fontWeight: 600,
		},
		'& .MuiFormControl-root .MuiOutlinedInput-root.Mui-focused': {
			border: '1px solid #858ADA',
		},
		'& .MuiFormControl-root .MuiOutlinedInput-root.Mui-error': {
			border: '1px solid #E52866',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink .MuiInputLabel-asterisk': {
			display: 'none',
		}
	},
	hide: {
		display: 'none !important',
	},
	textBold: {
		fontWeight: '700 !important',
	},
	countrySelectItem: {
		position: 'relative',
		fontSize: 13,
		fontWeight: 600,
		color: '#283339',
		padding: '15px 10px 15px 40px',
		borderBottom: '1px solid #E6E5E5',
		'&:hover': {
			background: '#5058C8',
			color: '#ffffff',
		},
		'&$countrySelectItemActive': {
			background: '#5058C8',
			color: '#ffffff',
			'&:before': {
				content: '""',
				position: 'absolute',
				top: 14,
				left: 15,
				width: 8,
				height: 16,
				borderBottom: '2px solid #ffffff',
				borderRight: '2px solid #ffffff',
				transform: 'rotate(45deg)'
			},
			'&:hover': {
				background: '#5058C8',
				color: '#ffffff',
			},
		}
	},
	countrySelectItemActive: {

	},
	sectionHeader: {
		color: '#6d7173',
		fontSize: 20,
		fontWeight: 700,
		lineHeight: '28px',
		padding: '10px 15px 20px',
		margin: 0,
	},
	separator: {
		height: 1,
		background: '#E6E5E5',
		margin: '0 10px 60px',
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	blockInfoTitle: {
		color: '#283339',
		fontSize: 28,
		fontWeight: 600,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	formControl: {
		minWidth: 120,
		marginTop: 20,
	},
	fieldWrapper: {
		position: 'relative',
	},
	eyeIcon: {
		position: 'absolute',
		right: 18,
		top: 23,
		width: 24,
		height: 20,
		cursor: 'pointer',
		zIndex: 1,
		color: '#41494f',
		fontSize: 18,
		textAlign: 'center',
	},
	eyeCloseIcon: {

	},
	accountInfoBlock: {
		borderRadius: 12,
		background: '#ffffff',
		padding: 30,
		marginBottom: 45,
	},
	checkboxError: {
		color: '#E52866',
	},
	formErrorText: {
		color: '#E52866',
		fontSize: 14,
		fontWeight: 500,
		lineHeight: '16px',
		padding: '10px 0'
	},
	errorIcon: {
		display: 'block',
		position: 'relative',
		paddingLeft: 25,
		fontSize: 11,
		fontWeight: 600,
		color: '#E52866',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-2px',
			left: 3,
			width: 15,
			height: 13,
			backgroundImage: `url(${iconError})`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},

	},
	errorText: {
		position: 'absolute',
		top: 73,
		left: 15,
		width: 'calc(100% + 15px)',
		color: '#f44336',
		fontSize: '0.75rem',
		lineHeight: 1,
	},
	termsContainer: {
		position: 'relative',
		marginBottom: 10,
		'& $errorText': {
			top: 35,
			left: 0,
			width: 'auto',
		},
	},
	terms: {
		fontSize: 12,
		lineHeight: '15px',
		display: 'inline-block',
		color: '#283339',
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
		'& a': {
			textDecoration: 'underline',
			color: '#5058c8'
		}
	},
	planFeaturesDeleteIcon: {

	},
	formInput: {
		marginTop: 20,
	},
	freemiumWrapper: {

	},
	loginBtnWrapper:  {
		minWidth: '100%',
		'& $errorIcon': {
			float: 'right',
		}
	},
	loginBtnContainer: {
		minWidth: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'& a': {
			color: '#A6A9AB',
			fontSize: 14,
			fontWeight: 600,
			textDecoration: 'none',
			marginRight: 20,
		},
	},
	button: {
		margin: theme.spacing(1, 0, 1),
		color: '#fff',
		backgroundColor: '#ff8f00',
		border: 'none',
		padding: 10,
		fontSize: 16,
		fontWeight: 700,
		borderRadius: 50,
		fontFamily: 'Spartan, sans-serif',
		textTransform: 'initial',
		'&:hover': {
			backgroundColor: '#f18f00',
			border: 'none',
		},
	},
	loginButton: {
		maxWidth: 150,
	},
	freemiumSubmit: {
		maxWidth: 350,
		margin: theme.spacing(1, 0, 1),
	},
	paymentBlock: {

	},
	paymentWrapper: {
		position: 'relative',
		borderRadius: 10,
		marginBottom: 20,
	},
	paymentCheckboxWrapperMedia: {
		display: 'flex',
	},
	paymentWrapperMedia: {
		'&$paymentWrapper': {
			minHeight: 50,
			'& $paymentTitleContainer': {
				display: 'none',
			}
		},
	},
	paymentWrapperOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		zIndex: 1000,
	},
	paymentTitleContainer: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		border: '1px solid #e6e5e5',
		padding: 22,
	},
	paymentTitleCircle: {
		width: 22,
		minWidth: 22,
		maxWidth: 22,
		height: 22,
		background: '#d4d6d7',
		border: '1px solid #d4d6d7',
		borderRadius: 11,
		display: 'inline-block',
		marginTop: 2,
		marginRight: 15,
	},
	paymentTitle: {
		color: '#6d7173',
		fontWeight: 700,
		fontSize: 14,
		width: 'calc(100% - 36px)',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	pTitle: {
		marginTop: 3,
		marginRight: 10,
	},
	paymentIcon: {

	},
	paymentMethodContainer: {
		display: 'none',
		background: '#ffffff',
		borderRadius: 12,
		marginLeft: 20,
	},
	paymentMethodContainerShow: {
		display: 'block',
	},
	paymentActive: {
		'&$paymentWrapper': {

		},
		'& $paymentTitleContainer': {
			border: '3px solid #5058C8',
			padding: 20,
			boxShadow: '0px 10px 20px rgba(109, 113, 115, 0.15)',
		},
		'& $paymentTitleCircle': {
			background: 'transparent',
			border: '6px solid #5058C8',
		},
		'& $paymentTitle': {
			color: '#283339',
		},
	},
	paymentDisabled: {
		'& $paymentTitleContainer': {
			cursor: 'not-allowed',
		},
	},
	paymentIconCC: {
		'& $paymentIcon': {
			display: 'block',
			width: 277,
			height: 26,
			backgroundImage: `url(${paymentIconCC})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			maxWidth: 'calc(100% - 30px)',
		},
	},
	paymentIconSepa: {
		'& $paymentIcon': {
			display: 'block',
			width: 32,
			height: 20,
			backgroundImage: `url(${paymentIconSepa})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	paymentIconIdeal: {
		'& $paymentIcon': {
			display: 'block',
			width: 21,
			height: 18,
			backgroundImage: `url(${paymentIconIdeal})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	paymentIconPayPal: {
		'& $paymentIcon': {
			display: 'block',
			width: 85,
			height: 26,
			backgroundImage: `url(${paymentIconPayPal})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	paymentIconCoinbase: {
		'& $paymentIcon': {
			display: 'block',
			width: 149,
			height: 18,
			backgroundImage: `url(${paymentIconCoinbase})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	pwm: {
		'& $pTitle': {
			marginTop: 0,
		},
	},
	paymentWallMethodItem: {
		padding: 3,
		margin: '0 3px',
		border: '1px solid #D9D9D9',
		borderRadius: 4,
		width: 47,
		height: 32,
		display: 'flex',
		alignItems: 'center',
	},
	pwMore: {
		color: '#283339',
		fontWeight: 600,
		fontSize: 12,
	},
	savedPaymentMethod: {
		'&$paymentTitle': {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	savedPaymentWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 5,
		color: '#6D7173',
		fontWeight: 600,
		fontSize: 12,
	},
	savedPaymentIcon: {
		display: 'block',
		marginRight: 5,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	sepa: {
		'&$savedPaymentIcon': {
			width: 32,
			height: 20,
			backgroundImage: `url(${paymentIconSepa})`,
		}
	},
	paypal: {
		'&$savedPaymentIcon': {
			width: 85,
			height: 26,
			backgroundImage: `url(${paymentIconPayPal})`,
		}
	},
	visa:  {
		'&$savedPaymentIcon': {
			width: 33,
			height: 10,
			backgroundImage: `url(${paymentIconVisa})`,
		}
	},
	mastercard: {
		'&$savedPaymentIcon': {
			width: 30,
			height: 19,
			backgroundImage: `url(${paymentIconMasterCard})`,
		}
	},
	diners: {
		'&$savedPaymentIcon': {
			width: 34,
			height: 26,
			backgroundImage: `url(${paymentIconDinersClub})`,
		}
	},
	discover: {
		'&$savedPaymentIcon': {
			width: 59,
			height: 11,
			backgroundImage: `url(${paymentIconDiscover})`,
		}
	},
	jcb: {
		'&$savedPaymentIcon': {
			width: 24,
			height: 18,
			backgroundImage: `url(${paymentIconJcb})`,
		}
	},
	amex: {
		'&$savedPaymentIcon': {
			width: 41,
			height: 11,
			backgroundImage: `url(${paymentIconAmex})`,
		}
	},
	passwordContainer: {
		position: 'relative',
		paddingBottom: '20px !important',
	},
	fieldContainer: {
		position: 'relative',
		paddingBottom: '20px !important',
		'& $formInput': {
			'& input': {
				paddingRight: 45,
			}
		}
	},
	emailResetBtn: {
		position: 'absolute',
		top: 27,
		right: 25,
		cursor: 'pointer',
	},
	emailSuccessText: {
		color: '#283339',
		fontWeight: 600,
		fontSize: 11,
		marginTop: 10,
	},
	emailSuccessIcon: {
		display: 'inline-block',
		width: 15,
		height: 17,
		marginLeft: '-20px',
		backgroundImage: `url(${checkMarkGreenRounded})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	authSuccessIcon: {
		position: 'absolute',
		bottom: 56,
		right: 0,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 2,
			left: '-40px',
			width: 16,
			height: 16,
			backgroundImage: `url(${checkMarkGreen})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	fieldHelpText: {
		color: '#a6a9ab',
		fontSize: 12
	},
	formWrapper: {

	},
	'@media (min-width: 1200px)': {
		formWrapper: {
			position: 'relative',
		},
	},
	'@media (min-width: 1280px)': {
		formWrapper: {
			position: 'relative',
		},
		selectedPlanContainer: {
			minWidth: 300,
		},
	},
	'@media (max-width: 959px)': {
		formWrapper: {
			marginTop: 0
		},
		paymentMethodContainer : {
			marginLeft: 0,
		},
		paymentCheckboxWrapperMedia: {
			display: 'none',
		},
		paymentWrapperMedia: {
			'&$paymentWrapper': {
				minHeight: 50,
				paddingTop: 0,
				display: 'block !important',
				'& $paymentTitleContainer': {
					display: 'flex',
					marginBottom: 20,
				}
			},
		},
	},
	'@media (max-width: 460px)': {
		sectionHeader: {
			textAlign: 'center',
			padding: '10px 15px',
		},
		separator: {
			margin: '0 10px 30px',
		},
		termsContainer: {
			marginBottom: 20,
			'& $errorText': {
				top: 50,
			},
		},
	},
	'@media (max-width: 340px)': {
		paymentIconPayPal: {
			'&::after': {
				content: '""',
				position: 'initial',
			},
		},
	}
});