import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
	pageVerifyContainer: {
		paddingBottom: 30,
	},
	verificationTitle: {
		fontSize: 44,
		lineHeight: '58px',
		fontWeight: 700,
		paddingTop: 60,
		marginBottom: 35,
		color: '#283339',
	},
	verificationContent: {
		fontSize: 20,
		lineHeight: '30px',
		fontWeight: 700,
		color: '#283339',
		border: '2px solid #99A3D2',
		boxShadow: '0px 10px 20px rgba(109, 113, 115, 0.15)',
		borderRadius: 10,
		padding: 40,
		textAlign: 'center',
		maxWidth: 750,
		margin: '0 auto',
	},
	verificationMessage: {
		paddingTop: 30,
	},
	verificationEmail: {
		display: 'block',
		color: '#5058C8',
	},
	verificationBtn: {
		background: '#FF8F00',
		borderRadius: 31,
		textTransform: 'initial',
		textAlign: 'center',
		padding: '16px 60px',
		fontSize: 16,
		fontWeight: 700,
		marginBottom: 80,
		color: '#ffffff',
		cursor: 'pointer',
		boxShadow: 'none',
		'&:hover': {
			background: '#FF8F00',
			boxShadow: 'none',
		}
	},
	linksContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: 50,
	},
	skipEmail: {
		color: '#5058C8',
		fontSize: 15,
		fontWeight: 700,
		cursor: 'pointer',
		marginBottom: 40,
		textAlign: 'center',
	},
	bottomLinks: {
		color: '#6D7173',
		fontSize: 13,
		fontWeight: 600,
		'& a': {
			color: '#6D7173',
			textDecoration: 'none',
			marginLeft: 7,
		},
		'&& span': {
			cursor: 'pointer',
			marginRight: 7,
		},
	},
	'@media (max-width: 850px)': {
		verificationTitle: {
			fontSize: 38,
			lineHeight: '48px',
			paddingTop: 40,
		},
		verificationEmail: {
			display: 'inline-block',
		},
	},
	'@media (max-width: 600px)': {
		verificationTitle: {
			fontSize: 28,
			lineHeight: '38px',
			paddingTop: 30,
		},
		verificationContent: {
			fontSize: 14,
			padding: '30px 20px',
			margin: '40px auto 0',
		},
		verificationBtn: {
			width: '100%',
			padding: '16px 20px',
		}
	}
}));