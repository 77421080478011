import React from 'react';

function ControlTower() {

	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="4" fill="#5058C8"/>
			<rect x="8" y="7" width="16" height="8" rx="4" fill="white"/>
			<circle cx="20" cy="11" r="3" fill="#5058C8"/>
			<rect x="8" y="17" width="16" height="8" rx="4" fill="white"/>
			<circle cx="12" cy="21" r="3" fill="#5058C8"/>
		</svg>
	);
}

export default ControlTower;