import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api';

export const currencySlice = createSlice({
	name: 'currency',
	initialState: {
		availableCurrencies: [],
	},
	reducers: {
		changeCurrency: (state, { payload }) => {
			if(state.availableCurrencies.indexOf(payload) === -1) {
				state.availableCurrencies = [payload, ...state.availableCurrencies];
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(api.endpoints.onGetCurrency.matchFulfilled, (state, { payload }) => {
				state.availableCurrencies = payload;
			})
			.addMatcher(api.endpoints.onGetCurrency.matchRejected, (state, { payload }) => {
				state.availableCurrencies = payload;
			});
	},
});

export const { changeCurrency } = currencySlice.actions;

export default currencySlice.reducer;