import React from 'react';

function InfinityIcon() {

	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="4" fill="#5058C8"/>
			<path d="M21.8438 11C20.394 11 19.0843 11.58 18.1476 12.5137L16.8989 13.7582L18.249 15.1036L19.5346 13.822C20.1203 13.2382 20.9393 12.8749 21.8438 12.8749C23.6205 12.8749 25.0664 14.2769 25.0664 16.0002C25.0664 17.7231 23.6205 19.1251 21.8438 19.1251C20.9393 19.1251 20.1203 18.7618 19.5346 18.1776C14.4842 13.1435 13.8599 12.5211 13.8524 12.5137C12.9157 11.58 11.606 11 10.1562 11C7.30871 11 5 13.2387 5 16.0002C5 18.7613 7.30871 21 10.1562 21C11.606 21 12.9157 20.42 13.8524 19.4863L15.1011 18.2418L13.751 16.8964L12.4654 18.1776C11.8797 18.7618 11.0607 19.1251 10.1562 19.1251C8.37949 19.1251 6.93359 17.7231 6.93359 16.0002C6.93359 14.2769 8.37949 12.8749 10.1562 12.8749C11.0607 12.8749 11.8797 13.2382 12.4654 13.822C12.6867 14.0426 18.0945 19.4334 18.1476 19.4863C19.0843 20.42 20.394 21 21.8438 21C24.6913 21 27 18.7613 27 16.0002C27 13.2387 24.6913 11 21.8438 11Z" fill="white"/>
		</svg>
	);
}

export default InfinityIcon;