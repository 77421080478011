import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import customService from '../../../services/custom';
import Loader from '../../UI/Loader/Loader';
import LoaderSmall from '../../UI/LoaderSmall/LoaderSmall';
import PaymentInfoV2 from '../PaymentInfoV2/PaymentInfoV2';
import { withNamespaces } from 'react-i18next';
import utils from '../../../services/utils';
import { PlanTypes } from '../../../config/plan-types';
import clsx  from 'clsx';
import MBG from '../../UI/MBG/MBG';
import {
	Container,
	Grid,
	TextField,
	Typography,
	Button,
	Box
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FormsV2Styles';

const { REACT_APP_CREDIT_CARD, REACT_APP_CREDIT_CARD_TOKEN } = process.env;

function CreditCardFormV2({
	t,
	mbg,
	isApplicableInTheCountry,
	upgradeMode,
	planConfig,
	savingPercent,
	saving2Percent,
	classes,
	saving,
	currency,
	selectedPackage,
	paymentJS,
	formFields,
	paymentFields,
	withPassword,
	fieldsError,
	fieldsTouched,
	btnDisabled,
	btnProcessing,
	isCouponCodeFieldOpened,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponSavingPercent,
	couponRenewalPrice,
	couponCode,
	couponYearly,
	couponYearly2,
	couponMonthly,
	isProxyDetected,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onSubmitCouponCode,
	onChangeFormFields,
	onBlur,
	onInit,
	onSubmit,
	onOpenCouponCodeField,
	selectedPaymentMethod
}) {
	const [loading, handleLoading] = useState(true);
	const [iframesInfo, iframesInfoUpdate] = useState({});
	const [iFrameError, handleIframeError] = useState(false);

	useEffect(() => {
		if(!iframesInfo.ccNumberTouched || (iframesInfo.ccNumberTouched && (!iframesInfo.validNumber || iframesInfo.validNumber === false)) ||
			!iframesInfo.cvvTouched || (iframesInfo.cvvTouched && (!iframesInfo.validCvv || iframesInfo.validCvv === false))) {
			handleIframeError(true);
		} else {
			handleIframeError(false);
		}
	}, [iframesInfo, handleIframeError]);

	useEffect(() => {
		if(paymentJS) {
			const iframeSubscription = customService.initPayment(paymentJS, REACT_APP_CREDIT_CARD_TOKEN, 'v2').subscribe(data => {
				handleLoading(false);
				if(data) {
					let _iframesInfo = {};
					if(data.ccNumber){
						_iframesInfo = data.ccNumber;
					} else if(data.cvv){
						_iframesInfo = data.cvv;
					}
					iframesInfoUpdate(prevItems => ({...prevItems, ..._iframesInfo}));
				}

			});
			return () => {
				iframeSubscription.unsubscribe();
			};
		}
	}, [paymentJS]);

	useEffect(() => {
		if(!paymentFields[selectedPaymentMethod] && selectedPaymentMethod === REACT_APP_CREDIT_CARD) {
			onInit({
				[selectedPaymentMethod] : {
					firstName: '',
					lastName: '',
					expirationMonthYear: '',
					address: '',
					city: '',
					state: '',
					zipCode: '',
					termsAndConditions: false
				}
			});
			iframesInfoUpdate({});
		}
	}, [onInit, paymentFields, selectedPaymentMethod]);

	const _pf = paymentFields[selectedPaymentMethod] || {};
	const _pft = fieldsTouched[selectedPaymentMethod] || {};
	const _pfe = fieldsError[selectedPaymentMethod] || {};
	const _ff = {...formFields} || {};
	const _ft = {...fieldsTouched['formFields']} || {};
	const _fe = {...fieldsError['formFields']} || {};

	const ccNumberError = iframesInfo.ccNumberTouched && (iframesInfo.numberErrorMessage || iframesInfo.numberLength === 0 || iframesInfo.validNumber === false ||
		(((iframesInfo.cardType === 'amex' && iframesInfo.numberLength < 15) || (iframesInfo.cardType !== 'amex' && iframesInfo.cardType !== 'diners' && iframesInfo.numberLength < 16)) && iframesInfo.ccNumberBlurred) ||
		(((iframesInfo.cardType === 'amex' && iframesInfo.numberLength === 15) || (iframesInfo.cardType !== 'amex' && iframesInfo.numberLength === 16)) && (iframesInfo.validNumber === false)));
	const cvvError = iframesInfo.cvvTouched && (iframesInfo.cvvLength === 0 ||
		(((iframesInfo.cardType === 'amex' && iframesInfo.cvvLength < 4) || (iframesInfo.cardType !== 'amex' && iframesInfo.cvvLength < 3)) && iframesInfo.cvvBlurred) ||
		(((iframesInfo.cardType === 'amex' && iframesInfo.cvvLength === 4) || (iframesInfo.cardType !== 'amex' && iframesInfo.cvvLength === 3)) && (iframesInfo.validCvv === false)));

	if(!withPassword) {
		delete _ff.password;
		delete _ft.password;
		delete _fe.password;
	}
	
	const _couponByPlan = utils.executeCouponByPlan(selectedPackage, PlanTypes, couponYearly, couponYearly2, couponMonthly);
	const _paymentNotEligible = _couponByPlan?.code && utils.checkPaymentEligibility(_couponByPlan?.payment_methods, REACT_APP_CREDIT_CARD);
	const disabled = (Object.keys(_pfe).length + Object.keys(_fe).length > 0) || (Object.keys(_ff).length + Object.keys(_pf).length !== Object.keys(_pft).length + Object.keys(_ft).length) || (_pf['expirationMonthYear'] && _pf['expirationMonthYear'].length < 5) || !_pf.termsAndConditions || iFrameError || btnDisabled || btnProcessing || isProxyDetected || _paymentNotEligible;

	return (
		<>
			<Container maxWidth="sm" component="main">
				{loading ?
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Loader />
						</Grid>
					</Grid> : null
				}
				<Grid container spacing={2} className={loading ? classes.hide : null}>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="firstName"
							label={t('First Name')}
							placeholder={t('Enter first name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="firstName"
							classes={{root: classes.fieldContainer}}
							value={_pf.firstName || ''}
							error={!!_pfe.firstName}
							helperText={(_pfe.firstName) ? <span className={classes.errorIcon}>{_pfe.firstName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'firstName')}
							onBlur={() => onBlur('firstName', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="lastName"
							label={t('Last Name')}
							placeholder={t('Enter last name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="lastName"
							classes={{root: classes.fieldContainer}}
							value={_pf.lastName || ''}
							error={!!_pfe.lastName}
							helperText={(_pfe.lastName) ? <span className={classes.errorIcon}>{_pfe.lastName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'lastName')}
							onBlur={() => onBlur('lastName', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} >
						<div className={classes.numberContainer}>
							<label className={classes.iframeLabel}>{t('Card Number')}</label>
							<div id="number_div" className={clsx({[classes.numberWrapper]: true, [classes.errorField]: ccNumberError})}></div>
							{ccNumberError ?
								<Typography component="p" className={classes.errorText}>
									<span className={classes.errorIcon}>{t((iframesInfo.numberLength === 0) ? 'Please Enter Card Number' : (iframesInfo.numberErrorMessage || 'Please Enter Valid Card Number'))}</span>
								</Typography> : null
							}
						</div>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							variant="outlined"
							fullWidth
							id="expirationMonthYear"
							label={t('Expiration Date')}
							placeholder="MM/YY"
							InputLabelProps={{
								shrink: true,
							}}
							name="expirationMonthYear"
							classes={{root: classes.fieldContainer}}
							value={_pf.expirationMonthYear || ''}
							error={!!_pfe.expirationMonthYear}
							helperText={(_pfe.expirationMonthYear) ? <span className={classes.errorIcon}>{_pfe.expirationMonthYear}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'expirationMonthYear')}
							onKeyUp={e => onChangeFormFields(e.target.value, 'expirationMonthYear', e)}
							onBlur={() => onBlur('expirationMonthYear', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<div className={classes.cvvContainer}>
							<div className={classes.cvvInfoIcon}>
								<div className={classes.cvvInfo}>
									<span className={classes.cvvInfoImg}></span>
									<span>{t('The CVV/CVC code is a three-digit code that appears on the back of your credit card. For American Express, the CVV/CVC code is a four-digit code on the front of the card.')}</span>
								</div>
							</div>
							<label className={classes.iframeLabel}>{t('CVC')}</label>
							<div id="cvv_div" className={clsx({[classes.cvvWrapper]: true, [classes.errorField]: cvvError})}></div>
							{cvvError ?
								<Typography component="p" className={classes.errorText}>
									<span className={classes.errorIcon}>{t((iframesInfo.cvvLength === 0) ? 'Please Enter Security Code' : 'Security Code is not Valid')}</span>
								</Typography> : null
							}
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('Address')}
							placeholder={t('Enter address')}
							InputLabelProps={{
								shrink: true,
							}}
							name="address"
							classes={{root: classes.fieldContainer}}
							value={_pf.address || ''}
							error={!!_pfe.address}
							helperText={(_pfe.address) ? <span className={classes.errorIcon}>{_pfe.address}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'address')}
							onBlur={() => onBlur('address', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('City')}
							placeholder={t('Enter city')}
							InputLabelProps={{
								shrink: true,
							}}
							name="city"
							classes={{root: classes.fieldContainer}}
							value={_pf.city || ''}
							error={!!_pfe.city}
							helperText={(_pfe.city) ? <span className={classes.errorIcon}>{_pfe.city}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'city')}
							onBlur={() => onBlur('city', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="state"
							label={t('State/Province')}
							placeholder={t('Enter state/province')}
							InputLabelProps={{
								shrink: true,
							}}
							name="state"
							classes={{root: classes.fieldContainer}}
							value={_pf.state || ''}
							error={!!_pfe.state}
							helperText={(_pfe.state) ? <span className={classes.errorIcon}>{_pfe.state}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'state')}
							onBlur={() => onBlur('state', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="zipCode"
							label={t('ZIP/Postal Code')}
							placeholder={t('Enter ZIP/Postal code')}
							InputLabelProps={{
								shrink: true,
							}}
							name="zipCode"
							classes={{root: classes.fieldContainer}}
							value={_pf.zipCode || ''}
							error={!!_pfe.zipCode}
							helperText={(_pfe.zipCode) ? <span className={classes.errorIcon}>{_pfe.zipCode}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'zipCode')}
							onBlur={() => onBlur('zipCode', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} style={{display: (isApplicableInTheCountry ? 'none' : 'block')}}>
						<div className={classes.countryWarning}>
							{t('Based on your current location, the selected payment method may not be available.')}
						</div>
					</Grid>
				</Grid>
			</Container>

			<div className={classes.separator}></div>

			<Container maxWidth="sm" component="main">
				<Grid container spacing={2} className={loading ? classes.hide : null}>
					<Grid item xs={12}>
						<PaymentInfoV2
							_pf={_pf}
							_pft={_pft}
							_pfe={_pfe}
							paymentNotEligible={_paymentNotEligible}
							paymentDisplayName="Credit Card"
							couponByPlan={_couponByPlan}
							saving={saving}
							savingPercent={savingPercent}
							saving2Percent={saving2Percent}
							selectedPackage={selectedPackage}
							currency={currency}
							customClassName="paymentInfoCCContainerV2"
							isCouponCodeFieldOpened={isCouponCodeFieldOpened}
							couponPrice={couponPrice}
							couponGracePeriod={couponGracePeriod}
							couponValidPlan={couponValidPlan}
							couponSavingPercent={couponSavingPercent}
							couponRenewalPrice={couponRenewalPrice}
							couponCode={couponCode}
							onChangePaymentMethod={onChangePaymentMethod}
							onOpenCouponCodeField={onOpenCouponCodeField}
							onChangeCouponCodeField={onChangeCouponCodeField}
							onSubmitCouponCode={onSubmitCouponCode}
							onChangeFormFields={onChangeFormFields}
							planConfig={planConfig}
						/>
					</Grid>
				</Grid>

				{iframesInfo?.cardType === 'amex' && currency === 'EUR' ?
					<Box component="div" pt={3} className={classes.amexWarning}>
						<Box component="span" className={classes.errorIcon}>
							{t('Unfortunately EUR payments aren\'t available for AmEx cards at the moment. Please select a different card type or payment method. Thank you.')}
						</Box>
					</Box>: null
				}

				<div className={classes.submitContainer}>
					<div>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={disabled || (iframesInfo?.cardType === 'amex' && currency === 'EUR')}
							onClick={onSubmit}
						>
							{btnProcessing ? <LoaderSmall /> : null}
							{t(btnProcessing ? 'Processing' : upgradeMode ? 'Confirm Purchase' : 'Get Now')}

						</Button>
						{isProxyDetected ?
							<div className={classes.errorIcon}>{t('We detected you are using a proxy, please disconnect your proxy before placing an order.')}</div>: null
						}
					</div>
					{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
						<div className={classes.moneyBackLg}>
							<div className={classes.moneyBackLgBadge}>
								<MBG />
							</div>
							<div className={classes.moneyBackLgTitle}>{t('30-Day Money Back Guarantee')}</div>
							<div className={classes.moneyBackLgContent}>{t('Try PrivadoVPN Premium for 30 days, risk-free. If you aren\'t entirely satisfied, we will give you a full refund.')}</div>
						</div>: null
					}
				</div>
				{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
					<div className={classes.moneyBackSm}>
						<div className={classes.moneyBackContentSm}>
							{t('30-Day Money Back Guarantee')}
						</div>
					</div> : null
				}
			</Container>
		</>
	);
}

CreditCardFormV2.propTypes = {
	mbg: PropTypes.bool,
	isApplicableInTheCountry: PropTypes.bool,
	upgradeMode: PropTypes.bool,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	currency: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.object,
	selectedPackage: PropTypes.object,
	paymentJS: PropTypes.object,
	formFields: PropTypes.object,
	paymentFields: PropTypes.object,
	withPassword: PropTypes.bool,
	fieldsError: PropTypes.object,
	fieldsTouched: PropTypes.object,
	btnDisabled: PropTypes.bool,
	btnProcessing: PropTypes.bool,
	selectedPaymentMethod: PropTypes.string,
	couponYearly: PropTypes.object,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponCode: PropTypes.string,
	couponYearly2: PropTypes.object,
	couponMonthly: PropTypes.object,
	isCouponCodeFieldOpened: PropTypes.bool,
	isProxyDetected: PropTypes.bool,
	onChangePaymentMethod: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onSubmitCouponCode: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onBlur: PropTypes.func,
	onInit: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpenCouponCodeField: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(CreditCardFormV2));