import React, { useEffect } from 'react';
import {
	Container,
	TextField,
	Typography,
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import LoaderSmall from '../../UI/LoaderSmall/LoaderSmall';
import PaymentInfoV2 from '../PaymentInfoV2/PaymentInfoV2';
import { PlanTypes } from '../../../config/plan-types';
import { withNamespaces } from 'react-i18next';
import utils from '../../../services/utils';
import clsx from 'clsx';
import MBG from '../../UI/MBG/MBG';
import { CountryList } from '../../../config/countryList';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FormsV2Styles';

const { REACT_APP_SEPA_DIRECT_DEBIT } = process.env;

function SepaDirectDebitFormV2({
	t,
	mbg,
	isApplicableInTheCountry,
	upgradeMode,
	planConfig,
	savingPercent,
	saving2Percent,
	classes,
	saving,
	currency,
	country,
	selectedPackage,
	formFields,
	paymentFields,
	withPassword,
	fieldsError,
	fieldsTouched,
	btnDisabled,
	btnProcessing,
	isCouponCodeFieldOpened,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponSavingPercent,
	couponRenewalPrice,
	couponCode,
	couponYearly2,
	couponYearly,
	couponMonthly,
	isProxyDetected,
	allowedCountries,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onSubmitCouponCode,
	onChangeFormFields,
	onChangeCountry,
	onBlur,
	onInit,
	onSubmit,
	onOpenCouponCodeField,
	selectedPaymentMethod
}) {

	useEffect(() => {
		if(!paymentFields[selectedPaymentMethod] && selectedPaymentMethod === REACT_APP_SEPA_DIRECT_DEBIT) {
			onInit({
				[selectedPaymentMethod] : {
					firstName: '',
					lastName: '',
					iban: '',
					address: '',
					city: '',
					postcode: '',
					termsAndConditions: false
				}
			});
		}
	}, [onInit, paymentFields, selectedPaymentMethod]);

	const _pf = paymentFields[selectedPaymentMethod] || {};
	const _pft = fieldsTouched[selectedPaymentMethod] || {};
	const _pfe = fieldsError[selectedPaymentMethod] || {};
	const _ff = {...formFields} || {};
	const _ft = {...fieldsTouched['formFields']} || {};
	const _fe = {...fieldsError['formFields']} || {};

	if(!withPassword) {
		delete _ff.password;
		delete _ft.password;
		delete _fe.password;
	}

	const _countryList = CountryList.filter(c => allowedCountries.indexOf(c.value) > -1).map(c => ({...c, viewValue: t(c.viewValue)})).sort((a, b) => (a.viewValue || '').toLowerCase().localeCompare((b.viewValue || '').toLowerCase()));
	const _couponByPlan = utils.executeCouponByPlan(selectedPackage, PlanTypes, couponYearly, couponYearly2, couponMonthly);
	const _paymentNotEligible = _couponByPlan?.code && utils.checkPaymentEligibility(_couponByPlan?.payment_methods, REACT_APP_SEPA_DIRECT_DEBIT);
	const disabled = (Object.keys(_pfe).length + Object.keys(_fe).length > 0) || (Object.keys(_ff).length + Object.keys(_pf).length !== Object.keys(_pft).length + Object.keys(_ft).length) || !_pf.termsAndConditions || !country || btnDisabled || btnProcessing || isProxyDetected || _paymentNotEligible;

	return (
		<>
			<Container maxWidth="sm" component="main">
				<Grid container spacing={2}>
					<Grid item xs={12}  sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('First Name')}
							placeholder={t('Enter first name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="firstName"
							classes={{root: classes.fieldContainer}}
							value={_pf.firstName || ''}
							error={!!_pfe.firstName}
							helperText={(_pfe.firstName) ? <span className={classes.errorIcon}>{_pfe.firstName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'firstName')}
							onBlur={() => onBlur('firstName', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('Last Name')}
							placeholder={t('Enter last name')}
							InputLabelProps={{
								shrink: true,
							}}
							name="lastName"
							classes={{root: classes.fieldContainer}}
							value={_pf.lastName || ''}
							error={!!_pfe.lastName}
							helperText={(_pfe.lastName) ? <span className={classes.errorIcon}>{_pfe.lastName}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'lastName')}
							onBlur={() => onBlur('lastName', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('Address')}
							placeholder={t('Enter address')}
							InputLabelProps={{
								shrink: true,
							}}
							name="address"
							classes={{root: classes.fieldContainer}}
							value={_pf.address || ''}
							error={!!_pfe.address}
							helperText={(_pfe.address) ? <span className={classes.errorIcon}>{_pfe.address}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'address')}
							onBlur={() => onBlur('address', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							label={t('City')}
							placeholder={t('Enter city')}
							InputLabelProps={{
								shrink: true,
							}}
							name="city"
							classes={{root: classes.fieldContainer}}
							value={_pf.city || ''}
							error={!!_pfe.city}
							helperText={(_pfe.city) ? <span className={classes.errorIcon}>{_pfe.city}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'city')}
							onBlur={() => onBlur('city', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" classes={{root: classes.fieldContainer}} fullWidth error={!country}>
							<InputLabel shrink>{t('Country')}</InputLabel>
							<Select
								value={country || ''}
								onChange={e => onChangeCountry(e.target.value)}
								label={t('Select Country')}
								displayEmpty
								renderValue={
									country !== '' ? undefined : () => <div className={classes.placeholder}>{t('Select Country')}</div>
								}
							>
								{_countryList.map(c => (
									<MenuItem
										key={c.value}
										className={clsx(classes.countrySelectItem, {[classes.countrySelectItemActive]: c.value === _ff.country})}
										value={c.value}>{c.viewValue}</MenuItem>
								))}
							</Select>

							<FormHelperText>{!country ? <span className={classes.errorIcon}>{t('This field is required')}</span> : ''}</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="postcode"
							label={t('ZIP/Postal Code')}
							placeholder={t('Enter ZIP/Postal code')}
							InputLabelProps={{
								shrink: true,
							}}
							name="postcode"
							classes={{root: classes.fieldContainer}}
							value={_pf.postcode || ''}
							error={!!_pfe.postcode}
							helperText={(_pfe.postcode) ? <span className={classes.errorIcon}>{_pfe.postcode}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'postcode')}
							onBlur={() => onBlur('postcode', selectedPaymentMethod)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							fullWidth
							id="iban"
							label="IBAN"
							placeholder={t('Enter your IBAN')}
							InputLabelProps={{
								shrink: true,
							}}
							name="iban"
							classes={{root: classes.fieldContainer}}
							value={_pf.iban || ''}
							error={!!_pfe.iban}
							helperText={(_pfe.iban) ? <span className={classes.errorIcon}>{_pfe.iban}</span> : ''}
							onChange={e => onChangeFormFields(e.target.value, 'iban')}
							onBlur={() => onBlur('iban', selectedPaymentMethod)}
						/>
					</Grid>
					<Grid item xs={12} style={{display: (isApplicableInTheCountry ? 'none' : 'block')}}>
						<div className={classes.countryWarning}>
							{t('Based on your current location, the selected payment method may not be available.')}
						</div>
					</Grid>
				</Grid>
			</Container>

			<div className={classes.separator}></div>

			<Container maxWidth="sm" component="main">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PaymentInfoV2
							_pf={_pf}
							_pft={_pft}
							_pfe={_pfe}
							paymentNotEligible={_paymentNotEligible}
							paymentDisplayName="Sepa"
							couponByPlan={_couponByPlan}
							saving={saving}
							savingPercent={savingPercent}
							saving2Percent={saving2Percent}
							selectedPackage={selectedPackage}
							currency={currency}
							customClassName="paymentInfoPayPalContainerV2"
							isCouponCodeFieldOpened={isCouponCodeFieldOpened}
							couponPrice={couponPrice}
							couponGracePeriod={couponGracePeriod}
							couponValidPlan={couponValidPlan}
							couponSavingPercent={couponSavingPercent}
							couponRenewalPrice={couponRenewalPrice}
							couponCode={couponCode}
							onChangePaymentMethod={onChangePaymentMethod}
							onOpenCouponCodeField={onOpenCouponCodeField}
							onChangeCouponCodeField={onChangeCouponCodeField}
							onSubmitCouponCode={onSubmitCouponCode}
							onChangeFormFields={onChangeFormFields}
							planConfig={planConfig}
						/>
					</Grid>
				</Grid>

				<Typography component="p" className={classes.info}>
					{selectedPackage.packageIdentifier == PlanTypes.YEARLY2 ? t('I authorize Privado Networks AG (https://privadovpn.com/) to send instructions to my bank to debit my account every 24 months.') : selectedPackage.packageIdentifier == PlanTypes.MONTHLY ? t('I authorize Privado Networks AG (https://privadovpn.com/) to send instructions to my bank to debit my account every 1 month.') : t('I authorize Privado Networks AG (https://privadovpn.com/) to send instructions to my bank to debit my account every 12 months.')} {t('At the same time I authorize my bank to debit my account in accordance with the instructions from Privado Networks AG.')}
				</Typography>

				<div className={classes.submitContainer}>
					<div>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={disabled}
							onClick={onSubmit}
						>
							{btnProcessing ? <LoaderSmall /> : null}
							{t(btnProcessing ? 'Processing' : upgradeMode ? 'Confirm Purchase' : 'Get Now')}

						</Button>
						{isProxyDetected ?
							<div className={classes.errorIcon}>We detected you are using a proxy, please disconnect your proxy before placing an order.</div>: null
						}
					</div>
					{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
						<div className={classes.moneyBackLg}>
							<div className={classes.moneyBackLgBadge}>
								<MBG />
							</div>
							<div className={classes.moneyBackLgTitle}>{t('30-Day Money Back Guarantee')}</div>
							<div className={classes.moneyBackLgContent}>{t('Try PrivadoVPN Premium for 30 days, risk-free. If you aren\'t entirely satisfied, we will give you a full refund.')}</div>
						</div>: null
					}
				</div>
				{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
					<div className={classes.moneyBackSm}>
						<div className={classes.moneyBackContentSm}>
							{t('30-Day Money Back Guarantee')}
						</div>
					</div> : null
				}
			</Container>
		</>
	);
}

SepaDirectDebitFormV2.propTypes = {
	mbg: PropTypes.bool,
	isApplicableInTheCountry: PropTypes.bool,
	upgradeMode: PropTypes.bool,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	currency: PropTypes.string,
	country: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.object,
	selectedPackage: PropTypes.object,
	formFields: PropTypes.object,
	paymentFields: PropTypes.object,
	withPassword: PropTypes.bool,
	fieldsError: PropTypes.object,
	fieldsTouched: PropTypes.object,
	btnDisabled: PropTypes.bool,
	btnProcessing: PropTypes.bool,
	selectedPaymentMethod: PropTypes.string,
	couponYearly: PropTypes.object,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponCode: PropTypes.string,
	couponYearly2: PropTypes.object,
	couponMonthly: PropTypes.object,
	isCouponCodeFieldOpened: PropTypes.bool,
	isProxyDetected: PropTypes.bool,
	allowedCountries: PropTypes.array,
	onChangePaymentMethod: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onSubmitCouponCode: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onChangeCountry: PropTypes.func,
	onBlur: PropTypes.func,
	onInit: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpenCouponCodeField: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(SepaDirectDebitFormV2));