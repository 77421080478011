import iconQuestionMark from '../../../assets/images/icon-question-mark.png';
import iconInfoImg from '../../../assets/images/icon-search-cc.png';
import iconError from '../../../assets/images/icon-error.png';
import iconMoneyBack from '../../../assets/images/icon-moneyback-guarantee-check.png';

export const useStyles = (theme) => ({
	hide: {
		display: 'none',
	},
	errorField: {

	},
	errorIcon: {
		position: 'relative',
		paddingLeft: 20,
		fontSize: 11,
		fontWeight: 600,
		color: '#E52866',
		display: 'block',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-2px',
			left: 3,
			width: 15,
			height: 13,
			backgroundImage: `url(${iconError})`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},

	},
	expDate: {

	},
	formRoot: {

	},
	fieldContainer: {
		marginTop: 23,
	},
	fieldLabel: {
		width: 'calc(100% + 15px)',
		fontSize: 12,
		color: '#283339',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		textTransform: 'uppercase',
	},
	expDateLabel: {
		letterSpacing: 0.24,
	},
	iframeLabel: {
		display: 'block',
		fontSize: 12,
		fontWeight: 600,
		color: '#6D7173',
		marginBottom: 6,
	},
	cvvContainer: {
		position: 'relative',
		'& $errorText': {
			top: 80,
		}
	},
	cvvWrapper: {
		height: 53,
		width: '100%',
		position: 'relative',
		borderRadius: 6,
		background: '#F9F9F9',
		border: '1px solid #D4D6D7',
		'&$errorField': {
			border: '1px solid #E52866',
		},
	},
	cvvInfo: {
		display: 'none',
		position: 'absolute',
		bottom: '30px',
		right: '-60px',
		zIndex: 20,
		width: 250,
		padding: 20,
		fontSize: 12,
		color: '#333333',
		fontWeight: 400,
		fontFamily: 'Spartan, sans-serif',
		boxSizing: 'border-box',
		background: '#ffffff',
		borderRadius: 10,
		boxShadow: '-4.33px 2.5px 6.5px rgba(1,1,1,0.17)',
		'&::after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			bottom: '-5px',
			right: 63,
			width: 15,
			height: 15,
			background: '#ffffff',
			transform: 'rotate(45deg)',
		},
	},
	cvvInfoImg: {
		maxWidth: 37,
		minWidth: 37,
		height: 27,
		margin: '3px 10px 0 0',
		backgroundImage: `url(${iconInfoImg})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	cvvInfoIcon: {
		position: 'absolute',
		top: '-2px',
		left: 35,
		zIndex: 10,
		width: 16,
		height: 17,
		backgroundImage: `url(${iconQuestionMark})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		'&:hover $cvvInfo': {
			display: 'flex',
		},
	},
	numberContainer: {
		position: 'relative',
		'& $errorText': {
			top: 78,
		}
	},
	numberWrapper: {
		height: 53,
		width: '100%',
		borderRadius: 6,
		background: '#F9F9F9',
		border: '1px solid #D4D6D7',
		'&$errorField': {
			border: '1px solid #E52866',
		},
	},
	checkboxError: {
		color: '#f44336',
	},
	errorText: {
		position: 'absolute',
		top: 71,
		left: 0,
		width: '100%',
		color: '#f44336',
		fontSize: '0.75rem',
		lineHeight: 1,
	},
	submitContainer: {
		display: 'flex',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		color: '#fff',
		backgroundColor: '#ff8f00',
		border: 'none',
		padding: 10,
		fontSize: 16,
		fontWeight: 700,
		borderRadius: 50,
		fontFamily: 'Spartan, sans-serif',
		textTransform: 'initial',
		lineHeight: '40px',
		maxWidth: 240,
		minWidth: 240,
		'&:hover': {
			backgroundColor: '#f18f00',
			border: 'none',
		},
	},
	moneyBackLgBadge: {
		position: 'absolute',
		left: 0,
		top: 0,
	},
	moneyBackLg: {
		position: 'relative',
		color: '#283339',
		paddingLeft: 60,
		marginTop: 24,
		marginLeft: 30,
		maxWidth: 400,
	},
	moneyBackLgTitle: {
		fontWeight: 700,
		fontSize: 15,
		marginBottom: 8,
	},
	moneyBackLgContent:  {
		color: '#6D7173',
		fontWeight: 600,
		fontSize: 11,
	},
	moneyBackSm: {
		display: 'none',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 25,
	},
	moneyBackContentSm: {
		position: 'relative',
		fontSize: 11,
		fontWeight: 700,
		lineHeight: '12px',
		color: '#283339',
		padding: '5px 15px 5px 30px',
		textAlign: 'center',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-1px',
			left: 0,
			width: 23,
			height: 22,
			backgroundSize: 'cover',
			backgroundImage: `url(${iconMoneyBack})`,
		},
	},
	separator: {
		background: '#E6E5E5',
		padding: '1px 0 0',
		margin: '0 0 -4px',
	},
	info: {
		fontSize: 11,
		fontWeight: 600,
		lineHeight: '16px',
		color: '#283339',
	},
	paymentWallMethodsWrapper: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	paymentWallMethodItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 70,
		maxWidth: 70,
		height: 48,
		padding: 5,
		borderRadius: 4,
		border: '1px solid #E9EAEB',
		margin: 7,
		cursor: 'pointer',
		boxSizing: 'border-box',
	},
	paymentWallMethodItemSelected: {
		border: '2px solid #767CD4',
		padding: 4,
		cursor: 'initial',
	},
	paymentWallIcon: {
		display: 'block',
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		margin: '0 auto',
	},
	amexWarning: {
		'& $errorIcon': {
			fontSize: 14,
			fontWeight: 700,
			'&::before': {
				top: 1,
			},
		}
	},
	countryWarning: {
		fontSize: 14,
		fontWeight: 500,
		lineHeight: '20px',
		padding: '0 10px',
		textAlign: 'center',
	},
	sparator: {
		borderBottom: '1px solid #E6E5E5',
		marginLeft: '-24px',
		marginRight: '-24px',
	},
	'@media (max-width: 959px)': {
		submitContainer: {
			display: 'block',
		},
		submit: {
			maxWidth: '100%',
		},
		moneyBackLg: {
			display: 'none',
		},
		moneyBackSm: {
			display: 'flex',
		},
	},
	'@media (max-width: 760px)': {
		cvvInfo: {
			position: 'absolute',
			bottom: '30px',
			right: '-30px',
			'&::after': {
				content: '""',
				position: 'absolute',
				bottom: '-5px',
				right: 33,
			},
		},
	},
	'@media (max-width: 600px)': {
		countryWarning: {
			marginLeft: '-16px',
			marginRight: '-16px',
		},
		cvvInfo: {
			position: 'absolute',
			bottom: '30px',
			left: '-40px',
			right: 'auto',
			'&::after': {
				content: '""',
				position: 'absolute',
				bottom: '-5px',
				left: 40,
				right: 'auto',
			},
		},
	},
});