import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useStyles } from './VerificationStyles';
import { withNamespaces, Trans } from 'react-i18next';
import Envelop from './Envelop';

function Verification({t, createFreemiumState, navigate}) {
	const classes = useStyles();
	useEffect(() => {
		if(!createFreemiumState.success) {
			navigate('/');
		}
	}, [createFreemiumState]);

	const transEmailVerify = createFreemiumState.email;

	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<div className="page-content bg-grey">
			{createFreemiumState.success ?
				<Container maxWidth="md" component="main" className={classes.pageVerifyContainer}>
					<Typography className={classes.verificationTitle} component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
						{t('Verify Your Email')}
					</Typography>

					<Box className={classes.verificationContent}>
						<Envelop />
						<div className={classes.verificationMessage}>
							<Trans i18nKey="transEmailVerify" transEmailVerify={transEmailVerify}>
								A verification email has been sent to  <span className={classes.verificationEmail}>{{transEmailVerify}}</span>.
							</Trans>
						</div>
					</Box>

					<Box className={classes.linksContainer}>
						<span className={classes.verificationBtn} onClick={handleGoBack}>{t('Skip Email Verification with Premium Plan')}</span>
						<div className={classes.bottomLinks}>
							<span onClick={handleGoBack}>{t('Change Email')}</span>|
							<a color="textPrimary" href="https://support.privadovpn.com/home/" rel="noopener noreferrer" target="_blank">{t('Contact Support')}</a>
						</div>
					</Box>
				</Container>: null
			}
		</div>
	);
}

const mapStateToProps = state => {
	return {
		createFreemiumState: state.home.createFreemiumState
	};
};

Verification.propTypes = {
	createFreemiumState: PropTypes.object,
	navigate: PropTypes.func,
	t: PropTypes.func
};

export default connect(mapStateToProps, null)(withNamespaces()(Verification));