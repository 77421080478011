import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.css';
import './assets/fonts/fontawesome/css/all.min.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import Loader from './components/UI/Loader/Loader';
import ErrorBoundary from './components/UI/ErrorBoundary/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';


Sentry.init({
	dsn: 'https://46c83ee9aebe41a492f308450e33054d@sentry.privado.io/12',
	environment: process.env.REACT_APP_ENVIRONMENT,
	sendClientReports: false,
	ignoreErrors: [
		/aimtell-worker/i,
		/extension/i,
		/payment.1.2/i,
		/clarity.js/i,
		/analytics.js/i,
		/translate/i,
		'ServiceWorker'
	],
	denyUrls: [
		/payment.1.2/i,
		/extensions\//i,
		/^chrome:\/\//i,
		/^chrome-extension:\/\//i,
		'http://localhost:3000/'
	],
	integrations: function(integrations) {
		return integrations.filter(function(integration) {
			return integration.name !== 'Dedupe';
		});
	},
	beforeSend(event) {
		return (event.tags && event.tags.react) ? event : null;
	}
});

const app = (
	<ErrorBoundary>
		<Provider store={store}>
			<BrowserRouter>
				<Suspense fallback={<Loader />}>
					<App />
				</Suspense>
			</BrowserRouter>
		</Provider>
	</ErrorBoundary>
);
ReactDOM.render(app, document.getElementById('root'));  // eslint-disable-line

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
