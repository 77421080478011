import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
	logosContainer: {
		padding: '20px 0 20px',
	},
	logosTitle: {
		fontWeight: 700,
		fontSize: 14,
		color: '#283339',
		textAlign: 'center',
	},
	logosSeparator: {
		borderTop: '1px solid #E9EAEB',
		marginTop: 10,
		marginBottom: 10,
	},
	logosRow: {
		paddingTop: 5,
		paddingBottom: 5,
	},
	logoCell: {
		margin: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flex: '1 1 auto',
		'& svg': {
			width: '100%',
			maxWidth: '100%',
		}
	},
	logoAmazon: {
		marginTop: 8,
	},
}));