/* eslint-disable */
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { META, CURRENCY } from '../config/main';

const utility = {

	setDataToStorage: (key, value) => {
		localStorage[key] = JSON.stringify(value);
	},

	getDataFromStorage: (key) => {
		return localStorage[key] ? JSON.parse(localStorage[key]) : null;
	},

	deleteDataFromStorage: (key) => {
		localStorage.removeItem(key);
	},

	filterMeta: meta => {
		const _meta = {};
		if(META && META.length) {
			for(let i=0; i<META.length; i++) {
				if(meta[META[i]] && meta[META[i]].length) {
					_meta[META[i]] = meta[META[i]];
				}
			}
		}
		return _meta;
	},

	updateObject: (oldObject, updatedProperties) => {
		return {
			...oldObject,
			...updatedProperties
		};
	},
	handleError: (endpointCode, error, message, extraData = {}, toastDisabled) => {
		const status = error?.response?.status;
		
		if(!toastDisabled) {
			let _message = message || (error?.response?.data?.error || error?.response?.data?.reason);
			if(status >= 500) {
				_message = i18next.t('Something went wrong, please come back later');
			}
			if(!_message) {
				_message = i18next.t('Something went wrong, please come back later');
				if(endpointCode) {
					_message += ' ' + endpointCode;
				}
				if(status) {
					_message += ' - ' + status;
				}
				if (error?.code) {
					_message += ' - ' + error?.code;
				}
				if (error?.message) {
					_message += ' - ' + error?.message;
				}
			}
			toast.error(_message, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
		if(status !== 400 && status !== 403) {
			Sentry.withScope(scope => {
				scope.setTag('react', true);
				if(error?.code) {
					scope.setFingerprint([error?.code]);
				} else if(endpointCode === 'captcha') {
					scope.setFingerprint([endpointCode]);
				}
				scope.setExtras({
					...extraData,
					'error message:': error?.message, 
					'endpoint code:': endpointCode, 
					'error code:': error?.code
				});
				Sentry.captureException(error);
			});
		}
	},
	idleTimeTicker: function(f1, f2, dt1, dt2) {
		const DELAY_TIME_1 = dt1 || 4*60*1000; //4 minutes, time is in milliseconds
		const DELAY_TIME_2 = dt2 || 10*60*1000; //10 minutes, time is in milliseconds
		const loadTime = new Date().getTime();
		let ti, initTime = null, currentTime = null, isF1Triggered = false;

		const resetTimer = () => {
			if(!isF1Triggered) {
				initTime = new Date().getTime();
			}
		};

		const mouseLeave = (event) => {
			const leaveTime = new Date().getTime();

			if((leaveTime - loadTime >= 5000) && !isF1Triggered && (event.clientY <= 20 || event.clientX <= 20 || event.clientX >= window.innerWidth - 20 || event.clientY >= window.innerHeight - 20)) {
				isF1Triggered = true;
				f1();
			}
		};

		this.init = function() {
			initTime = new Date().getTime();
			window.onload = resetTimer;
			window.onmousemove = resetTimer;
			window.onmousedown = resetTimer;  // catches touchscreen presses as well
			window.ontouchstart = resetTimer; // catches touchscreen swipes as well
			window.onclick = resetTimer;      // catches touchpad clicks as well
			window.onkeydown = resetTimer;
			window.addEventListener('scroll', resetTimer, true);
			
			document.getElementsByTagName('body')[0].addEventListener('mouseleave', mouseLeave, false);

			ti = setInterval(() => {
				currentTime = new Date().getTime();
				const timeInterval = currentTime - initTime;

				if(!isF1Triggered && timeInterval > DELAY_TIME_1) {
					isF1Triggered = true;
					f1();
				}
				if(isF1Triggered && timeInterval > DELAY_TIME_2) {
					if(f2) {
						f2();
					}
					clearInterval(ti);
				}
			}, 5000);
		};

		this.reset = function() {
			clearInterval(ti);
			window.removeEventListener('scroll', resetTimer, true);
			document.getElementsByTagName('body')[0].removeEventListener('mouseleave', mouseLeave, false);
		};
	},

	getRoute: (LANGUAGES) => {
		let _path = location.pathname || '';
		const _lang = LANGUAGES.map(l => l.lang).find(lang => _path.indexOf('/' + lang + '/') > -1);
		_path = _lang ? _path.replace('/' + _lang + '/', '') : _path;
		_path = (_path[0] === '/') ? _path.slice(1) : _path;
		_path = (_path[_path.length - 1] === '/') ? _path.slice(0, -1) : _path;

		return _path;
	},

	initLang: (LANGUAGES, lang) => {
		let _path = location.pathname || '';
		_path = (_path[_path.length - 1] === '/') ? _path.slice(0, -1) : _path;
		const _pathArray = _path.split('/');

		return (_pathArray[1] && LANGUAGES.map(l => l.lang).indexOf(_pathArray[1]) > -1) ? _pathArray[1] : (lang || '');
	},

	combineRoute: (lang, path, currency) => {
		return (lang ? ('/' + lang) : '') + path + (currency ? '/?currency=' + currency : '');
	},

	processTitle: (t, currency, price, customTitle='') => {
		const titleArr = customTitle.split('{$}');
		const _title0 = titleArr[0] || '';
		const _title1 = titleArr[1] || '';

		return (
			titleArr.length > 1 ?
				(t(_title0) + ' ' + (CURRENCY[currency] || currency) + price + (_title1.length > 1 ? t(_title1) : '')):
				t(customTitle)
		);
	},

	calculatePricePerMonth: (currentPackage, PlanTypes, currency, coupon) => {
		const currentPackagePrice = currentPackage?.recurringPeriod?.basePrice.find(bp => bp.currencyCode === currency)?.amount || 0;
		const currentPackageGracePeriod = currentPackage?.recurringPeriod?.grace_period_months || 0;
		const couponValidPlan = coupon.validPlans && coupon?.validPlans[0] || '';
		const couponGracePeriod = coupon?.grace_period_months || 0;
		const couponPrice = coupon?.typeParameters?.fixedPrice[currency] || '';
		const months = currentPackage?.packageIdentifier === PlanTypes.YEARLY2 ? 24 : currentPackage?.packageIdentifier === PlanTypes.YEARLY ? 12 :  1;

		const _pricePerMonth = (couponPrice && couponValidPlan === currentPackage?.packageIdentifier) ? couponPrice/(couponGracePeriod || months) : currentPackagePrice/(currentPackageGracePeriod || months);

		return (Math.round(_pricePerMonth*100)/100).toFixed(2);
	},

	calculateSaving: (currentPackage, monthlyPrice, PlanTypes, currency, coupon, sentryProps = {}, freeSentry) => {
		const currentPackagePrice = currentPackage?.recurringPeriod?.basePrice.find(bp => bp.currencyCode === currency)?.amount || 0;
		const currentPackageGracePeriod = currentPackage?.recurringPeriod?.grace_period_months || 0;
		const couponValidPlan = coupon.validPlans && coupon?.validPlans[0] || '';
		const couponSentryPeriod = coupon?.sentryPeriod || 0;
		const couponGracePeriod = coupon?.grace_period_months || 0;
		const couponPrice = coupon?.typeParameters?.fixedPrice[currency] || '';
		const isSentryCoupon = (coupon?.validPlans || []).indexOf(PlanTypes.YEARLYS) > -1 || (coupon?.validPlans || []).indexOf(PlanTypes.YEARLY2S) > -1;
		const months = currentPackage?.packageIdentifier === PlanTypes.YEARLY2 ? 24 : currentPackage?.packageIdentifier === PlanTypes.YEARLY ? 12 :  1;
		const sentryPrice = ((currentPackage?.addonPackages || []).find(a => a.packageIdentifier.indexOf('SENTRY') > -1)?.recurringPeriod?.basePrice || []).find(b => b.currencyCode === currency)?.amount || 0;

		const _pricePerMonth = ((couponPrice && couponValidPlan === currentPackage?.packageIdentifier) ? couponPrice/(couponGracePeriod || months) : currentPackagePrice/(currentPackageGracePeriod || months));
		const _sentryPricePerMonth = (!freeSentry && !isSentryCoupon && sentryProps[currentPackage?.packageIdentifier] ? sentryPrice : 0); 

		const _priceMonth = (couponValidPlan === PlanTypes.MONTHLY ? couponPrice : monthlyPrice);
		const _sentryPriceMonth = (sentryProps[currentPackage?.packageIdentifier] ? sentryPrice : 0);

		const _months = couponValidPlan === currentPackage?.packageIdentifier && (couponGracePeriod || months) || currentPackageGracePeriod || months;
		const _sentryMonths = couponValidPlan === currentPackage?.packageIdentifier && (couponSentryPeriod || couponGracePeriod || months) || currentPackageGracePeriod || months;
		
		const regularPrice = _priceMonth*_months + _sentryPriceMonth*_sentryMonths;
		const discount = Math.round(((_priceMonth*_months + _sentryPriceMonth*_sentryMonths) - (_pricePerMonth*_months + _sentryPricePerMonth*_sentryMonths))*100)/100;
		const savingPercent = (regularPrice ? Math.round((discount/regularPrice)*100) : 0) + '%';
		
		return {
			regularPrice, 
			discount, 
			savingPercent
		};
	},

	executeCouponByPlan: (selectedPackage, PlanTypes, coupon, couponYearly2, couponMonthly) => {
		let _coupon = {};
		if(selectedPackage?.packageIdentifier === PlanTypes.YEARLY2) {
			_coupon = (coupon?.validPlans && coupon?.validPlans[0] === PlanTypes.YEARLY2) ? coupon : (couponYearly2?.code ? couponYearly2 : {});
		} else if(selectedPackage?.packageIdentifier === PlanTypes.YEARLY) {
			_coupon = (coupon?.validPlans && coupon?.validPlans[0] === PlanTypes.YEARLY) ? coupon : {};
		} else if(selectedPackage?.packageIdentifier === PlanTypes.MONTHLY) {
			_coupon = (coupon?.validPlans && coupon?.validPlans[0] === PlanTypes.MONTHLY) ? coupon : (couponMonthly?.code ? couponMonthly : {});
		}

		return _coupon
	},

	checkPaymentEligibility: (paymentMethods, paymentType) => {
		return (paymentMethods || []).indexOf(paymentType) === -1
	},
	
	combineStyles: (...styles) => {
		return (theme) => {
		  const outStyles = styles.map((arg) => {
			// Apply the "theme" object for style functions.
			if (typeof arg === 'function') {
			  return arg(theme);
			}
			// Objects need no change.
			return arg;
		  });
	  
		  return outStyles.reduce((acc, val) => Object.assign(acc, val));
		};
	},

	captureSentryMessage: (extraData = {})=> {
		Sentry.withScope(scope => {
			scope.setTag('react', true);
			scope.setExtras(extraData);
			scope.setFingerprint([extraData.errorType]);
			Sentry.captureMessage(extraData.message, extraData.messageType);
		});
	},
};

export default utility;