import React from 'react';

function Envelop() {
	return (
		<svg width="132" height="115" viewBox="0 0 132 115" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M34.6661 3.93775C35.7183 1.76299 38.3343 0.853003 40.509 1.90524L87.762 24.7681C89.9368 25.8203 90.8468 28.4363 89.7945 30.6111L77.6487 55.7142C76.5964 57.889 73.9804 58.7989 71.8057 57.7467L24.5527 34.8839C22.3779 33.8317 21.4679 31.2157 22.5202 29.0409L34.6661 3.93775Z" fill="url(#paint0_linear_3003_78514)"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M52.0284 34.1102C52.898 35.3463 54.3439 36.0458 55.8527 35.9605L84.1755 34.3596C86.5903 34.2231 88.7412 32.7882 89.7945 30.6111C90.8468 28.4363 89.9366 25.8202 87.762 24.7681L40.509 1.90524C38.3344 0.853077 35.7183 1.76302 34.6661 3.93775C33.6944 5.94606 33.8878 8.32331 35.1715 10.1481L52.0284 34.1102Z" fill="url(#paint1_linear_3003_78514)"/>
			<g opacity="0.5">
				<path d="M103.948 10.5286C104.74 10.0714 105.753 10.3427 106.21 11.1348L116.146 28.3436C116.603 29.1356 116.332 30.1483 115.54 30.6056L106.398 35.8838C105.605 36.3411 104.593 36.0697 104.135 35.2777L94.2 18.0689C93.7427 17.2769 94.0141 16.2642 94.8061 15.8069L103.948 10.5286Z" fill="url(#paint2_linear_3003_78514)"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M102.969 23.6698C102.978 24.2418 103.282 24.7684 103.773 25.0625L112.983 30.5845C113.768 31.0553 114.747 31.0634 115.54 30.6056C116.332 30.1483 116.603 29.1355 116.146 28.3436L106.21 11.1348C105.753 10.3428 104.74 10.0714 103.948 10.5286C103.217 10.9509 102.772 11.7365 102.786 12.5809L102.969 23.6698Z" fill="url(#paint3_linear_3003_78514)"/>
			</g>
			<g opacity="0.22">
				<path d="M131.165 31.3601C131.626 31.4838 131.9 31.958 131.776 32.4194L129.09 42.4443C128.966 42.9056 128.492 43.1794 128.031 43.0558L122.705 41.6288C122.244 41.5052 121.97 41.0309 122.093 40.5695L124.78 30.5447C124.903 30.0833 125.378 29.8095 125.839 29.9331L131.165 31.3601Z" fill="url(#paint4_linear_3003_78514)"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M125.95 35.8516C125.742 36.0664 125.66 36.3731 125.732 36.663L127.094 42.1037C127.21 42.5676 127.569 42.9321 128.031 43.0558C128.492 43.1794 128.966 42.9056 129.09 42.4443L131.776 32.4194C131.9 31.958 131.626 31.4838 131.165 31.3601C130.739 31.246 130.284 31.3718 129.977 31.6888L125.95 35.8516Z" fill="url(#paint5_linear_3003_78514)"/>
			</g>
			<path d="M4.49836 114.384H106.884C108.403 114.384 109.605 113.181 109.605 111.663V111.283C109.605 109.764 108.403 108.562 106.884 108.562H4.49836C2.97965 108.562 1.77734 109.764 1.77734 111.283V111.663C1.77734 113.181 2.97965 114.384 4.49836 114.384Z" fill="url(#paint6_linear_3003_78514)"/>
			<path d="M3.23288 109.321H95.6208L92.3303 56.9259C92.2037 54.5846 90.242 52.7495 87.9007 52.7495H4.43518C1.90401 52.7495 -0.120933 54.901 0.00562606 57.4322L3.23288 109.321Z" fill="url(#paint7_linear_3003_78514)"/>
			<path d="M45.3673 89.8636C47.8362 89.615 49.5254 86.3126 49.1402 82.4875C48.755 78.6624 46.4412 75.7631 43.9723 76.0117C41.5034 76.2604 39.8142 79.5628 40.1994 83.3878C40.5846 87.2129 42.8983 90.1122 45.3673 89.8636Z" fill="#F1F2FF"/>
			<defs>
				<linearGradient id="paint0_linear_3003_78514" x1="65.899" y1="54.8889" x2="49.7253" y2="10.0934" gradientUnits="userSpaceOnUse">
					<stop stopColor="#AAB0EA"/>
					<stop offset="1" stopColor="#E3E6FF"/>
				</linearGradient>
				<linearGradient id="paint1_linear_3003_78514" x1="69.3621" y1="46.7586" x2="59.7167" y2="6.79937" gradientUnits="userSpaceOnUse">
					<stop stopColor="#C6C9EA"/>
					<stop offset="1" stopColor="#F0F1FF"/>
				</linearGradient>
				<linearGradient id="paint2_linear_3003_78514" x1="102.894" y1="33.1266" x2="107.355" y2="15.659" gradientUnits="userSpaceOnUse">
					<stop stopColor="#AAB0EA"/>
					<stop offset="1" stopColor="#E3E6FF"/>
				</linearGradient>
				<linearGradient id="paint3_linear_3003_78514" x1="105.707" y1="31.3171" x2="111.185" y2="16.7522" gradientUnits="userSpaceOnUse">
					<stop stopColor="#C6C9EA"/>
					<stop offset="1" stopColor="#F0F1FF"/>
				</linearGradient>
				<linearGradient id="paint4_linear_3003_78514" x1="122.429" y1="39.3164" x2="130.528" y2="34.5131" gradientUnits="userSpaceOnUse">
					<stop stopColor="#AAB0EA"/>
					<stop offset="1" stopColor="#E3E6FF"/>
				</linearGradient>
				<linearGradient id="paint5_linear_3003_78514" x1="124.137" y1="39.6872" x2="131.539" y2="36.3311" gradientUnits="userSpaceOnUse">
					<stop stopColor="#C6C9EA"/>
					<stop offset="1" stopColor="#F0F1FF"/>
				</linearGradient>
				<linearGradient id="paint6_linear_3003_78514" x1="63.7002" y1="114.958" x2="63.6089" y2="105.689" gradientUnits="userSpaceOnUse">
					<stop stopColor="#C3C7F3"/>
					<stop offset="1" stopColor="#E6E8FE"/>
				</linearGradient>
				<linearGradient id="paint7_linear_3003_78514" x1="77.8841" y1="116.901" x2="17.6622" y2="67.2615" gradientUnits="userSpaceOnUse">
					<stop stopColor="#C6C9EA"/>
					<stop offset="1" stopColor="#F0F1FF"/>
				</linearGradient>
			</defs>
		</svg>

	);
}

export default Envelop;