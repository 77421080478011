import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import {
	Typography,
	Box
} from '@material-ui/core';

import Techradar from '../../UI/svg/Techradar';
import TomsGuide from '../../UI/svg/TomsGuide';
import PcMag from '../../UI/svg/PcMag';
import DigitalTrends from '../../UI/svg/DigitalTrends';
import Forbes from '../../UI/svg/Forbes'; 
import SecurityOrg from '../../UI/svg/SecurityOrg';
import Chip from '../../UI/svg/Chip';
import Netzwelt from '../../UI/svg/Netzwelt';
import PcMagazin from '../../UI/svg/PcMagazin';
import ComputerBild from '../../UI/svg/ComputerBild';
import VpnGids from '../../UI/svg/VpnGids';

function LogosV3({ country, classes, t }) {

	return (
		<Box className={classes.logosContainer}>
			<Typography className={classes.logosTitle}>{t('Recommended by:')}</Typography>
			{country === 'DE' ?
				<>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<Chip fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<Netzwelt fillColor={'#93999C'} fillColor1={'#D4D6D7'} />
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<PcMagazin fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<ComputerBild fillColor={'#93999C'} fillColor1={'#D4D6D7'} />
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<Techradar fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<DigitalTrends fillColor={'#93999C'} />
						</Box>
					</Box>
				</>: null
			}

			{country === 'NL' ?
				<>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<VpnGids fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<Techradar fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<PcMag fillColor={'#93999C'} />
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<DigitalTrends fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<TomsGuide fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<Forbes fillColor={'#93999C'} />
						</Box>
					</Box>
				</>: null
			}

			{country !== 'NL' && country !== 'DE' ?
				<>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<Techradar fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<TomsGuide fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<DigitalTrends fillColor={'#93999C'} />
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between" className={classes.logosRow}>
						<Box className={classes.logoCell}>
							<PcMag fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<Forbes fillColor={'#93999C'} />
						</Box>
						<Box className={classes.logoCell}>
							<SecurityOrg fillColor={'#93999C'} fillColor1={'#D4D6D7'} />
						</Box>
					</Box>
				</>: null
			}
		</Box>
	);
}

LogosV3.propTypes = {
	classes: PropTypes.object,
	country: PropTypes.string,
	t: PropTypes.func
};

export default withNamespaces()(LogosV3);