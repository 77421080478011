import React from 'react';
import PropTypes from 'prop-types';

function Techradar({fillColor}) {

	return (
		<svg width="94" height="33" viewBox="0 0 94 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2426_124528)">
				<path d="M7.13057 14.6704H4.87305V19.8082C4.87305 20.6801 5.37126 20.8669 6.19643 20.8669C6.50781 20.8669 6.81919 20.8047 7.13057 20.8047V23.4981C6.44553 23.4981 5.74492 23.6227 5.12216 23.6227C1.99277 23.6227 1.16761 22.6885 1.16761 19.6058V14.6549H-0.700684V12.2105H1.18318V8.83203H4.88862V12.2105H7.14614V14.6704H7.13057Z" fill={fillColor || '#2F6E91'}/>
				<path fillRule="evenodd" clipRule="evenodd" d="M12.9069 21.381C11.3344 21.381 10.4625 20.3846 10.4003 18.8121V18.7965H18.683C18.8699 14.9665 16.8614 11.8994 12.7823 11.8994C9.45055 11.8994 6.69482 14.4216 6.69482 17.8624C6.69482 21.6924 9.20145 23.8876 12.9069 23.8876C15.4135 23.8876 17.8579 22.7511 18.4806 20.1199H15.0399C14.6662 20.9451 13.7788 21.381 12.9069 21.381ZM12.7822 14.4212C14.0433 14.4212 14.9152 15.4799 14.9775 16.741H10.4002C10.587 15.3553 11.4589 14.4212 12.7822 14.4212Z" fill={fillColor || '#2F6E91'}/>
				<path d="M26.8257 16.367C26.8257 15.4328 25.9538 14.7322 25.0041 14.7322C22.9334 14.7322 22.622 16.4915 22.622 18.0484C22.622 19.4964 23.2448 20.991 24.8172 20.991C26.1406 20.991 26.7634 20.2437 26.9502 19.0449H30.5311C30.2197 22.112 27.8999 23.8713 24.8951 23.8713C21.5166 23.8713 18.9321 21.5515 18.9321 18.1107C18.9321 14.5298 21.1896 11.9609 24.8951 11.9609C27.7131 11.9609 30.2197 13.4711 30.4688 16.4137H26.8257V16.367Z" fill={fillColor || '#2F6E91'}/>
				<path d="M30.6401 8.84766H34.3455V13.7986H34.4078C34.9683 12.6777 36.4785 11.9148 37.8486 11.9148C41.6163 11.9148 41.9277 14.6705 41.9277 16.3053V23.576H38.2222V18.1268C38.2222 16.5544 38.4091 14.9974 36.3384 14.9974C34.9527 14.9974 34.33 16.1963 34.33 17.4418V23.5916H30.6245V8.84766H30.6401Z" fill={fillColor || '#2F6E91'}/>
				<path d="M42.239 12.2259H45.8198V14.2343H45.8821C46.4426 12.7864 47.7037 12.0391 49.2606 12.0391C49.572 12.0391 49.8211 12.0391 50.1325 12.1013V15.4798C49.6343 15.3553 49.1983 15.293 48.6846 15.293C46.863 15.293 45.9288 16.5541 45.9288 17.9865V23.5602H42.2234C42.239 23.5758 42.239 12.2259 42.239 12.2259Z" fill={fillColor || '#333333'}/>
				<path fillRule="evenodd" clipRule="evenodd" d="M50.1494 15.713C50.1489 15.7233 50.1485 15.7337 50.1481 15.7441L50.1325 15.713H50.1494ZM50.1494 15.713H53.511C53.6978 14.701 54.3984 14.2651 55.3948 14.2651C56.22 14.2651 57.2164 14.592 57.2164 15.4639C57.2164 16.1489 56.8428 16.398 56.22 16.5226C55.7216 16.6343 55.1687 16.7121 54.6012 16.7919C52.2437 17.1235 49.6343 17.4906 49.6343 20.4771C49.6343 22.8592 51.3936 23.9179 53.5266 23.9179C54.9122 23.9179 56.2979 23.5287 57.2943 22.47C57.2943 22.8436 57.3566 23.155 57.4811 23.5287H61.1866C60.7506 22.6568 60.7506 21.5825 60.7506 20.6484V20.6484V15.6974C60.7506 12.4902 58.1817 11.9297 55.4883 11.9297C54.2272 11.9297 52.9038 12.1788 51.9074 12.7393C50.9024 13.2959 50.2198 14.2211 50.1494 15.713ZM57.1698 18.3128C57.2943 20.6326 56.6716 21.629 54.9123 21.629C54.0871 21.629 53.402 21.2554 53.402 20.3835C53.402 19.496 54.1027 19.1224 54.9123 18.9356C55.2149 18.8347 55.561 18.7644 55.8988 18.6959C56.395 18.5951 56.8734 18.4981 57.1698 18.3128Z" fill={fillColor || '#333333'}/>
				<path fillRule="evenodd" clipRule="evenodd" d="M69.189 23.575H72.7077V8.84668H69.0022V13.5485H68.9399C68.1926 12.4743 67.1339 11.9761 65.7483 11.9761C62.5566 11.9761 60.9841 14.7318 60.9841 17.6744C60.9841 20.8193 62.4788 23.8864 66.0597 23.8864C67.2896 23.902 68.4573 23.2948 69.1268 22.2517H69.189V23.575ZM66.947 21.0683C65.2499 21.0683 64.6894 19.3868 64.6894 17.8143C64.6894 16.3664 65.2499 14.7317 66.947 14.8095C68.5817 14.8095 69.1422 16.3041 69.1422 17.9389C69.1422 19.3713 68.7685 21.0683 66.947 21.0683Z" fill={fillColor || '#333333'}/>
				<path fillRule="evenodd" clipRule="evenodd" d="M73.2852 15.713C73.2847 15.7233 73.2842 15.7337 73.2838 15.7441H73.2682V15.713H73.2852ZM73.2852 15.713H76.6467C76.8336 14.701 77.5342 14.2651 78.5306 14.2651C79.3558 14.2651 80.3522 14.592 80.3522 15.4639C80.3522 16.1489 79.9785 16.398 79.3558 16.5226C78.8574 16.6343 78.3044 16.7121 77.7369 16.7919C75.3794 17.1235 72.77 17.4906 72.77 20.4771C72.77 22.8592 74.5293 23.9179 76.6623 23.9179C77.9857 23.9179 79.4336 23.5287 80.43 22.47C80.43 22.8436 80.4923 23.155 80.6168 23.5287H84.3223C83.8864 22.6568 83.8864 21.5825 83.8864 20.6484V20.6484V15.6974C83.8864 12.4902 81.3175 11.9297 78.624 11.9297C77.3629 11.9297 76.0395 12.1788 75.0431 12.7393C74.0381 13.2959 73.3555 14.2211 73.2852 15.713ZM80.3054 18.3128C80.43 20.6326 79.8072 21.629 78.0479 21.629C77.2227 21.629 76.4754 21.2554 76.5377 20.3835C76.5377 19.496 77.2383 19.1224 78.0479 18.9356C78.3506 18.8347 78.6967 18.7644 79.0344 18.6959C79.5307 18.5951 80.009 18.4981 80.3054 18.3128Z" fill={fillColor || '#333333'}/>
				<path d="M84.4312 12.2259H88.0121V14.2343H88.0743C88.6348 12.7864 89.8959 12.0391 91.4528 12.0391C91.7642 12.0391 92.387 12.0391 92.7139 12.1013V15.4798C92.2157 15.3553 91.4528 15.293 90.9546 15.293C89.133 15.293 88.1989 16.5541 88.1989 17.9865V23.5602H84.4934V12.2103H84.4312V12.2259Z" fill={fillColor || '#333333'}/>
				<path d="M89.0085 19.8711H92.714V23.5765H89.0085V19.8711Z" fill={fillColor || '#E6248A'}/>
			</g>
			<defs>
				<clipPath id="clip0_2426_124528">
					<rect width="93.0749" height="32.6102" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
}

Techradar.propTypes = {
	fillColor: PropTypes.string
};

export default Techradar;