import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	footerWrapper: {
		padding: '30px 0',
		background: '#151935',
	},
	footerCopy: {
		fontSize: 13,
		fontWeight: 600,
		color: '#A8ACE3',
	},
	footerLink: {
		fontSize: 13,
		fontWeight: 600,
		color: '#A8ACE3',
		cursor: 'pointer',
		textTransform: 'initial',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		}
	},
	footerNavContainer: {
		display: 'flex',
	},
	footerNav: {
		'& $footerLink': {
			margin: '0 10px',
		}
	},
	footerCurrencyContainer: {
		position: 'relative',
		fontSize: 12,
		fontWeight: 700,
		color: '#a2a9d2',
		fontFamily: 'Spartan, sans-serif',
		textTransform: 'initial',
		textDecoration: 'none',
		lineHeight: '1.43',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: 100,
	},
	footerCurrency: {
		position: 'relative',
		cursor: 'pointer',
		paddingRight: 20,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			right: '2px',
			width: 9,
			height: 9,
			borderBottom: '2px solid #a2a9d2',
			borderRight: '2px solid #a2a9d2',
			transform: 'rotate(45deg)',
		},
	},
	footerCurrencyDisabled: {
		cursor: 'initial',
	},
	footerCurrencyOption: {
		position: 'absolute',
		top: '-12px',
		left: '30px',
		background: '#ecebeb',
		borderRadius: 10,
		padding: '10px 20px 10px 0px',
		cursor: 'initial',
		width: 85,
	},
	footerCurrencyOptionItem: {
		paddingLeft: '20px',
		color: '#6d7173',
		fontWeight: 600,
		cursor: 'pointer',
	},
	footerCurrencyOptionItemSelected: {
		position: 'relative',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 1,
			left: 8,
			width: 6,
			height: 10,
			borderBottom: '2px solid #6d7173',
			borderRight: '2px solid #6d7173',
			transform: 'rotate(45deg)',
		},

	},
	footerContainer: {},
	V2: {
		'&$footerWrapper': {
			background: 'transparent',
			padding: 0,
			'& $footerContainer': {
				padding: '20px 0 20px',
				borderTop: '1px solid #E6E5E5',
				
			}
		},
		'& $footerCopy': {
			color: '#A6A9AB',
		},
		'& $footerCurrencyContainer': {
			fontWeight: 500,
			width: 'initial',
			color: '#A6A9AB',
		},
		'& $footerCurrency': {
			padding: '10px 30px 10px 10px',
			background: '#ffffff',
			border: '1px solid #D4D6D7',
			borderRadius: 6,
			fontWeight: 600,
			'&::before': {
				top: 10,
				right: 10,
				borderBottom: '2px solid #A6A9AB',
				borderRight: '2px solid #A6A9AB',
			},
		},
		'& $footerCurrencyOption': {
			position: 'initial',
			background: '#ffffff',
			border: '1px solid #D4D6D7',
			borderRadius: 6,
			padding: '10px 18px 10px 0px',
			cursor: 'initial',
			width: 'initial',
		},
		'& $footerCurrencyOptionItem': {
			color: '#6d7173',
		},
		'& $footerCurrencyOptionItemSelected': {
			'&::before': {
				borderBottom: '2px solid #6d7173',
				borderRight: '2px solid #6d7173',
			},
	
		}
	},
	V3: {
		'&$footerWrapper': {
			background: '#E9EAEB',
			borderTop: '1px solid #D4D6D7',
			padding: 0,
			'& $footerContainer': {
				padding: theme.spacing(2, 5),
			}
		},
		'& $footerCopy': {
			fontWeight: 500,
			color: '#93999C',
		},
		'& $footerCurrencyContainer': {
			fontWeight: 500,
			width: 'initial',
			color: '#93999C',
		},
		'& $footerCurrency': {
			padding: '10px 30px 10px 10px',
			background: '#ffffff',
			border: '1px solid #D4D6D7',
			borderRadius: 6,
			fontWeight: 600,
			'&::before': {
				top: 10,
				right: 10,
				borderBottom: '2px solid #A6A9AB',
				borderRight: '2px solid #A6A9AB',
			},
		},
		'& $footerCurrencyOption': {
			position: 'initial',
			background: '#ffffff',
			border: '1px solid #D4D6D7',
			borderRadius: 6,
			padding: '10px 18px 10px 0px',
			cursor: 'initial',
			width: 'initial',
		},
		'& $footerCurrencyOptionItem': {
			color: '#6d7173',
		},
		'& $footerCurrencyOptionItemSelected': {
			'&::before': {
				borderBottom: '2px solid #6d7173',
				borderRight: '2px solid #6d7173',
			},
	
		}
	},
	'@media (max-width: 760px)': {
		footer: {
			display: 'flex',

			flexDirection: 'column',
			textAlign: 'center',
		},
		footerCopy: {
			order: 3,
		},
		footerNavContainer: {
			display: 'block',
		},
		footerNav: {
			'& $footerLink': {
				display: 'block',
				margin: '10px 10px'
			}
		},
		footerCurrencyContainer: {
			margin: '10px auto',
			justifyContent: 'center',
		},
		footerCurrencyOption: {
			position: 'initial',
		},
	},
}));