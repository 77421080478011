export const useStyles = () => ({
	proxyPopupBackdrop: {
		position: 'fixed',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		background: 'rgba(255, 255, 255, 0.95)',
		zIndex: 10000,
	},
	proxyPopupWrapper: {
		padding: 20,
		marginTop: 200,
	},
	proxyPopup: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: '#283339',
		padding: 35,
		width: 740,
		minHeight: 230,
		background: '#FFFFFF',
		border: '1px solid #D4D6D7',
		boxSizing: 'border-box',
		boxShadow: '0px 10px 20px rgba(109, 113, 115, 0.15)',
		borderRadius: 12,
	},
	proxyContent: {
		maxWidth: 457,
		marginLeft: 35,
	},
	proxyTitle: {
		fontSize: 28,
		fontWeight: 700,
		marginBottom: 15,
	},
	proxyText: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: 20,
		'& a': {
			color: '#5058c8',
			textDecoration: 'underline',
		}
	},
	proxyBtn: {
		background: '#FF8F00',
		borderRadius: 30,
		color: '#ffffff',
		cursor: 'pointer',
		maxWidth: 170,
		padding: 15,
		textAlign: 'center',
		fontSize: 14,
		fontWeight: 700,

	},
	'@media (max-width: 760px)': {
		proxyPopupWrapper: {
			marginTop: 100,
		},
		proxyPopup: {
			flexDirection: 'column',
			textAlign: 'center',
		},
		proxyContent: {
			margin: 0,
		},
		proxyTitle: {
			fontSize: 24,
			marginTop: 30,
		},
		proxyBtn: {
			maxWidth: '100%',
		},
	},
});