import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ProxyIllustration from './ProxyIllustration';
import { withNamespaces, Trans } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './ProxyDetectModalStyles';


function ProxyDetectModal({t, classes, onClick}) {

	return (
		<Box className={classes.proxyPopupBackdrop} >
			<Box display="flex" justifyContent="center" alignItems="center" className={classes.proxyPopupWrapper} >
				<Box className={classes.proxyPopup} >
					<ProxyIllustration />

					<Box className={classes.proxyContent}>
						<div className={classes.proxyTitle}>{t('Proxy Detected')}</div>
						<div className={classes.proxyText}>
							<Trans i18nKey="transProxyDetected">
								We detected you are using a proxy, please disconnect your proxy before placing an order or <a target="_blank" href='https://support.privadovpn.com/home/' rel='noreferrer'>contact our support</a>.
							</Trans>
						</div>
						<div className={classes.proxyBtn} onClick={onClick}>{t('Continue')}</div>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

ProxyDetectModal.propTypes = {
	i18n: PropTypes.object,
	classes: PropTypes.object,
	onClick: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(ProxyDetectModal));