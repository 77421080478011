import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import { LANGUAGES } from './config/main';
import Backend from 'i18next-http-backend';
import utils from './services/utils';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(reactI18nextModule) // passes i18n down to react-i18next
	.init({
		detection: {
			order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
			lookupFromPathIndex: 0
		},
		lng: utils.initLang(LANGUAGES) || 'en',
		load: 'all',
		lowerCaseLng: true,
		fallbackLng: 'en', // use en if detected lng is not available
		ns: ['translation'],
		defaultNS: 'translation',
		supportedLngs: LANGUAGES.map(l => l.lang),
		backend: {
			loadPath: '/translations/order_form/locale/{{ns}}.{{lng}}.json'
		},
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		//debug: process.env.NODE_ENV === 'development',
		react: {
			wait: true
		}
	});

export default i18n;