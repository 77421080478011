import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoaderSmall from '../../UI/LoaderSmall/LoaderSmall';
import PaymentInfoV2 from '../PaymentInfoV2/PaymentInfoV2';
import { PlanTypes } from '../../../config/plan-types';
import { withNamespaces } from 'react-i18next';
import utils from '../../../services/utils';
import MBG from '../../UI/MBG/MBG';
import {
	Grid,
	Container,
	Button
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './FormsV2Styles';

const { REACT_APP_SAVED_PAYMENT } = process.env;

function SavedPaymentV2({
	t,
	mbg,
	upgradeMode,
	planConfig,
	savingPercent,
	saving2Percent,
	classes,
	saving,
	currency,
	selectedPackage,
	formFields,
	paymentFields,
	withPassword,
	fieldsError,
	fieldsTouched,
	btnDisabled,
	btnProcessing,
	isCouponCodeFieldOpened,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponSavingPercent,
	couponRenewalPrice,
	couponCode,
	couponYearly2,
	couponYearly,
	couponMonthly,
	isProxyDetected,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onSubmitCouponCode,
	onChangeFormFields,
	onInit,
	onSubmit,
	onOpenCouponCodeField,
	selectedPaymentMethod
}) {

	useEffect(() => {
		if(!paymentFields[selectedPaymentMethod] && (selectedPaymentMethod === REACT_APP_SAVED_PAYMENT)) {
			onInit({
				[selectedPaymentMethod]: {
					termsAndConditions: false
				}
			});
		}
	}, [onInit, paymentFields, selectedPaymentMethod]);

	const _pf = paymentFields[selectedPaymentMethod] || {};
	const _pft = fieldsTouched[selectedPaymentMethod] || {};
	const _pfe = fieldsError[selectedPaymentMethod] || {};
	const _ff = {...formFields} || {};
	const _ft = {...fieldsTouched['formFields']} || {};
	const _fe = {...fieldsError['formFields']} || {};

	if(!withPassword) {
		delete _ff.password;
		delete _ft.password;
		delete _fe.password;
	}

	const _couponByPlan = utils.executeCouponByPlan(selectedPackage, PlanTypes, couponYearly, couponYearly2, couponMonthly);
	const _paymentNotEligible = false; //_couponByPlan?.code && utils.checkPaymentEligibility(_couponByPlan?.payment_methods, REACT_APP_PAYPAL_FLEXIBLE);
	const disabled = (Object.keys(_pfe).length + Object.keys(_fe).length > 0) || (Object.keys(_ff).length + Object.keys(_pf).length !== Object.keys(_pft).length + Object.keys(_ft).length) || !_pf.termsAndConditions || btnDisabled || btnProcessing || isProxyDetected || _paymentNotEligible;

	return (
		<Container maxWidth="sm" component="main">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PaymentInfoV2
						_pf={_pf}
						_pft={_pft}
						_pfe={_pfe}
						paymentNotEligible={_paymentNotEligible}
						paymentDisplayName="PayPal"
						couponByPlan={_couponByPlan}
						saving={saving}
						savingPercent={savingPercent}
						saving2Percent={saving2Percent}
						selectedPackage={selectedPackage}
						currency={currency}
						customClassName="paymentInfoPayPalContainerV2"
						isCouponCodeFieldOpened={isCouponCodeFieldOpened}
						couponPrice={couponPrice}
						couponGracePeriod={couponGracePeriod}
						couponValidPlan={couponValidPlan}
						couponSavingPercent={couponSavingPercent}
						couponRenewalPrice={couponRenewalPrice}
						couponCode={couponCode}
						onChangePaymentMethod={onChangePaymentMethod}
						onOpenCouponCodeField={onOpenCouponCodeField}
						onChangeCouponCodeField={onChangeCouponCodeField}
						onSubmitCouponCode={onSubmitCouponCode}
						onChangeFormFields={onChangeFormFields}
						planConfig={planConfig}
					/>
				</Grid>
			</Grid>

			<div className={classes.submitContainer}>
				<div>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={disabled}
						onClick={onSubmit}
					>
						{btnProcessing ? <LoaderSmall /> : null}
						{t(btnProcessing ? 'Processing' : upgradeMode ? 'Confirm Purchase' : 'Get Now')}
					</Button>
					{isProxyDetected ?
						<div className={classes.errorIcon}>{t('We detected you are using a proxy, please disconnect your proxy before placing an order.')}</div>: null
					}
				</div>
				{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
					<div className={classes.moneyBackLg}>
						<div className={classes.moneyBackLgBadge}>
							<MBG />
						</div>
						<div className={classes.moneyBackLgTitle}>{t('30-Day Money Back Guarantee')}</div>
						<div className={classes.moneyBackLgContent}>{t('Try PrivadoVPN Premium for 30 days, risk-free. If you aren\'t entirely satisfied, we will give you a full refund.')}</div>
					</div>: null
				}
			</div>
			{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
				<div className={classes.moneyBackSm}>
					<div className={classes.moneyBackContentSm}>
						{t('30-Day Money Back Guarantee')}
					</div>
				</div> : null
			}
		</Container>
	);
}

SavedPaymentV2.propTypes = {
	mbg: PropTypes.bool,
	upgradeMode: PropTypes.bool,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	currency: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.object,
	selectedPackage: PropTypes.object,
	selectedPaymentMethod: PropTypes.string,
	formFields: PropTypes.object,
	paymentFields: PropTypes.object,
	withPassword: PropTypes.bool,
	fieldsError: PropTypes.object,
	fieldsTouched: PropTypes.object,
	btnDisabled: PropTypes.bool,
	btnProcessing: PropTypes.bool,
	couponYearly: PropTypes.object,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponCode: PropTypes.string,
	couponYearly2: PropTypes.object,
	couponMonthly: PropTypes.object,
	isCouponCodeFieldOpened: PropTypes.bool,
	isProxyDetected: PropTypes.bool,
	onChangePaymentMethod: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onSubmitCouponCode: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onInit: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpenCouponCodeField: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(SavedPaymentV2));