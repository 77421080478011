import React from 'react';

function Devices() {

	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="4" fill="#5058C8"/>
			<path d="M7.12745 23H17.6667C17.4634 22.622 17.3553 22.1903 17.3531 21.7499V20.5H6.56394C6.25252 20.5 6 20.7799 6 21.125V21.7499C6 22.4402 6.50462 22.9995 7.12745 23Z" fill="white"/>
			<path d="M6.83301 9.74972V19.6667H17.3639V12.6662C17.3653 11.3785 18.4043 10.3349 19.6867 10.3331H24.333V9.74972C24.3317 8.7837 23.5518 8.0009 22.5901 8H8.57577C7.61408 8.00135 6.83523 8.7837 6.83389 9.74972H6.83301Z" fill="white"/>
			<path d="M24.7499 11.3333H19.7496C19.0598 11.3342 18.501 11.8562 18.5 12.5V21.8332C18.501 22.477 19.0598 22.9991 19.7496 23H24.7499C25.4398 22.9991 25.999 22.4775 26 21.8332V12.5C25.999 11.8557 25.4402 11.3342 24.7499 11.3333ZM22.2497 13.0834C22.5949 13.0834 22.8749 13.3446 22.8749 13.6668C22.8749 13.9889 22.5949 14.2501 22.2497 14.2501C21.9046 14.2501 21.6247 13.9889 21.6247 13.6668C21.6247 13.3446 21.9046 13.0834 22.2497 13.0834ZM22.8749 21.2494H21.6247C21.2795 21.2472 21.0016 20.9841 21.004 20.662C21.0064 20.343 21.2829 20.085 21.6247 20.0827H22.8749C23.22 20.0805 23.5018 20.3399 23.5042 20.662C23.5067 20.9841 23.2286 21.2472 22.8835 21.2494C22.8806 21.2494 22.8778 21.2494 22.8749 21.2494Z" fill="white"/>
		</svg>
	);
}

export default Devices;