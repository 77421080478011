import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PlanTypes } from '../../config/plan-types';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import {
	getRecaptchaKey
} from '../../store/actions';


function FreeRecaptcha({
	loadUnconditionallyCaptchaKey,
	selectedPackage: {packageIdentifier},
	recaptchaFreeRef,
	email,
	emailSuccess,
	asyncScriptOnLoad,
	onGetRecaptchaKey
}) {
	const [recaptchaKey, updateRecaptchaKey] = useState('');

	useEffect(() => {
		if(emailSuccess && packageIdentifier === PlanTypes.FREE && !recaptchaKey) {
			onGetRecaptchaKey({platform: 'web', email}, loadUnconditionallyCaptchaKey).then(response => {
				if(response?.data?.recaptcha_key) {
					updateRecaptchaKey(response?.data?.recaptcha_key);
				}
			});
		}
	}, [packageIdentifier, emailSuccess, loadUnconditionallyCaptchaKey]);

	return (
		recaptchaKey ?  
			<div style={{visibility: (packageIdentifier === PlanTypes.FREE ? 'initial' : 'hidden')}}>
				<ReCAPTCHA
					ref={recaptchaFreeRef}
					size="invisible"
					sitekey={recaptchaKey}
					asyncScriptOnLoad={e => asyncScriptOnLoad(e, loadUnconditionallyCaptchaKey)}
				/>
			</div>
			: null
	);
}

const mapStateToProps = (state) => {
	return {
		loadUnconditionallyCaptchaKey: state.home.loadUnconditionallyCaptchaKey
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetRecaptchaKey: (dataToSend, loadUnconditionally) => dispatch(getRecaptchaKey(dataToSend, loadUnconditionally))
	};
};

FreeRecaptcha.propTypes = {
	loadUnconditionallyCaptchaKey: PropTypes.bool,
	recaptchaFreeRef: PropTypes.object,
	selectedPackage: PropTypes.object,
	email: PropTypes.string,
	emailSuccess: PropTypes.bool,
	onGetRecaptchaKey: PropTypes.func,
	asyncScriptOnLoad: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeRecaptcha);