import React from 'react';
import PropTypes from 'prop-types';

function Netzwelt({fillColor, fillColor1}) {

	return (
		<svg width="139" height="33" viewBox="0 0 139 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2426_124255)">
				<path d="M22.0909 12.5104V26.8556L28.0955 20.517V6.17188L22.0909 12.5104Z" fill={fillColor || '#1652A1'}/>
				<path d="M18.5883 0L31.0979 21.6011L12 32.6102L-0.509521 11.0091L18.5883 0Z" fill={fillColor || 'url(#paint0_linear_2426_124255)'}/>
				<path d="M8.74756 26.2709L14.9189 19.6821L8.74756 12.7598V26.2709Z" fill={fillColor1 || '#1652A1'}/>
				<path d="M13.4177 9.75781L18.0046 17.681L10.3321 22.1013L5.74524 14.1781L13.4177 9.75781Z" fill={fillColor1 || 'url(#paint1_linear_2426_124255)'}/>
				<path d="M48.2775 9.1748V23.5199H45.6088L41.1054 15.0963V23.5199H38.1865V9.1748H40.9386L45.3587 17.2648V9.1748H48.2775Z" fill={fillColor || 'black'}/>
				<path d="M54.9494 11.9271V14.7627H58.9525V17.5984H54.9494V20.7677H60.12V23.5199H51.9471V9.1748H60.12V11.9271H54.9494Z" fill={fillColor || 'black'}/>
				<path d="M72.7962 11.9271H69.5437V23.5199H66.6248V11.9271H63.2889V9.1748H72.7962V11.9271Z" fill={fillColor || 'black'}/>
				<path d="M84.9722 11.6769L79.3846 20.7677H84.8888V23.5199H75.7986V21.1013L81.3028 11.9271H75.9654V9.1748H84.9722V11.6769Z" fill={fillColor || 'black'}/>
				<path d="M105.488 9.1748L102.402 23.5199H99.233L96.8145 14.7627L94.4794 23.5199H91.3103L88.1412 9.1748H91.3103L93.0616 18.2656L95.3968 9.1748H98.3157L100.651 18.1822L102.402 9.1748H105.488Z" fill={fillColor || 'black'}/>
				<path d="M111.659 11.9271V14.7627H115.662V17.5984H111.659V20.7677H116.83V23.5199H108.74V9.1748H116.83V11.9271H111.659Z" fill={fillColor || 'black'}/>
				<path d="M123.501 20.7677H128.338V23.5199H120.499V9.1748H123.501V20.7677Z" fill={fillColor || 'black'}/>
				<path d="M129.256 9.1748V11.9271H132.508V23.5199H135.511V11.9271H138.763V9.1748H129.256Z" fill={fillColor || 'black'}/>
			</g>
			<defs>
				<linearGradient id="paint0_linear_2426_124255" x1="10.6503" y1="8.27422" x2="21.5874" y2="27.2167" gradientUnits="userSpaceOnUse">
					<stop stopColor="#8BCDCE"/>
					<stop offset="1.17e-06" stopColor="#8BCDCE"/>
					<stop offset="1" stopColor="#4B55A1"/>
				</linearGradient>
				<linearGradient id="paint1_linear_2426_124255" x1="9.58047" y1="11.953" x2="14.1718" y2="19.905" gradientUnits="userSpaceOnUse">
					<stop stopColor="#8BCDCE"/>
					<stop offset="1.17e-06" stopColor="#8BCDCE"/>
					<stop offset="1" stopColor="#4B55A1"/>
				</linearGradient>
				<clipPath id="clip0_2426_124255">
					<rect width="138.593" height="32.6102" fill="white"/>
				</clipPath>
			</defs>
		</svg>

	);
}

Netzwelt.propTypes = {
	fillColor: PropTypes.string,
	fillColor1: PropTypes.string
};

export default Netzwelt;