import React from 'react';

function Amazon() {

	return (
		<svg width="84" height="26" viewBox="0 0 84 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M51.8333 20.0315C46.978 23.6102 39.9406 25.5196 33.8815 25.5196C25.3856 25.5196 17.7372 22.3773 11.9509 17.151C11.4963 16.74 11.9036 16.18 12.4491 16.5C18.6937 20.1333 26.4149 22.3191 34.3906 22.3191C39.7696 22.3191 45.6869 21.2062 51.1277 18.8967C51.9496 18.5476 52.637 19.435 51.8333 20.0315Z" fill="#FF9900"/>
			<path d="M53.8517 17.7213C53.2335 16.9284 49.7493 17.3467 48.1854 17.5322C47.709 17.5904 47.6363 17.1757 48.0654 16.8775C50.8404 14.9245 55.3938 15.4882 55.9248 16.1429C56.4558 16.8011 55.7866 21.3655 53.1789 23.544C52.7789 23.8786 52.397 23.7004 52.5752 23.2567C53.1607 21.7946 54.4737 18.5178 53.8517 17.7213Z" fill="#FF9900"/>
			<path d="M48.2945 3.09045V1.19199C48.2945 0.90467 48.5128 0.711914 48.7746 0.711914H57.2741C57.5468 0.711914 57.7651 0.908307 57.7651 1.19199V2.81769C57.7614 3.09045 57.5323 3.44687 57.125 4.01059L52.7207 10.2988C54.3573 10.2588 56.0848 10.5025 57.5687 11.339C57.9033 11.5281 57.9942 11.8045 58.0196 12.0773V14.103C58.0196 14.3794 57.7141 14.7031 57.3941 14.5358C54.7792 13.1647 51.3059 13.0156 48.4146 14.5504C48.12 14.7104 47.8108 14.3903 47.8108 14.1139V12.19C47.8108 11.8809 47.8145 11.3535 48.1236 10.8844L53.2262 3.56689H48.7855C48.5128 3.56689 48.2945 3.37413 48.2945 3.09045Z" fill="#221F1F"/>
			<path d="M17.2899 14.9354H14.704C14.4567 14.9172 14.2603 14.7317 14.2421 14.4953V1.22425C14.2421 0.958759 14.464 0.747816 14.7404 0.747816H17.1517C17.4026 0.75872 17.6026 0.951483 17.6208 1.19152V2.92633H17.6681C18.2973 1.24971 19.4793 0.467773 21.0722 0.467773C22.6907 0.467773 23.7017 1.24971 24.4291 2.92633C25.0547 1.24971 26.4767 0.467773 28.0006 0.467773C29.0844 0.467773 30.27 0.915113 30.9937 1.9189C31.812 3.03543 31.6447 4.6575 31.6447 6.07953L31.6411 14.4553C31.6411 14.7208 31.4193 14.9354 31.1429 14.9354H28.5606C28.3024 14.9172 28.0951 14.7099 28.0951 14.4553V7.42155C28.0951 6.86147 28.146 5.46489 28.0224 4.9339C27.8296 4.04286 27.2514 3.79191 26.5022 3.79191C25.8766 3.79191 25.222 4.21016 24.9565 4.87935C24.691 5.54854 24.7164 6.66871 24.7164 7.42155V14.4553C24.7164 14.7208 24.4946 14.9354 24.2182 14.9354H21.636C21.3741 14.9172 21.1704 14.7099 21.1704 14.4553L21.1668 7.42155C21.1668 5.94133 21.4105 3.76282 19.5738 3.76282C17.7154 3.76282 17.7881 5.88677 17.7881 7.42155V14.4553C17.7881 14.7208 17.5663 14.9354 17.2899 14.9354Z" fill="#221F1F"/>
			<path d="M65.0825 0.467773C68.9195 0.467773 70.9962 3.76282 70.9962 7.95254C70.9962 12.0004 68.7013 15.2118 65.0825 15.2118C61.3147 15.2118 59.2635 11.9168 59.2635 7.8107C59.2635 3.67917 61.3402 0.467773 65.0825 0.467773ZM65.1044 3.17727C63.1986 3.17727 63.0786 5.77403 63.0786 7.39245C63.0786 9.01452 63.0531 12.4769 65.0825 12.4769C67.0865 12.4769 67.181 9.68371 67.181 7.98163C67.181 6.86147 67.1338 5.52308 66.7955 4.4611C66.5046 3.53733 65.9263 3.17727 65.1044 3.17727Z" fill="#221F1F"/>
			<path d="M75.9715 14.9354H73.3965C73.1383 14.9172 72.931 14.7099 72.931 14.4553L72.9274 1.18061C72.9492 0.936935 73.1638 0.747816 73.4256 0.747816H75.8223C76.0478 0.75872 76.2333 0.911477 76.2842 1.11878V3.14818H76.3315C77.0553 1.33336 78.07 0.467773 79.8557 0.467773C81.0159 0.467773 82.1469 0.886019 82.8743 2.03165C83.5508 3.09362 83.5508 4.87935 83.5508 6.16318V14.5172C83.5217 14.7499 83.3071 14.9354 83.0525 14.9354H80.4594C80.223 14.9172 80.0266 14.7427 80.0012 14.5172V7.30881C80.0012 5.85768 80.1685 3.73372 78.3827 3.73372C77.7536 3.73372 77.1753 4.1556 76.888 4.7957C76.5243 5.60673 76.477 6.41413 76.477 7.30881V14.4553C76.4734 14.7208 76.2479 14.9354 75.9715 14.9354Z" fill="#221F1F"/>
			<path d="M7.61933 8.59661V8.03653C5.74996 8.03653 3.77511 8.43659 3.77511 10.6406C3.77511 11.7571 4.35338 12.5136 5.34626 12.5136C6.07364 12.5136 6.72465 12.0662 7.13562 11.3388C7.64479 10.4442 7.61933 9.60403 7.61933 8.59661ZM10.227 14.8994C10.0561 15.0521 9.80875 15.063 9.61599 14.9612C8.75768 14.2484 8.60493 13.9174 8.13213 13.2373C6.71374 14.6848 5.70995 15.1176 3.86967 15.1176C1.6948 15.1176 0 13.7756 0 11.0879C0 8.9894 1.13835 7.56009 2.75678 6.8618C4.16063 6.24353 6.12092 6.13442 7.61933 5.96349V5.62889C7.61933 5.01425 7.66661 4.28687 7.30656 3.75588C6.99014 3.27944 6.38642 3.08305 5.85543 3.08305C4.86982 3.08305 3.98969 3.58858 3.77511 4.63601C3.73147 4.86878 3.56054 5.0979 3.32777 5.10881L0.818305 4.83968C0.607364 4.7924 0.374601 4.62146 0.432792 4.29778C1.01106 1.25732 3.75693 0.34082 6.21548 0.34082C7.47385 0.34082 9.11774 0.675416 10.1106 1.62829C11.369 2.80301 11.249 4.37052 11.249 6.07623V10.1059C11.249 11.317 11.7509 11.848 12.2237 12.5027C12.391 12.7354 12.4273 13.0155 12.2164 13.19C11.689 13.6301 10.7507 14.4484 10.2343 14.9067L10.227 14.8994" fill="#221F1F"/>
			<path d="M41.1906 8.59661V8.03653C39.3212 8.03653 37.3464 8.43659 37.3464 10.6406C37.3464 11.7571 37.9247 12.5136 38.9175 12.5136C39.6449 12.5136 40.2959 12.0662 40.7069 11.3388C41.2161 10.4442 41.1906 9.60403 41.1906 8.59661ZM43.7983 14.8994C43.6273 15.0521 43.38 15.063 43.1873 14.9612C42.329 14.2484 42.1762 13.9174 41.7034 13.2373C40.285 14.6848 39.2812 15.1176 37.4409 15.1176C35.2661 15.1176 33.5713 13.7756 33.5713 11.0879C33.5713 8.9894 34.7096 7.56009 36.3281 6.8618C37.7319 6.24353 39.6922 6.13442 41.1906 5.96349V5.62889C41.1906 5.01425 41.2379 4.28687 40.8778 3.75588C40.5614 3.27944 39.9577 3.08305 39.4267 3.08305C38.4411 3.08305 37.561 3.58858 37.3464 4.63601C37.3027 4.86878 37.1318 5.0979 36.899 5.10881L34.3896 4.83968C34.1786 4.7924 33.9459 4.62146 34.0041 4.29778C34.5823 1.25732 37.3282 0.34082 39.7868 0.34082C41.0451 0.34082 42.689 0.675416 43.6819 1.62829C44.9403 2.80301 44.8202 4.37052 44.8202 6.07623V10.1059C44.8202 11.317 45.3221 11.848 45.7949 12.5027C45.9622 12.7354 45.9986 13.0155 45.7877 13.19C45.2603 13.6301 44.322 14.4484 43.8055 14.9067L43.7983 14.8994" fill="#221F1F"/>
		</svg>
	);
}

export default Amazon;