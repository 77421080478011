import React from 'react';

function MenuIcon() {

	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_105_1875)">
				<rect height="0.01" stroke="#525b60" strokeLinejoin="round" strokeWidth="3" width="0.01" x="12" y="12"/>
				<rect height="0.01" stroke="#525b60" strokeLinejoin="round" strokeWidth="3" width="0.01" x="12" y="5"/>
				<rect height="0.01" stroke="#525b60" strokeLinejoin="round" strokeWidth="3" width="0.01" x="12" y="19"/>
			</g>
			<defs>
				<clipPath id="clip0_105_1875">
					<rect fill="white" height="24" transform="translate(0 0.000976562)" width="24"/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default MenuIcon;