export const PlanTypes = {
	CLASSIC: 'classic',
	MONTHLY: 'PREMIUM10',
	YEARLY: 'PREMIUM120',
	YEARLYS: 'PREMIUMS120',
	YEARLY2: 'PREMIUM240',
	YEARLY2S: 'PREMIUMS240',
	OVERQUOTA: 'OVERQUOTA',
	FREE: 'FREE'
};

export const Saving = {
	percent: '55%',
};
