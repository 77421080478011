import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Container, Typography, Box} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './AimtellBannerStyles';
import { withNamespaces, Trans } from 'react-i18next';

function AimtellBanner({t, classes, price, onClickBanner}) {
	const [seconds, onUpdateSeconds] = useState(172000);
	let intervalId = -1;

	const convertSecondsToReadableString = (seconds) => {
		const _s = (seconds > 0) ? seconds : 0;
		const d = Math.floor(_s / (3600 * 24));
		const h = Math.floor(_s % (3600 * 24) / 3600);
		const m = Math.floor(_s % 3600 / 60);
		const s = Math.floor(_s % 60);

		return {d, h, m, s};
	};

	useEffect(() => {
		intervalId = setInterval(() => {
			onUpdateSeconds(prevTime => prevTime - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	const dateArray = convertSecondsToReadableString(seconds);
	const _days = dateArray.d;
	const _hours = dateArray.h;
	const _miuntes = dateArray.m;
	const _seconds = dateArray.s;

	return (
		<div className={classes.aimtellBanner} onClick={onClickBanner}>
			<span className={classes.aimtellBgC1}></span>
			<span className={classes.aimtellBgC2}></span>
			<span className={classes.aimtellBgC3}></span>
			<span className={classes.aimtellBgC4}></span>
			<Container component="div" className={classes.aimtellBannerContainer}>
				<Typography component="p" className={classes.aimtellBannerText}>
					<Trans i18nKey="transAimtellTitle">
						Sign up now and get {{transAimtellPrice: price}} VPN for a limited time only!
					</Trans>
				</Typography>
				<Typography component="div" className={classes.aimtellBannerClock}>
					<Box className={classes.aimtellBannerClocktem}>
						<span>{_days}</span>
						<span className={classes.aimtellBannerClocktemText}>{t('DAY' + (_days > 1 ? 'S' : ''))}</span>
					</Box>
					<Box className={classes.aimtellBannerClocktem}>
						<span>{_hours}</span>
						<span className={classes.aimtellBannerClocktemText}>{t('HOUR' + (_hours > 1 ? 'S' : ''))}</span>
					</Box>
					<Box className={classes.aimtellBannerClocktem}>
						<span>{_miuntes}</span>
						<span className={classes.aimtellBannerClocktemText}>{t('MINUTE' + (_miuntes > 1 ? 'S' : ''))}</span>
					</Box>
					<Box className={classes.aimtellBannerClocktem}>
						<span>{_seconds}</span>
						<span className={classes.aimtellBannerClocktemText}>{t('SECOND' + (_seconds > 1 ? 'S' : ''))}</span>
					</Box>
				</Typography>
				<Typography component="div" className={classes.aimtellBannerCTA}>
					{t('Get Deal')}
				</Typography>
			</Container>
		</div>
	);
}

AimtellBanner.propTypes = {
	classes: PropTypes.object,
	price: PropTypes.string,
	onClickBanner: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(useStyles)(withNamespaces()(AimtellBanner));