import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api';

export const securityCheckSlice = createSlice({
	name: 'securityCheck',
	initialState: {
		securityDetails: {is_blocked: false, is_in_european_union: false, iso_code: '', current_currency: 'USD'},
		checkingSecurity: true,
	},
	reducers: {
		updateSecurityDetails: (state, { payload }) => {
			state.securityDetails = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase('currency/changeCurrency', (state, { payload }) => {
				state.securityDetails.current_currency = payload;
			})
			.addMatcher(api.endpoints.onCheckSecurity.matchPending, (state) => {
				state.checkingSecurity = true;
			})
			.addMatcher(api.endpoints.onCheckSecurity.matchFulfilled, (state, { payload }) => {
				state.securityDetails = payload;
				state.checkingSecurity = false;
			})
			.addMatcher(api.endpoints.onCheckSecurity.matchRejected, (state) => {
				state.securityDetails = [];
				state.checkingSecurity = false;
			});
	},
});

export const { updateSecurityDetails } = securityCheckSlice.actions;

export default securityCheckSlice.reducer;