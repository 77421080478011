import React from 'react';
import PropTypes from 'prop-types';

function PcMagazin({fillColor}) {

	return (
		<svg width="111" height="33" viewBox="0 0 111 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M45.4572 5.35352C44.5676 5.39714 43.6781 5.39714 42.7885 5.35352C42.3882 5.35352 42.2547 5.48439 42.1213 5.83338C40.9204 9.10515 39.6305 12.3333 38.4741 15.6487C38.4296 15.6051 38.3851 15.5615 38.3851 15.4742C37.2731 12.2024 35.9832 9.06153 34.7823 5.83338C34.6489 5.48439 34.471 5.35352 34.1151 5.35352C33.1811 5.39714 32.247 5.39714 31.3575 5.35352C31.0016 5.35352 30.8237 5.39714 30.8237 5.83338C30.8237 11.4172 30.8237 17.001 30.8237 22.5849C30.8237 22.9338 30.9127 23.0211 31.2685 23.0211C32.0691 22.9775 32.9142 22.9775 33.7148 23.0211C34.1151 23.0211 34.2041 22.9338 34.2041 22.5412C34.2041 20.0983 34.2041 17.6118 34.2041 15.1688C34.2041 14.2091 34.2041 13.293 34.2041 12.2024C34.2931 12.4642 34.3375 12.5514 34.382 12.6823C35.494 15.6487 36.606 18.6151 37.6734 21.6251C37.8069 21.9741 37.9848 22.105 38.3851 22.1486C38.8299 22.1486 39.0078 22.0177 39.1412 21.6251C40.1642 18.7896 41.2317 15.9104 42.2992 13.0749C42.3882 12.8132 42.4326 12.5514 42.655 12.246C42.655 12.4642 42.655 12.6387 42.655 12.7695C42.5216 16.0413 42.6106 19.2695 42.5661 22.5412C42.5661 22.8466 42.655 22.9775 42.9664 22.9775C43.856 22.9775 44.7011 22.9775 45.5906 22.9775C45.902 22.9775 45.9909 22.8902 45.9909 22.5849C45.9909 21.9305 45.9909 21.2761 45.9909 20.5782C45.9909 15.6487 45.9909 10.7629 45.9909 5.83338C45.9909 5.48439 45.902 5.35352 45.4572 5.35352Z" fill={fillColor || '#CC2835'}/>
			<path d="M6.71628 5.44077C4.67026 5.26627 2.57976 5.44076 0.533742 5.35352C0.0444773 5.35352 0 5.528 0 5.92062C0 8.66891 0 11.4172 0 14.1655C0 16.9574 0 19.7493 0 22.5412C0 22.8902 0.0889573 23.0211 0.444786 23.0211C1.42332 22.9775 2.35737 22.9775 3.3359 23.0211C3.69173 23.0211 3.78068 22.8902 3.78068 22.5849C3.78068 20.7963 3.78068 19.0513 3.78068 17.2628C3.78068 16.8265 3.86964 16.6957 4.31443 16.6957C5.60431 16.7393 6.84971 16.6957 8.09511 16.3031C12.1427 15.0816 13.6105 10.2394 10.8528 7.22933C9.7853 6.05149 8.31751 5.52802 6.71628 5.44077ZM6.09357 13.5548C5.42639 13.5984 4.75921 13.5548 4.13651 13.5984C3.82516 13.5984 3.78068 13.4675 3.78068 13.2494C3.78068 12.5078 3.78068 11.7662 3.78068 11.0682C3.78068 10.3266 3.78068 9.58501 3.78068 8.79978C3.78068 8.62529 3.78069 8.4508 4.04756 8.4508C4.80369 8.49442 5.55983 8.40717 6.31596 8.53804C7.78376 8.79978 8.5399 9.71588 8.45094 11.1991C8.45094 12.5514 7.56137 13.4675 6.09357 13.5548Z" fill={fillColor || '#CC2835'}/>
			<path d="M69.2977 9.75879C68.4971 9.75879 67.652 9.80241 66.8514 9.75879C66.4066 9.75879 66.1397 9.80241 66.2287 10.3695C66.0952 10.3259 66.0508 10.2823 65.9618 10.2823C63.2486 9.10443 60.5799 10.2386 59.7348 13.0306C58.9787 15.4299 59.0232 17.8728 59.7793 20.2285C60.402 22.1479 61.9587 23.1512 64.0048 23.0204C64.7609 22.9768 65.4281 22.715 66.1397 22.366C66.2287 23.8056 65.517 24.6345 63.9603 25.0271C62.7593 25.2888 61.5584 25.3324 60.3575 25.3324C60.0017 25.3324 59.9127 25.3761 60.0462 25.725C60.2241 26.1613 60.402 26.6411 60.5354 27.121C60.6244 27.4264 60.8023 27.5136 61.1136 27.5136C62.2701 27.5136 63.3821 27.47 64.5385 27.3391C65.8284 27.1646 67.0738 26.9029 68.1413 26.1177C69.4756 25.1143 69.787 23.6747 69.8315 22.1479C69.8759 18.1782 69.8315 14.2084 69.8315 10.2387C69.7425 9.80242 69.6091 9.75879 69.2977 9.75879ZM66.1842 19.007C66.1842 19.6614 65.7394 20.1848 64.9388 20.403C64.2271 20.6211 63.6045 20.403 63.2041 19.8359C62.8928 19.356 62.8038 18.7889 62.7594 18.2654C62.6259 16.8694 62.6259 15.4299 62.8483 14.0339C62.8483 13.903 62.8928 13.7722 62.9373 13.6413C63.1152 13.0306 63.3376 12.4635 64.0937 12.3326C64.9388 12.2017 65.606 12.4634 66.0953 13.1178C66.2287 13.2923 66.2287 13.4668 66.2287 13.6849C66.2287 14.601 66.2287 15.5171 66.2287 16.3896C66.1842 17.262 66.1842 18.1345 66.1842 19.007Z" fill={fillColor || '#CC2835'}/>
			<path d="M25.0414 18.615C24.4187 18.3969 23.796 18.1788 23.1733 17.9606C22.1948 17.6117 22.1948 17.6117 21.7055 18.5278C21.5276 18.8331 21.3497 19.0949 21.1718 19.3566C20.8604 19.7492 20.4156 20.011 19.8819 20.0982C18.592 20.2727 17.6135 19.6183 17.0797 18.2224C16.5905 16.9137 16.5015 15.5613 16.5015 14.209C16.5015 13.3802 16.546 12.5513 16.6794 11.7661C16.8129 10.85 17.0353 9.93389 17.6135 9.19229C18.6365 7.83995 20.638 7.97083 21.4386 9.49766C21.5721 9.7594 21.661 10.0211 21.75 10.2829C21.8389 10.501 21.9279 10.5882 22.1503 10.501C23.1288 10.2393 24.1074 9.93389 25.0859 9.71578C25.3083 9.67215 25.3528 9.54129 25.2638 9.36679C24.8635 8.2762 24.3742 7.22923 23.4402 6.444C20.5935 4.0447 15.8788 4.9608 13.9662 8.2762C13.2101 9.58491 12.8543 10.9809 12.6763 12.4641C12.4095 14.9942 12.5874 17.4372 13.6549 19.7928C14.8113 22.2794 16.9908 23.5445 19.8374 23.37C22.4616 23.2391 24.3297 21.7559 25.2193 19.1821C25.3972 18.7895 25.2638 18.7022 25.0414 18.615Z" fill={fillColor || '#CC2835'}/>
			<path d="M57.9112 12.9436C57.7333 11.3731 56.8882 10.2389 55.3314 9.8027C52.8851 9.14834 50.5277 9.41008 48.2593 10.5879C47.9925 10.7188 47.9924 10.8497 48.1704 11.0678C48.5262 11.5913 48.882 12.1148 49.1934 12.6382C49.3713 12.9 49.5047 12.9436 49.7716 12.7691C50.7946 12.2456 51.9066 12.0275 53.063 12.202C53.9081 12.2892 54.3084 12.7691 54.3974 13.5543C54.4419 13.8597 54.3974 14.1651 54.3974 14.4268C54.3974 14.6449 54.3084 14.6885 54.1305 14.6013C53.2854 14.2087 52.3958 14.2087 51.5063 14.2523C49.7716 14.3395 48.3483 14.9503 47.5032 16.5207C46.6581 18.1348 46.7915 19.7925 47.7256 21.363C48.8375 23.2388 52.129 24.1113 54.3529 22.2354C54.4419 23.0207 54.4419 23.0207 55.2425 23.0207C55.9986 23.0207 56.7547 23.0207 57.5554 23.0207C57.9112 23.0207 58.0001 22.8898 58.0001 22.5844C58.0001 20.9703 58.0001 19.3563 58.0001 17.7858C58.0001 16.1717 58.0891 14.5577 57.9112 12.9436ZM52.7517 20.5777C51.2394 20.7522 50.2609 19.618 50.7057 18.0476C50.928 17.2187 51.5063 16.7825 52.3514 16.7825C52.4848 16.7825 52.6182 16.7825 52.7962 16.7825C54.1305 16.8697 54.4419 17.2187 54.4419 18.4838C54.4419 19.7489 53.9081 20.4469 52.7517 20.5777Z" fill={fillColor || '#CC2835'}/>
			<path d="M81.9741 21.1014C81.9741 18.484 82.0186 15.8665 81.9296 13.2491C81.8851 11.4605 80.9066 10.1955 79.1719 9.75922C76.7701 9.14849 74.4572 9.41023 72.2777 10.5881C72.0553 10.7189 71.9664 10.8062 72.1443 11.0679C72.5001 11.5914 72.856 12.1149 73.1673 12.6384C73.3452 12.9001 73.4787 12.9437 73.7455 12.7692C74.7685 12.2021 75.8805 12.0276 77.037 12.1585C77.7931 12.2458 78.2379 12.6384 78.3713 13.2927C78.4603 13.729 78.4603 14.1652 78.3713 14.6887C77.4817 14.2524 76.5477 14.2088 75.5692 14.2088C72.767 14.2524 70.9434 16.0846 70.9879 18.8329C71.0323 20.6215 71.6995 22.0611 73.4342 22.8027C75.1688 23.5879 76.8146 23.457 78.3713 22.1483C78.3713 22.3228 78.4158 22.4101 78.3713 22.4537C78.2824 22.8899 78.5047 22.9772 78.905 22.9772C79.7946 22.9336 80.6397 22.9772 81.5293 22.9772C81.8406 22.9772 81.9296 22.8899 81.9296 22.5846C81.9296 22.1047 81.9741 21.6249 81.9741 21.1014ZM78.4603 18.0041C78.4158 18.1786 78.4603 18.3531 78.4603 18.5276C78.4603 19.9235 77.6596 20.6651 76.2363 20.6215C75.5247 20.5779 75.0799 20.2289 74.813 19.6182C74.5906 19.0511 74.5906 18.4403 74.813 17.8296C75.0799 17.2189 75.5692 16.8699 76.2363 16.8262C76.7701 16.7826 77.3483 16.8699 77.8376 17.088C78.2379 17.2625 78.5492 17.4806 78.4603 18.0041Z" fill={fillColor || '#CC2835'}/>
			<path d="M109.951 14.4702C109.951 13.7722 109.907 13.0742 109.818 12.3762C109.506 10.7185 108.617 9.84606 106.927 9.54069C105.459 9.27895 104.124 9.54069 102.924 10.5877C102.879 9.71519 102.879 9.71519 102.078 9.71519C101.322 9.71519 100.566 9.71519 99.7655 9.71519C99.4542 9.71519 99.3652 9.80243 99.3652 10.1078C99.3652 14.252 99.3652 18.4399 99.3652 22.5842C99.3652 22.9332 99.4987 22.9768 99.81 22.9768C100.655 22.9768 101.5 22.9331 102.39 22.9768C102.835 22.9768 102.924 22.8459 102.924 22.4097C102.924 19.5305 102.924 16.6513 102.924 13.7722C102.924 13.5541 102.879 13.3359 103.101 13.1615C103.546 12.7252 103.991 12.4198 104.569 12.289C105.77 12.0272 106.393 12.5071 106.437 13.6849C106.437 13.9031 106.437 14.1212 106.437 14.3829C106.437 17.0876 106.437 19.7922 106.437 22.4969C106.437 22.8895 106.526 23.0204 106.927 22.9768C107.727 22.9331 108.572 22.9331 109.373 22.9768C109.862 22.9768 109.996 22.8895 109.996 22.366C109.951 19.7922 109.951 17.1312 109.951 14.4702Z" fill={fillColor || '#CC2835'}/>
			<path d="M92.738 9.75879C89.8469 9.75879 86.9557 9.75879 84.0201 9.75879C83.7088 9.75879 83.6198 9.88966 83.6198 10.1514C83.6198 10.7621 83.6643 11.4165 83.6198 12.0272C83.6198 12.3326 83.7088 12.4635 84.0646 12.4198C84.9987 12.4198 85.9327 12.4198 86.8223 12.4198C87.6674 12.4198 88.557 12.4198 89.491 12.4198C89.4021 12.5507 89.3576 12.5943 89.3131 12.638C87.2671 15.0373 85.399 17.5674 83.4419 20.054C83.3085 20.2285 83.1306 20.403 83.1751 20.6647C83.1751 21.3627 83.1751 22.017 83.1751 22.715C83.1751 22.9767 83.2195 23.064 83.4864 23.064C86.5554 23.064 89.6245 23.064 92.738 23.064C93.0048 23.064 93.0938 22.9767 93.0938 22.715C93.0938 22.0607 93.0938 21.4063 93.0938 20.7956C93.0938 20.4902 93.0048 20.403 92.6935 20.403C92.2042 20.403 91.715 20.403 91.2702 20.403C89.8024 20.403 88.3791 20.403 86.9113 20.403C86.9113 20.3593 86.9113 20.3157 86.9113 20.3157C89.0462 17.9164 90.8254 15.299 92.7824 12.8124C92.9159 12.638 93.0493 12.4635 93.0493 12.2017C93.0493 11.5474 93.0493 10.893 93.0493 10.195C93.1383 9.84604 93.0493 9.75879 92.738 9.75879Z" fill={fillColor || '#CC2835'}/>
			<path d="M97.4527 13.9902C96.5631 14.0339 95.6735 13.9902 94.784 13.9902C94.5171 13.9902 94.3837 14.0339 94.3837 14.3392C94.3837 17.0875 94.3837 19.8794 94.3837 22.6277C94.3837 22.8895 94.4726 22.9767 94.7395 22.9767C95.6291 22.9767 96.5631 22.9331 97.4527 22.9767C97.8975 22.9767 97.9419 22.8458 97.9419 22.4532C97.9419 21.1009 97.9419 19.7922 97.9419 18.4399C97.9419 17.0875 97.9419 15.7788 97.9419 14.4265C97.9419 14.0775 97.8085 13.9902 97.4527 13.9902Z" fill={fillColor || '#CC2835'}/>
			<path d="M94.8285 13.1621C95.2733 13.1185 95.7181 13.1621 96.1629 13.1621C96.6077 13.1621 97.0969 13.1621 97.5417 13.1621C97.8531 13.1621 97.942 13.0748 97.942 12.7695C97.942 11.897 97.942 10.9809 97.942 10.1084C97.942 9.80306 97.8086 9.71582 97.5417 9.71582C96.6077 9.71582 95.7181 9.71582 94.784 9.71582C94.4727 9.71582 94.3837 9.80306 94.3837 10.1084C94.3837 10.9809 94.4282 11.8534 94.3837 12.7258C94.3392 13.1185 94.5172 13.1621 94.8285 13.1621Z" fill={fillColor || '#CC2835'}/>
		</svg>

	);
}

PcMagazin.propTypes = {
	fillColor: PropTypes.string
};

export default PcMagazin;