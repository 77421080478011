import React from 'react';
import clsx  from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { PlanTypes } from '../../../config/plan-types';
import osIconsBlack from '../../../assets/images/icons-os-black.png';

function FeaturesBoxV3({selectedPackage, classes, serversDetails, t}) {
	const transCitiesCount = serversDetails.cities_count || 58;

	return (
		<Box>
			<div className={classes.planFeatureBox}>
				<div className={classes.planFeatureTitle}>
					<span>{t((selectedPackage.packageIdentifier === PlanTypes.YEARLY) ? '12 Month Plan Includes:' : (selectedPackage.packageIdentifier === PlanTypes.YEARLY2) ? '24 Month Plan Includes:' : (selectedPackage.packageIdentifier === PlanTypes.MONTHLY) ? '1 Month Plan Includes:' : '')}</span>
				</div>
				<ul className={classes.planFeatureContainer}>
					<li className={classes.planFeature}>
						{t('Unlimited Data')}
					</li>
					<li className={classes.planFeature}>
						<Trans i18nKey="transCitiesCount" transCitiesCount={transCitiesCount}>
							Servers in {{transCitiesCount}} Cities
						</Trans>
					</li>
					<li className={classes.planFeature}>
						{t('10 Connections')}
					</li>
					<li className={classes.planFeature}>
						{t('SOCKS5 Proxy')}
					</li>
					<li className={classes.planFeature}>
						{t('Unlimited Devices')}
					</li>
					<li className={classes.planFeature}>
						{t('Zero-Log')}
					</li>
					<li className={classes.planFeature}>
						{t('Ad Blocker')}
					</li>
					<li className={classes.planFeature}>
						{t('Threat Prevention')}
					</li>
					<li className={classes.planFeature}>
						{t('Parental Controls')}
					</li>
					<li className={clsx(classes.planFeature, classes.planFeatureIcons)}>
						{t('Apps for')}: <img src={osIconsBlack} alt="" width="167" />
					</li>
				</ul>
			</div>
		</Box>
	);
}

const mapStateToProps = (state) => {
	return {
		serversDetails: state.home.serversDetails
	};
};

FeaturesBoxV3.propTypes = {
	selectedPackage: PropTypes.object,
	serversDetails: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default connect(mapStateToProps, null)(withNamespaces()(FeaturesBoxV3));