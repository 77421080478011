import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { PlanTypes } from '../../../config/plan-types';
import { withNamespaces } from 'react-i18next';
import utils from '../../../services/utils';
import {
	Box,
	Paper,
	TextField,
	Typography,
	Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import { removeCouponByPlan } from '../../../store/actions';

function CouponBoxV3({
	t,
	selectedPackage,
	selectedPaymentMethod,
	classes,
	customClassName,
	couponValidPlan,
	couponCode,
	couponByCode,
	couponMonthly,
	couponYearly,
	couponYearly2,
	isCouponCodeFieldOpened,
	couponByCodeIsLoading,
	onChangeCouponCodeField,
	onSubmitCouponCode,
	onOpenCouponCodeField,
	onRemoveCouponByPlan
}) {
	const _couponByPlan = utils.executeCouponByPlan(selectedPackage, PlanTypes, couponYearly, couponYearly2, couponMonthly);
	const _paymentNotEligible = _couponByPlan.code && utils.checkPaymentEligibility(_couponByPlan.payment_methods, selectedPaymentMethod);

	const handleRemoveCouponByPlan = () => {
		onRemoveCouponByPlan(_couponByPlan?.code);
		if(_couponByPlan?.code === couponCode) {
			onChangeCouponCodeField('');
		}
	};

	return selectedPackage.packageIdentifier ? (
		<Paper elevation={0} className={clsx(classes.paymentInfoContainer, (customClassName) ? classes[customClassName] : null)}>
			<Box className={clsx('couponSectionV2', classes.couponSection)}>
				<Box justify="center" style={{display: (_couponByPlan?.code && _paymentNotEligible || isCouponCodeFieldOpened ? 'flex' : 'none')}}>
					<TextField
						variant="outlined"
						placeholder={t('Coupon')}
						name="couponCode"
						disabled={_couponByPlan?.code && _paymentNotEligible}
						value={_couponByPlan?.code && _paymentNotEligible ? _couponByPlan?.code : (couponCode || '')}
						onChange={e => onChangeCouponCodeField(e.target.value)}
					/>
					<Button
						variant="contained"
						color="primary"
						className={classes.couponBtn}
						disabled={!(_couponByPlan?.code && _paymentNotEligible || !!couponCode) || couponByCodeIsLoading}
						onClick={_couponByPlan?.code && _paymentNotEligible ? handleRemoveCouponByPlan : onSubmitCouponCode}
					>{t(_couponByPlan?.code && _paymentNotEligible ? 'Remove' : 'Apply')}</Button>
				</Box>
				<Box className={classes.couponInitBtn} style={{display: (_couponByPlan?.code && _paymentNotEligible || isCouponCodeFieldOpened ? 'none' : 'block')}} onClick={onOpenCouponCodeField}>
					{couponCode ? t('Coupon Applied') : t('Have a Coupon?')}
				</Box>

				{isCouponCodeFieldOpened && (couponByCode.success || couponByCode.error) ?
					<Box className={classes.couponSectionMessagesBox}>
						<Typography component="p"  className={clsx(classes.couponCodeMessage, {[classes.couponCodeSuccess]: couponByCode.success, [classes.couponCodeError]: couponByCode.error})}>
							{couponByCode.message || t(couponByCode.success ? ((selectedPackage.packageIdentifier !== couponValidPlan || selectedPackage.initialSelectedPackageId) && couponValidPlan === PlanTypes.YEARLY ? 'Coupon code applied on 12 Month Plan.' : (selectedPackage.packageIdentifier !== couponValidPlan ? (couponValidPlan === PlanTypes.YEARLY2 ? 'Coupon code applied on 24 Month Plan.' : 'Coupon code applied on 1 Month Plan.') : 'Coupon code applied.')) : 'Invalid coupon code.')}
						</Typography>
					</Box>: null
				}
			</Box>
		</Paper>
	) : null;
}

CouponBoxV3.propTypes = {
	selectedPaymentMethod: PropTypes.string,
	couponCode: PropTypes.string,
	couponByCode: PropTypes.object,
	couponByCodeIsLoading: PropTypes.bool,
	isCouponCodeFieldOpened: PropTypes.bool,
	couponYearly: PropTypes.object,
	couponMonthly: PropTypes.object,
	couponYearly2: PropTypes.object,
	couponValidPlan: PropTypes.string,
	customClassName: PropTypes.string,
	selectedPackage: PropTypes.object,
	classes: PropTypes.object,
	onChangeCouponCodeField: PropTypes.func,
	onSubmitCouponCode: PropTypes.func,
	onOpenCouponCodeField: PropTypes.func,
	onRemoveCouponByPlan: PropTypes.func,
	t: PropTypes.func
};

const mapDispatchToProps = dispatch => {
	return {
		onRemoveCouponByPlan: dataToSend => dispatch(removeCouponByPlan(dataToSend)),
	};
};

export default connect(null, mapDispatchToProps)(withNamespaces()(CouponBoxV3));