import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import homeReducer from './reducers/home';
import { api } from './api';
import { securityCheckSlice } from './reducers/securityCheckSlice';
import { currencySlice } from './reducers/currencySlice';
import { packagesSlice } from './reducers/packagesListSlice';

export const store = configureStore({
	reducer: {
		home: homeReducer,
		[api.reducerPath]: api.reducer,
		[securityCheckSlice.name]: securityCheckSlice.reducer,
		[currencySlice.name]: currencySlice.reducer,
		[packagesSlice.name]: packagesSlice.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);