/* eslint-disable */
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

const { REACT_APP_CREDIT_CARD } = process.env;

const custom = {
	initPayment: (paymentJS, publicToken, formType) => {
		let payment =  Observable.create((observer) => {
			//init paymentjs
			paymentJS.init(publicToken, "number_div", "cvv_div", function(payment) {
				var regularStyle = {
					'display': 'block',
					'width': '100%',
					'height': '40px',
					'padding': '10px',
					'text-transform': 'uppercase',
					'font-size': '16px',
					'font-weight': '400',
					'font-family': 'Spartan, sans-serif',
					'line-height': '30px',
					'color': '#333333',
					'background-color': 'transparent',
					'background-clip': 'padding-box',
					'outline': 'none',
					'border': 'none',
					'border-radius': '0px',
				};
				var focusStyle = {
					
				};
				var blurStyle = {
					
				};

				if(formType === 'twoStep') {
					regularStyle['padding'] = '12px 11px 10px';
					regularStyle['color'] = '#757575';
					regularStyle['font-weight'] = '500';
					regularStyle['border-radius'] = '30px';
					regularStyle['background-color'] = 'rgba(255, 255, 255, 0.56)';
					regularStyle['border'] = '1px solid rgba(162, 169, 210, 0.49)';
					regularStyle['text-transform'] = 'initial';
				} else if(formType === 'v2') {
					regularStyle['padding'] = '15px 15px';
					regularStyle['color'] = '#283339';
					regularStyle['font-weight'] = '500';
					regularStyle['height'] = '50px';
					regularStyle['border-radius'] = '6px';
					regularStyle['background-color'] = 'transparent';
					regularStyle['border'] = 'none';
					regularStyle['text-transform'] = 'initial';
				}

				// Set the initial style
				payment.setNumberStyle(regularStyle);
				payment.setCvvStyle(regularStyle);

				// Focus events
				payment.numberOn('focus', function() {
					payment.setNumberStyle(focusStyle);
					//observer.next({ccNumber: {ccNumberTouched: true}});
				});
				payment.cvvOn('focus', function() {
					payment.setCvvStyle(focusStyle);
					//observer.next({cvv: {cvvTouched: true}});
				});

				// Blur events
				payment.numberOn('blur', function(data) {
					payment.setNumberStyle(blurStyle);
					if(data.cardType === 'unionpay' || ((data.firstSix || '').indexOf('81') === 0)) {
						data.validNumber = false;
					}
					observer.next({ccNumber: {...data, ccNumberBlurred: true}});
				});
				payment.cvvOn('blur', function(data) {
					payment.setCvvStyle(blurStyle);
					if(data.cardType === 'amex' && data.cvvLength === 3) { //ixopay validCvv correction for amex
						data.validCvv = false;
					}
					observer.next({cvv: {...data, cvvBlurred: true}});
				});

				payment.numberOn("input", function(data) {
					data.numberErrorMessage = '';
					if(data.cardType === 'unionpay' || ((data.firstSix || '').indexOf('81') === 0)) {
						data.validNumber = false;
						data.numberErrorMessage = 'Please select "Other" payment method to purchase using UnionPay card';
					}
					if((data.cardType === 'amex' && data.numberLength > 15) || (data.cardType !== 'amex' && data.numberLength > 16)) { //ixopay validCvv correction for extra digit
						return;
					}
					observer.next({ccNumber: {...data, ccNumberTouched: true}});
				});
				payment.cvvOn("input", function(data) {
					if((data.cardType === 'amex' && data.cvvLength > 4) || (data.cardType !== 'amex' && data.cvvLength > 3)) { //ixopay validCvv correction for extra digit
						return;
					}
					if(data.cardType === 'amex' && data.cvvLength === 3) { //ixopay validCvv correction for amex
						data.validCvv = false;
					}
					observer.next({cvv: {...data, cvvTouched: true}});
				});
				payment.setNumberPlaceholder((formType === 'twoStep') ? "Card Number" : (formType === 'v2') ?  "0000 0000 0000 0000" : "1234 1234 1234 1234");
				payment.setCvvPlaceholder((formType === 'twoStep') ? "CVC" : (formType === 'v2') ? "000" : "---");
				observer.next(payment);
			});


		});
		return payment;
	},

	getTransactionToken: (paymentJS, paymentMethod, form, credentials) => {
		let transactionProcessing =  Observable.create((observer) => {
			let pm = "";
			if(paymentMethod){
				pm =  paymentMethod.name?paymentMethod.name:paymentMethod;
			}
			if(pm === REACT_APP_CREDIT_CARD){
				let firstName = form['firstName'];
				let lastName = form['lastName'];
				let ccYear = form['ccYear'];
				let ccDate = form['ccDate'];
				let email = credentials?credentials.user.meta_data.email:form['email'];
				let data = {
					first_name: firstName,
					last_name: lastName,
					//card_holder: firstName + " " + lastName,
					month: String(ccDate),
					year: String(ccYear),
					email: email
				};
				//console.log(data);

				paymentJS.tokenize(
					data, //additional data, MUST include card_holder (or first_name & last_name), month and year
					(token, cardData) => {
						//success callback function
						//console.log('success', cardData);
						observer.next({
							transactionToken: token,
							first_six_chars_of_card: cardData.first_six_digits,
							billingMethod: paymentMethod
						});
					},
					function(errors) {
						//error callback function
						console.log(errors);
						observer.next({errors:errors});
					}
				);
			} else {
				observer.next({
					transactionToken: null,
					billingMethod: pm
				});
			}
		}).pipe(first());
		return transactionProcessing;
	}
};

export default custom;