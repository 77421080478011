import React from 'react';

function SafeAndSecure() {

	return (
		<svg width="96" height="54" viewBox="0 0 96 54" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_950_12281)">
				<path d="M40.3275 14.6543C40.4624 16.3034 41.2345 17.6377 43.0035 17.6377C44.7725 17.6377 45.5071 16.6932 45.5071 14.9017C45.5071 13.2226 44.8025 12.3156 42.5687 11.2437C39.9152 9.97692 38.7683 8.81506 38.7683 6.39391C38.7683 3.97275 40.2825 2.20374 43.056 2.20374C46.3691 2.20374 47.2536 4.54993 47.2986 6.54382H45.2822C45.1998 5.71179 44.9524 3.8828 42.9885 3.8828C41.4668 3.8828 40.8147 4.91723 40.8147 6.21401C40.8147 7.73566 41.4893 8.44777 43.5507 9.42223C46.4741 10.809 47.5909 12.1882 47.5909 14.7293C47.5909 17.2704 46.0843 19.3242 42.9285 19.3242C39.4954 19.3242 38.386 17.0905 38.2661 14.6543H40.3125H40.3275Z" fill="black"/>
				<path d="M57.508 16.7756C57.508 17.5252 57.5679 18.6571 57.6054 19.0844H55.7689C55.709 18.8145 55.649 18.1624 55.649 17.7126C55.1843 18.6871 54.2698 19.3017 52.7256 19.3017C50.327 19.3017 49.45 17.4203 49.45 15.6738C49.45 13.3351 51.0391 11.656 54.2923 11.656C54.847 11.656 55.1918 11.656 55.5441 11.6935V10.0894C55.5441 9.15987 55.3792 7.99802 53.7676 7.99802C52.0585 7.99802 51.9386 9.20485 51.8636 10.0444H49.9297C49.9822 8.24538 50.8367 6.38641 53.835 6.38641C56.3086 6.38641 57.5155 7.57076 57.5155 9.88697V16.7756H57.508ZM55.5815 13.2526C55.2742 13.2001 54.9519 13.1926 54.4047 13.1926C52.2984 13.1926 51.4513 14.1971 51.4513 15.5163C51.4513 16.5882 52.0135 17.6152 53.2953 17.6152C55.0493 17.6152 55.5815 16.3634 55.5815 13.8823V13.2601V13.2526Z" fill="black"/>
				<path d="M60.5663 19.0844V8.2979H58.9697V6.59634H60.5663V4.96974C60.5663 3.08079 61.2859 1.47668 63.5947 1.47668C63.9545 1.47668 64.4042 1.53665 64.6441 1.61161V3.36564C64.4267 3.31316 64.1494 3.26819 63.8495 3.26819C62.8901 3.26819 62.5752 3.86036 62.5752 5.0447V6.59634H64.5017V8.2979H62.5752V19.0844H60.5663Z" fill="black"/>
				<path d="M67.2376 13.2601C67.2376 15.5389 67.6124 17.6077 69.5163 17.6077C71.4202 17.6077 71.6151 15.8762 71.6751 15.4864H73.6315C73.594 15.8237 73.2567 19.3243 69.4938 19.3243C65.7309 19.3243 65.2212 15.9511 65.2212 12.8329C65.2212 8.87506 66.6004 6.40143 69.5838 6.40143C72.9344 6.40143 73.729 9.10743 73.729 12.2557C73.729 12.533 73.7215 12.9828 73.6915 13.2751H67.2301L67.2376 13.2601ZM71.7351 11.716C71.7351 9.75207 71.3378 8.09549 69.5763 8.09549C67.4175 8.09549 67.305 10.8465 67.275 11.716H71.7351Z" fill="black"/>
				<path d="M91.9063 8.99501L88.758 15.329C87.4837 17.8326 86.5018 19.2868 84.0731 19.2868C81.4571 19.2868 80.1603 17.4578 80.1603 14.8118C80.1603 12.638 81.0973 11.2438 83.6458 9.63216C85.8196 8.23043 86.3069 7.34593 86.3069 5.85425C86.3069 4.73737 85.9171 3.84537 84.7252 3.84537C83.7133 3.84537 83.1436 4.6924 83.1436 6.01167C83.1436 7.15853 83.5259 8.02055 84.5229 9.28735L92.4235 19.0769H89.8674L82.9187 10.3817C81.9143 9.06997 81.2172 7.88563 81.2172 6.08662C81.2172 4.28762 82.2666 2.23376 84.7477 2.23376C87.019 2.23376 88.1808 3.7929 88.1808 5.7643C88.1808 8.14048 86.9815 9.27985 84.6053 10.764C82.7164 12.0008 82.1842 13.0727 82.1842 14.6394C82.1842 16.3934 82.9412 17.5103 84.238 17.5103C85.5348 17.5103 86.2694 16.5508 87.4537 14.1072L89.8749 8.98751H91.8988L91.9063 8.99501Z" fill="black"/>
				<path d="M40.3275 35.8226C40.4624 37.4717 41.2345 38.8059 43.0035 38.8059C44.7725 38.8059 45.5071 37.8615 45.5071 36.07C45.5071 34.3909 44.8025 33.4839 42.5687 32.412C39.9152 31.1452 38.7683 29.9833 38.7683 27.5622C38.7683 25.141 40.2825 23.372 43.056 23.372C46.3691 23.372 47.2536 25.7182 47.2986 27.7121H45.2822C45.1998 26.8801 44.9524 25.0511 42.9885 25.0511C41.4668 25.0511 40.8147 26.0855 40.8147 27.3823C40.8147 28.9039 41.4893 29.616 43.5507 30.5905C46.4741 31.9772 47.5909 33.3565 47.5909 35.8976C47.5909 38.4386 46.0843 40.4925 42.9285 40.4925C39.4954 40.4925 38.386 38.2587 38.2661 35.8226H40.3125H40.3275Z" fill="black"/>
				<path d="M51.3839 34.4283C51.3839 36.7071 51.7587 38.7759 53.6627 38.7759C55.5666 38.7759 55.7615 37.0444 55.8215 36.6546H57.7779C57.7404 36.9919 57.4031 40.4925 53.6402 40.4925C49.8773 40.4925 49.3676 37.1193 49.3676 34.0011C49.3676 30.0433 50.7468 27.5696 53.7301 27.5696C57.0808 27.5696 57.8753 30.2756 57.8753 33.4239C57.8753 33.7012 57.8678 34.151 57.8379 34.4433H51.3764L51.3839 34.4283ZM55.8814 32.8842C55.8814 30.9203 55.4842 29.2637 53.7226 29.2637C51.5638 29.2637 51.4514 32.0147 51.4214 32.8842H55.8814Z" fill="black"/>
				<path d="M67.9648 36.3623C67.9123 37.0894 67.6574 40.4775 63.9095 40.4775C60.5814 40.4775 59.7043 37.5991 59.7043 33.9561C59.7043 30.3131 60.8362 27.5472 64.0744 27.5472C67.7924 27.5472 67.9573 30.9353 67.9648 31.5575H65.9184C65.8959 31.0552 65.746 29.2637 64.0669 29.2637C62.3879 29.2637 61.7582 31.1227 61.7582 33.9561C61.7582 36.9769 62.3804 38.7684 63.992 38.7684C65.7085 38.7684 65.9034 37.0369 65.9934 36.3698H67.9723L67.9648 36.3623Z" fill="black"/>
				<path d="M77.9941 36.7895C77.9941 38.4086 77.9941 39.6454 78.0166 40.2601H76.0977C76.0377 39.8928 76.0152 39.3831 76.0002 38.656C75.5355 39.8778 74.5161 40.485 73.0694 40.485C71.6227 40.485 69.9436 39.8328 69.9436 36.752V27.772H71.9675V36.2798C71.9675 37.809 72.3723 38.7235 73.729 38.7235C75.0858 38.7235 75.9553 37.9289 75.9553 35.3653V27.772H77.9791V36.797L77.9941 36.7895Z" fill="black"/>
				<path d="M80.6927 32.0147C80.6927 30.0133 80.6927 28.6565 80.6702 27.7645H82.6041C82.6266 28.0644 82.6641 28.6341 82.6641 29.9608C83.1813 28.4691 84.3656 27.5846 85.9622 27.5696V29.8034C83.8334 29.8484 82.709 31.0552 82.709 33.3639V40.2601H80.7002V32.0222L80.6927 32.0147Z" fill="black"/>
				<path d="M88.923 34.4283C88.923 36.7071 89.2978 38.7759 91.2017 38.7759C93.1057 38.7759 93.3006 37.0444 93.3605 36.6546H95.3169C95.2795 36.9919 94.9422 40.4925 91.1792 40.4925C87.4163 40.4925 86.9066 37.1193 86.9066 34.0011C86.9066 30.0433 88.2859 27.5696 91.2692 27.5696C94.6198 27.5696 95.4144 30.2756 95.4144 33.4239C95.4144 33.7012 95.4069 34.151 95.3769 34.4433H88.9155L88.923 34.4283ZM93.4205 32.8842C93.4205 30.9203 93.0232 29.2637 91.2617 29.2637C89.1029 29.2637 88.9905 32.0147 88.9605 32.8842H93.4205Z" fill="black"/>
				<path d="M4.86484 20.2837V12.5705C4.86484 7.53329 8.94258 3.45555 13.9798 3.45555C19.017 3.45555 23.0947 7.53329 23.0947 12.5705V20.2837H25.8982V12.5705C25.8982 5.99664 20.5611 0.659607 13.9798 0.659607C7.39844 0.659607 2.0614 5.99664 2.0614 12.578V20.2912H4.86484V20.2837Z" fill="#959CB5"/>
				<path d="M0.659546 21.693V35.3654C0.659546 36.9995 1.59653 38.4986 3.0807 39.1808C6.01907 40.5225 9.81946 41.3246 13.9796 41.3246C18.1398 41.3246 21.9402 40.515 24.8786 39.1808C26.3628 38.5061 27.2997 36.9995 27.2997 35.3654V21.693C27.2997 20.1413 26.0479 18.8895 24.4963 18.8895H3.46299C1.91135 18.8895 0.659546 20.1413 0.659546 21.693Z" fill="#FFDC64"/>
				<path d="M16.7832 27.997C16.7832 26.228 15.1491 24.8412 13.3051 25.2685C12.3081 25.5009 11.4986 26.3029 11.2587 27.2924C10.9664 28.4917 11.4461 29.5861 12.2931 30.2232C12.4581 30.3507 12.5705 30.5306 12.5705 30.7404V32.8243C12.5705 33.5214 13.0502 34.1735 13.7398 34.286C14.6169 34.4284 15.3739 33.7538 15.3739 32.9067V30.733C15.3739 30.5306 15.4789 30.3582 15.6438 30.2382C16.3334 29.7285 16.7757 28.919 16.7757 27.997H16.7832Z" fill="#464655"/>
				<path d="M26.5503 18.912V12.578C26.5503 5.64437 20.9134 0 13.9797 0C7.04609 0 1.40172 5.64437 1.40172 12.578V18.912C0.554692 19.5416 0 20.5536 0 21.693V35.3654C0 37.2693 1.10189 39.0008 2.80344 39.7729C5.92171 41.1971 9.8945 41.9767 13.9722 41.9767C18.05 41.9767 22.0228 41.1896 25.141 39.7729C26.8426 38.9933 27.9445 37.2618 27.9445 35.3654V21.693C27.9445 20.5536 27.3898 19.5416 26.5428 18.912H26.5503ZM2.71349 12.578C2.71349 6.36397 7.76569 1.31177 13.9797 1.31177C20.1938 1.31177 25.2385 6.36397 25.2385 12.5705V18.3048C24.9986 18.2524 24.7513 18.2224 24.4964 18.2224H23.7543V12.5705C23.7543 7.18101 19.3692 2.79595 13.9797 2.79595C8.59023 2.79595 4.20516 7.18851 4.20516 12.578V18.2299H3.46308C3.20822 18.2299 2.96086 18.2599 2.72099 18.3123V12.578H2.71349ZM5.51694 18.2299V12.578C5.51694 7.9156 9.30983 4.12271 13.9722 4.12271C18.6346 4.12271 22.4275 7.9156 22.4275 12.578V18.2299H5.52443H5.51694ZM26.6402 35.3654C26.6402 36.7521 25.8381 38.0189 24.6013 38.5811C21.648 39.9303 17.8776 40.6649 13.9722 40.6649C10.0669 40.6649 6.2965 39.9228 3.34314 38.5811C2.10633 38.0189 1.30428 36.7521 1.30428 35.3654V21.693C1.30428 20.5086 2.26374 19.5491 3.44808 19.5491H24.4814C25.6657 19.5491 26.6252 20.5086 26.6252 21.693V35.3654H26.6402Z" fill="black"/>
				<path d="M13.9798 24.5339C12.0684 24.5339 10.5167 26.0855 10.5167 27.997C10.5167 29.0838 11.0489 30.1183 11.9184 30.7629V32.8992C11.9184 34.0311 12.8404 34.9606 13.9798 34.9606C15.1192 34.9606 16.0412 34.0386 16.0412 32.8992V30.7629C16.9182 30.1108 17.4429 29.0838 17.4429 27.997C17.4429 26.0855 15.8912 24.5339 13.9798 24.5339ZM15.0517 29.8409C14.8493 29.9609 14.7219 30.1782 14.7219 30.4106V32.8992C14.7219 33.3115 14.3846 33.6413 13.9798 33.6413C13.575 33.6413 13.2377 33.304 13.2377 32.8992V30.4106C13.2377 30.1782 13.1103 29.9609 12.9079 29.8409C12.2333 29.4511 11.836 28.7615 11.836 27.997C11.836 26.8126 12.7955 25.8531 13.9798 25.8531C15.1641 25.8531 16.1236 26.8126 16.1236 27.997C16.1236 28.7615 15.7188 29.4511 15.0517 29.8409Z" fill="black"/>
				<path d="M0.966963 53.8875V47.7709C0.75708 47.8834 0.247363 48.0033 0 48.0183V47.3137C0.502221 47.2087 1.03443 46.9239 1.2593 46.6915H1.85897V53.88H0.966963V53.8875Z" fill="#464655"/>
				<path d="M6.91873 50.237C6.91873 52.8831 6.39402 54 4.9923 54C3.59058 54 3.07336 52.7781 3.07336 50.237C3.07336 47.4861 3.80796 46.6465 5.02978 46.6465C6.33405 46.6465 6.92622 47.621 6.92622 50.237H6.91873ZM3.97286 50.2071C3.97286 52.2085 4.16026 53.2354 4.99979 53.2354C5.83933 53.2354 6.01923 52.2534 6.01923 50.2221C6.01923 48.1907 5.79435 47.4036 5.01479 47.4036C4.23522 47.4036 3.98036 48.1307 3.98036 50.2071H3.97286Z" fill="#464655"/>
				<path d="M11.6485 50.237C11.6485 52.8831 11.1238 54 9.72204 54C8.32031 54 7.8031 52.7781 7.8031 50.237C7.8031 47.4861 8.53769 46.6465 9.75951 46.6465C11.0638 46.6465 11.656 47.621 11.656 50.237H11.6485ZM8.7026 50.2071C8.7026 52.2085 8.89 53.2354 9.72953 53.2354C10.5691 53.2354 10.749 52.2534 10.749 50.2221C10.749 48.1907 10.5241 47.4036 9.74452 47.4036C8.96496 47.4036 8.7101 48.1307 8.7101 50.2071H8.7026Z" fill="#464655"/>
				<path d="M15.284 48.618C15.284 49.9073 14.8567 50.6793 13.8973 50.6868C12.9603 50.6868 12.5255 49.9672 12.5255 48.6405C12.5255 47.3137 13.0652 46.6316 13.9122 46.6316C14.7593 46.6316 15.284 47.2238 15.284 48.6105V48.618ZM13.2376 48.6405C13.2376 49.6449 13.455 50.0572 13.9122 50.0572C14.3695 50.0572 14.5794 49.6299 14.5794 48.6255C14.5794 47.7035 14.3995 47.2537 13.9047 47.2537C13.4625 47.2537 13.2376 47.636 13.2376 48.633V48.6405ZM13.53 53.8876L17.4278 46.7215H18.0799L14.1821 53.8876H13.53ZM19.1069 51.9237C19.1069 53.2129 18.6871 53.985 17.7201 53.9925C16.7831 53.9925 16.3484 53.2954 16.3484 51.9461C16.3484 50.5969 16.8881 49.9373 17.7351 49.9373C18.5821 49.9373 19.1069 50.5444 19.1069 51.9162V51.9237ZM17.0605 51.9461C17.0605 52.9431 17.2779 53.3703 17.7351 53.3703C18.1924 53.3703 18.4022 52.9431 18.4022 51.9386C18.4022 51.0316 18.2223 50.5669 17.7276 50.5669C17.2854 50.5669 17.0605 50.9867 17.0605 51.9461Z" fill="#464655"/>
				<path d="M23.1546 50.5444V53.8875H22.2551V46.5191H24.2415C25.5008 46.5191 26.168 47.2237 26.168 48.4455C26.168 49.5399 25.6208 50.0047 25.186 50.1621C25.8456 50.4094 26.0705 50.9866 26.0705 52.036V52.3284C26.0705 53.0405 26.093 53.4977 26.1754 53.895H25.2834C25.1785 53.5577 25.171 52.9131 25.171 52.3134V52.0585C25.171 51.0241 24.9536 50.5519 23.9867 50.5519H23.1546V50.5444ZM23.1546 49.7873H24.0316C24.9087 49.7873 25.246 49.315 25.246 48.4755C25.246 47.7559 24.9087 47.2837 24.1216 47.2837H23.1546V49.7873Z" fill="#464655"/>
				<path d="M28.2218 46.5191V53.8875H27.3148V46.5191H28.2218Z" fill="#464655"/>
				<path d="M30.1033 51.9311C30.1633 52.6582 30.5006 53.2504 31.2877 53.2504C32.0747 53.2504 32.397 52.8306 32.397 52.0361C32.397 51.294 32.0822 50.8892 31.1003 50.417C29.9234 49.8548 29.4212 49.3451 29.4212 48.2657C29.4212 47.1863 30.0883 46.4142 31.3176 46.4142C32.7868 46.4142 33.1766 47.4561 33.1991 48.3331H32.3071C32.2696 47.9658 32.1572 47.1563 31.2951 47.1563C30.6205 47.1563 30.3357 47.6135 30.3357 48.1907C30.3357 48.8653 30.6355 49.1802 31.55 49.6149C32.8468 50.2296 33.3415 50.8367 33.3415 51.9611C33.3415 53.0855 32.6744 53.9925 31.2802 53.9925C29.7585 53.9925 29.2713 53.003 29.2113 51.9236H30.1183L30.1033 51.9311Z" fill="#464655"/>
				<path d="M34.3385 46.5191H35.238V50.0946C35.7402 49.36 36.8946 47.576 37.6217 46.5191H38.6636L36.5947 49.48L38.7236 53.8875H37.7041L35.9801 50.237L35.2455 51.234V53.8875H34.346V46.5191H34.3385Z" fill="#464655"/>
				<path d="M41.3171 46.5191H44.7877V47.3212H42.2166V49.6748H44.6228V50.4694H42.2166V53.8875H41.3171V46.5191Z" fill="#464655"/>
				<path d="M46.5791 50.5444V53.8875H45.6796V46.5191H47.666C48.9253 46.5191 49.5924 47.2237 49.5924 48.4455C49.5924 49.5399 49.0452 50.0047 48.6104 50.1621C49.2701 50.4094 49.4949 50.9866 49.4949 52.036V52.3284C49.4949 53.0405 49.5174 53.4977 49.5999 53.895H48.7079C48.6029 53.5577 48.5954 52.9131 48.5954 52.3134V52.0585C48.5954 51.0241 48.3781 50.5519 47.4111 50.5519H46.5791V50.5444ZM46.5791 49.7873H47.4561C48.3331 49.7873 48.6704 49.315 48.6704 48.4755C48.6704 47.7559 48.3331 47.2837 47.546 47.2837H46.5791V49.7873Z" fill="#464655"/>
				<path d="M54.075 50.4019H51.6313V53.093H54.3448L54.2249 53.8875H50.7318V46.5191H54.1874V47.3212H51.6238V49.5999H54.0675V50.4019H54.075Z" fill="#464655"/>
				<path d="M58.5574 50.4019H56.1137V53.093H58.8272L58.7073 53.8875H55.2142V46.5191H58.6698V47.3212H56.1062V49.5999H58.5499V50.4019H58.5574Z" fill="#464655"/>
				<path d="M61.7957 46.5191H63.7596C64.9289 46.5191 65.746 47.2462 65.746 48.5955C65.746 49.9447 65.0039 50.7393 63.6396 50.7393H62.7026V53.8875H61.7957V46.5191ZM62.7026 49.9822H63.5422C64.3967 49.9822 64.824 49.5324 64.824 48.588C64.824 47.7859 64.4042 47.2762 63.6321 47.2762H62.7026V49.9822Z" fill="#464655"/>
				<path d="M67.0503 51.6463L66.5481 53.895H65.6411L67.4101 46.5266H68.587L70.401 53.895H69.4415L68.9318 51.6463H67.0578H67.0503ZM68.7444 50.8442C68.3471 49.1127 68.0773 47.9808 67.9798 47.3736H67.9498C67.8524 48.0258 67.56 49.3076 67.2152 50.8442H68.7369H68.7444Z" fill="#464655"/>
				<path d="M71.6902 53.8875V51.1141C71.6902 51.0391 71.6753 50.9716 71.6453 50.9192L69.7788 46.5191H70.7982C71.2855 47.7484 71.9526 49.42 72.1775 50.1171C72.4398 49.3675 73.122 47.6885 73.5717 46.5191H74.5312L72.6422 50.9267C72.6197 50.9716 72.6047 51.0391 72.6047 51.129V53.895H71.6977L71.6902 53.8875Z" fill="#464655"/>
				<path d="M79.5533 50.8292C79.5533 49.48 79.5758 48.0857 79.5983 47.2987H79.5758C79.3059 48.5955 78.6913 51.5563 78.1216 53.895H77.267C76.8398 51.8337 76.1951 48.6554 75.9328 47.2837H75.9028C75.9478 48.1382 75.9778 49.7498 75.9778 51.0166V53.895H75.1307V46.5266H76.48C77.0347 48.7904 77.5669 51.4514 77.7393 52.4408H77.7468C77.8892 51.6088 78.5563 48.7004 79.1035 46.5266H80.4378V53.895H79.5533V50.8292Z" fill="#464655"/>
				<path d="M85.1902 50.4019H82.7465V53.093H85.46L85.3401 53.8875H81.847V46.5191H85.3026V47.3212H82.739V49.5999H85.1827V50.4019H85.1902Z" fill="#464655"/>
				<path d="M86.337 53.8875V46.5191H87.4314C89.3728 51.4289 89.6577 52.1335 89.7551 52.4633H89.7626C89.7102 51.4963 89.6952 50.387 89.6952 49.1876V46.5191H90.5347V53.8875H89.4928C87.4764 48.7229 87.214 48.0258 87.1316 47.7259H87.1241C87.1766 48.6479 87.1841 49.8323 87.1841 51.1515V53.8875H86.3445H86.337Z" fill="#464655"/>
				<path d="M92.8884 47.3212H91.2618V46.5191H95.4295V47.3212H93.8029V53.8875H92.8959V47.3212H92.8884Z" fill="#464655"/>
			</g>
			<defs>
				<clipPath id="clip0_950_12281">
					<rect width="96" height="54" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default SafeAndSecure;