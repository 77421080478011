import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { changeCurrency, getPaymentMethods } from '../../store/actions';
import {
	Typography,
	Container,
	Box,
	Grid
} from '@material-ui/core';
import { CURRENCY } from '../../config/main';
import PropTypes from 'prop-types';
import clsx  from 'clsx';
import { withNamespaces } from 'react-i18next';
import { useStyles } from './FooterStyles';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const { REACT_APP_WEB } = process.env;

function Footer({
	isEmpty, 
	V2, 
	V3, 
	t, 
	availableCurrencies, 
	securityDetails, 
	queryParams, 
	onChangeCurrency, 
	onGetPaymentMethods
}) {
	const {current_currency = 'USD'} = securityDetails;
	const [currencyOpened, handleOpenCurrency] = useState(false);
	const classes = useStyles();
	const currencyOption = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (currencyOption.current && !currencyOption.current.contains(event.target)) {
				handleOpenCurrency(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [currencyOption]);

	const handleOpenCurrencyOption = () => {
		if(!queryParams.access_token) {
			handleOpenCurrency(true);
		}
	};

	const handleChangeCurrencyOption = (e, current_currency) => {
		e.stopPropagation();
		if(securityDetails.current_currency !== current_currency) {
			onGetPaymentMethods({token: queryParams.token, show_sdd_method: queryParams.show_sdd_method, currency: current_currency, selected_country: securityDetails.iso_code, show_all_methods_with_no_limitations: (location.pathname.indexOf('bfa9b7aa5dfd833775f3cf093846b04d') > -1)});
		}
		cookies.set('currencyCode', current_currency, { path: '/', domain: (window.location.hostname || '').split('signup.')[1], secure: true });
		onChangeCurrency(current_currency);
		handleOpenCurrency(false);
	};

	return (
		<Box className={clsx(classes.footerWrapper, {[classes.V2]: V2, [classes.V3]: V3})}>
			<Container maxWidth={V3 ? 'xl' : 'md'} component="div">
				<Grid container>
					<Grid item xs={12} className={classes.footerContainer}>
						<Box display="flex" justifyContent="space-between" alignItems="center" className={classes.footer}>
							<Typography className={classes.footerCopy}>
								&copy;
								{' '}
								{new Date().getFullYear()}
								{' '}
								PrivadoVPN {t('All rights reserved')}
							</Typography>
							{isEmpty ? null : 
								<div className={classes.footerNavContainer}>
									{V2 || V3 ? null :
										<nav className={classes.footerNav}>
											<a color="textPrimary" href="https://blog.privadovpn.com/" rel="noopener noreferrer" className={classes.footerLink}>
												{t('Blog')}
											</a>
											<a color="textPrimary" href={REACT_APP_WEB + '/privacy-policy/'} rel="noopener noreferrer" className={classes.footerLink}>
												{t('Privacy Policy')}
											</a>
											<a color="textPrimary" href={REACT_APP_WEB + '/terms-of-service/'} rel="noopener noreferrer" className={classes.footerLink}>
												{t('Terms of Service')}
											</a>
											<a color="textPrimary" href={REACT_APP_WEB + '/imprint/'} rel="noopener noreferrer" className={classes.footerLink}>
												{t('Imprint')}
											</a>
											<a color="textPrimary" href={REACT_APP_WEB + '/contact/'} rel="noopener noreferrer" className={classes.footerLink}>
												{t('Contact Us')}
											</a>
										</nav>
									}
									<div className={classes.footerCurrencyContainer}>
										{V2 || V3 ? <span>{t('Currency: ')}&nbsp;</span> : null}
										{currencyOpened ?
											<div className={classes.footerCurrencyOption}  ref={currencyOption}>
												{availableCurrencies.map((c, idx) =>  {
													return (<div key={idx} className={clsx(classes.footerCurrencyOptionItem, {[classes.footerCurrencyOptionItemSelected] : current_currency ===  c})} onClick={e => handleChangeCurrencyOption(e, c)}>{(CURRENCY[c] || '') + ' ' + c}</div>);
												})}
											</div>:
											<div className={clsx(classes.footerCurrency, {[classes.footerCurrencyDisabled]: queryParams.access_token})} onClick={handleOpenCurrencyOption}>{(CURRENCY[current_currency] || '') + ' ' + current_currency}</div>
										}
									</div>
								</div>
							}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		onChangeCurrency: (current_currency) => dispatch(changeCurrency(current_currency)),
		onGetPaymentMethods: (dataToSend) => dispatch(getPaymentMethods(dataToSend))
	};
};

const mapStateToProps = state => {
	return {
		securityDetails: state.securityCheck.securityDetails,
		availableCurrencies: state.currency.availableCurrencies,
		queryParams: state.home.queryParams
	};
};

Footer.propTypes = {
	isEmpty: PropTypes.bool,
	V2: PropTypes.bool,
	V3: PropTypes.bool,
	availableCurrencies: PropTypes.array,
	securityDetails: PropTypes.object,
	queryParams: PropTypes.object,
	onChangeCurrency: PropTypes.func,
	onGetPaymentMethods: PropTypes.func,
	t: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Footer));