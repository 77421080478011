import pwUnionpay from '../../../assets/images/pw/pwUnionpay.png';
import pwQiwi from '../../../assets/images/pw/pwQiwi.png';
import pwAlipay from '../../../assets/images/pw/pwAlipay.png';
import pwEpinpaymentsystem from '../../../assets/images/pw/pwEpinpaymentsystem.png';
import pwSofortbanktransfer from '../../../assets/images/pw/pwSofortbanktransfer.png';
import pwGiropay from '../../../assets/images/pw/pwGiropay.png';
import pwEpspayments from '../../../assets/images/pw/pwEpspayments.png';
import pwBoletobancario from '../../../assets/images/pw/pwBoletobancario.png';
import pwOvo from '../../../assets/images/pw/pwOvo.png';
import pwDanawallet from '../../../assets/images/pw/pwDanawallet.png';
import pwGcashph from '../../../assets/images/pw/pwGcashph.png';
import pwLinkaja from '../../../assets/images/pw/pwLinkaja.png';
import pwBoostwallet from '../../../assets/images/pw/pwBoostwallet.png';
import pwTouchngomalay from '../../../assets/images/pw/pwTouchngomalay.png';
import pwSingteldash from '../../../assets/images/pw/pwSingteldash.png';
import pwAkulakuid from '../../../assets/images/pw/pwAkulakuid.png';
import pwMcash from '../../../assets/images/pw/pwMcash.png';
import pwSakuku from '../../../assets/images/pw/pwSakuku.png';
import pwCherrycredits from '../../../assets/images/pw/pwCherrycredits.png';
import pwKakaopaykr from '../../../assets/images/pw/pwKakaopaykr.png';
import pwDokuwallet from '../../../assets/images/pw/pwDokuwallet.png';
import pwGrabpayph from '../../../assets/images/pw/pwGrabpayph.png';
import pwGrabpaymalay from '../../../assets/images/pw/pwGrabpaymalay.png';
import pwGopay from '../../../assets/images/pw/pwGopay.png';
import pwGocpay from '../../../assets/images/pw/pwGocpay.png';
import pwPaymayaph from '../../../assets/images/pw/pwPaymayaph.png';
import pwMercadopago from '../../../assets/images/pw/pwMercadopago.png';
import pwMycardwallet from '../../../assets/images/pw/pwMycardwallet.png';
import pwPaycokr from '../../../assets/images/pw/pwPaycokr.png';
import pwRedcompra from '../../../assets/images/pw/pwRedcompra.png';
import pwWechatpayments from '../../../assets/images/pw/pwWechatpayments.png';
import pwQris from '../../../assets/images/pw/pwQris.png';
import pwShopeepayph from '../../../assets/images/pw/pwShopeepayph.png';
import pwTest from '../../../assets/images/pw/pwTest.png';
import pwTouchNGo from '../../../assets/images/pw/pwTouchNGo.png';

export const paymentWallIconStyles = () => {
	const size108x44 = {width: 108,height: 44};
	const size107x43 = {width: 107,height: 43};

	const size54x22 = {width: 54,height: 22};
	const size53x21 = {width: 53,height: 21};

	return {
		paymentWallIcon: { 
			...size107x43,
			maxWidth: '100%',
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
		'pw-sofortbanktransfer': { backgroundImage: `url(${pwSofortbanktransfer})` },
		'pw-giropay': { backgroundImage: `url(${pwGiropay})` },
		'pw-ovoid': { backgroundImage: `url(${pwOvo})` },
		'pw-danawallet': { backgroundImage: `url(${pwDanawallet})` },
		'pw-gcashph': { backgroundImage: `url(${pwGcashph})` },
		'pw-linkaja': { backgroundImage: `url(${pwLinkaja})` },
		'pw-boostwallet': { backgroundImage: `url(${pwBoostwallet})` },
		'pw-touchngomalay': { backgroundImage: `url(${pwTouchngomalay})` },
		'pw-singteldash': { backgroundImage: `url(${pwSingteldash})` },
		'pw-akulakuid': { backgroundImage: `url(${pwAkulakuid})` },
		'pw-mcash': { backgroundImage: `url(${pwMcash})` },
		'pw-sakuku': { backgroundImage: `url(${pwSakuku})` },
		'pw-cherrycredits': { backgroundImage: `url(${pwCherrycredits})` },
		'pw-kakaopaykr': { backgroundImage: `url(${pwKakaopaykr})` },
		'pw-dokuwallet': { backgroundImage: `url(${pwDokuwallet})` },
		'pw-grabpayph': { backgroundImage: `url(${pwGrabpayph})` },
		'pw-grabpaymalay': { backgroundImage: `url(${pwGrabpaymalay})` },
		'pw-gopay': { backgroundImage: `url(${pwGopay})` },
		'pw-gocpay': { backgroundImage: `url(${pwGocpay})` },
		'pw-paymayaph': { backgroundImage: `url(${pwPaymayaph})` },
		'pw-mercadopago': { backgroundImage: `url(${pwMercadopago})` },
		'pw-mycardwallet': { backgroundImage: `url(${pwMycardwallet})` },
		'pw-paycokr': { backgroundImage: `url(${pwPaycokr})` },
		'pw-redcompra': { backgroundImage: `url(${pwRedcompra})` },
		'pw-wechatpayments': { backgroundImage: `url(${pwWechatpayments})` },
		'pw-qrisid': { backgroundImage: `url(${pwQris})` },
		'pw-shopeepayph': { backgroundImage: `url(${pwShopeepayph})` },
		'pw-alipay': { ...size108x44, backgroundImage: `url(${pwAlipay})` },
		'pw-qiwiwallet': { ...size108x44, backgroundImage: `url(${pwQiwi})` },
		'pw-epinpaymentsystem': { ...size108x44, backgroundImage: `url(${pwEpinpaymentsystem})` },
		'pw-epspayments': { ...size108x44, backgroundImage: `url(${pwEpspayments})` },
		'pw': { ...size108x44, backgroundImage: `url(${pwTest})` },
		'pw-unionpay': {
			width: 66,
			height: 42,
			backgroundImage: `url(${pwUnionpay})`,
		},
		'pw-boletobr': {
			width: 72,
			height: 40,
			backgroundImage: `url(${pwBoletobancario})`,
		},
		'pw-tngewallet': {
			width: 35,
			height: 35,
			backgroundImage: `url(${pwTouchNGo})`,
		},
		paymentIcon: {

		},
		paymentIconPaywall: {
			'& $paymentIcon': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexWrap: 'wrap',
				'& span.pw-icon': {
					display: 'block',
					maxWidth: '100%',
					backgroundSize: '100%',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					'&$pw-qiwiwallet': size54x22,
					'&$pw-alipay': size54x22,
					'&$pw-epinpaymentsystem': size54x22,
					'&$pw-sofortbanktransfer': size53x21,
					'&$pw-giropay': size53x21,
					'&$pw-epspayments': size54x22,
					'&$pw-ovoid': size53x21,
					'&$pw-danawallet': size53x21,
					'&$pw-gcashph': size53x21,
					'&$pw-linkaja': size53x21,
					'&$pw-boostwallet': size53x21,
					'&$pw-touchngomalay': size53x21,
					'&$pw-singteldash': size53x21,
					'&$pw-akulakuid': size53x21,
					'&$pw-mcash': size53x21,
					'&$pw-sakuku': size53x21,
					'&$pw-cherrycredits': size53x21,
					'&$pw-kakaopaykr': size53x21,
					'&$pw-dokuwallet': size53x21,
					'&$pw-grabpayph': size53x21,
					'&$pw-grabpaymalay': size53x21,
					'&$pw-gopay': size53x21,
					'&$pw-gocpay': size53x21,
					'&$pw-paymayaph': size53x21,
					'&$pw-mercadopago': size53x21,
					'&$pw-mycardwallet': size53x21,
					'&$pw-paycokr': size53x21,
					'&$pw-redcompra': size53x21,
					'&$pw-wechatpayments': size53x21,
					'&$pw-qrisid': size53x21,
					'&$pw-shopeepayph': size53x21,
					'&$pw': size53x21,
					'&$pw-unionpay': {
						width: 33,
						height: 21,
					},
					'&$pw-boletobr': {
						width: 36,
						height: 20,
					},
					'&$pw-tngewallet': {
						width: 35,
						height: 35
					},
				}
			},
		},
	};
};