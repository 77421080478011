import React from 'react';
import PropTypes from 'prop-types';
import clsx  from 'clsx';
import './LoaderSmall.css';

const LoaderSmall = ({xs, color}) => (
	<div className={clsx('circularG-container', (xs ? 'xs' : ''), (color || ''))}>
		<div className="circularG circularG_1"></div>
		<div className="circularG circularG_2"></div>
		<div className="circularG circularG_3"></div>
		<div className="circularG circularG_4"></div>
		<div className="circularG circularG_5"></div>
		<div className="circularG circularG_6"></div>
		<div className="circularG circularG_7"></div>
		<div className="circularG circularG_8"></div>
	</div>
);

LoaderSmall.propTypes = {
	xs: PropTypes.bool,
	color: PropTypes.string
};

export default LoaderSmall;