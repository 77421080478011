import { makeStyles } from '@material-ui/core/styles';
import iconBtnArrow from '../../assets/images/arrow-white.png';
import iconCheckMark from '../../assets/images/icon-checkmark-blue.png';
import iconCheckMBG from '../../assets/images/icon-mbg-check-66x66.png';
import abandonmentBg from '../../assets/images/abandonmentBg_2.png';

export const useStyles = makeStyles(() => ({
	abModal: {
		position: 'fixed',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		zIndex: 10000,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'auto',
		background: 'rgba(10, 12, 29, 0.75)',
	},
	abModalWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		maxWidth: 940,
		width: 940,
		margin: 10,
		padding: '40px 80px',
		boxShadow: '0 0 29px 0 rgba(35, 10, 81, 0.52)',
		borderRadius: 20,
		backgroundColor: '#ffffff',
		color: '#283339',
		backgroundImage: `url(${abandonmentBg})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top right',
	},
	abModalCloseBtn: {
		position: 'absolute',
		top: 12,
		right: 12,
		color: '#D0D0D0',
		fontSize: 25,
		fontWeight: 700,
		cursor: 'pointer',
	},
	abModalBody: {
		minHeight: 370,
	},
	abModalImg: {
		maxWidth: '348px',
		width: '100%',
	},
	abModalContent: {
		color: '#283339',
		maxWidth: 390,
	},
	abModalDesc: {
		fontSize: 15,
		fontWeight: 500,
		lineHeight: '24px',
		marginBottom: 10,
	},
	abModalTitle: {
		fontSize: 36,
		fontWeight: 700,
		lineHeight: '48px',
		marginBottom: 10,
	},
	abModalFeatures: {
		marginBottom: 20,
	},
	abModalFeatureItem: {
		position: 'relative',
		fontSize: 15,
		fontWeight: 500,
		lineHeight: '24px',
		paddingLeft: 32,
		paddingTop: 4,
		paddingBottom: 4,
		'&:before': {
			content: '""',
			display: 'block',
			width: 19,
			height: 16,
			position: 'absolute',
			top: 8,
			left: 7,
			background: `url(${iconCheckMark})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
		}
	},
	abModalFeatureItemApps: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		'& img': {
			marginLeft: 15,
		},
	},
	abModalBtn: {
		cursor: 'pointer',
		fontSize: 18,
		fontWeight: 700,
		color: '#ffffff',
		background: '#ff8f00',
		borderRadius: 34,
		padding: '15px 20px 13px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: 290,
	},
	abModalBtnArrow: {
		width: 14,
		minWidth: 14,
		height: 16,
		marginLeft: 10,
		marginTop: '-2px',
		background: `url(${iconBtnArrow})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	abModalMoneyBack: {
		position: 'relative',
		fontSize: 15,
		fontWeight: 700,
		paddingLeft: 40,
		paddingTop: 10,
		paddingBottom: 10,
		marginBottom: 10,
		color: '#283339',
		'&:before': {
			content: '""',
			display: 'block',
			width: 33,
			height: 33,
			position: 'absolute',
			top: 2,
			left: 0,
			background: `url(${iconCheckMBG})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
		}
	},
	'@media (max-width: 900px)': {
		abModalWrapper: {
			padding: '40px 30px',
		},
	},
	'@media (max-width: 800px)': {
		abModalImg: {
			display: 'none',
		},
		abModalContent: {
			maxWidth: '100%',
		},
	},
	'@media (max-width: 440px)': {
		abModalTitle: {
			fontSize: 25,
			lineHeight: '40px',
		},
		abModalBtn: {
			fontSize: 14,
			padding: '15px 20px 13px',
		},
	},
}));