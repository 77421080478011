import React from 'react';

function CheckGreen() {

	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4 12.7511L9.479 18.4444L20 7.54519L18.49 6L9.48 15.3333L5.49 11.1956L4 12.7511Z" fill="#12AF5A"/>
		</svg>
	);
}

export default CheckGreen;