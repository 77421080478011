import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {error: null, errorInfo: null};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		Sentry.withScope(scope => {
			scope.setTag('react', true);
			if(error?.code) {
				scope.setFingerprint([error?.code]);
			}
			scope.setExtras({
				'error code:': error?.code,
				'error message:': error?.message,
				'errorInfo': errorInfo,
			});
			Sentry.captureException(error);
		});

		if(errorInfo && process.env.REACT_APP_ENVIRONMENT !== 'Local' && window.ga) {
			Sentry.withScope(scope => {
				scope.setTag('react', true);
				scope.setExtras({
					'error message:': error?.message,
					'errorInfo': errorInfo,
				});
				scope.setFingerprint('js_error');
				Sentry.captureMessage(error?.message, 'info');
			});
			window.ga('send', 'exception', {
				'exDescription': 'Something went wrong. We will fix it soon. - Full Screen'
			});
		}
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<div className="error-boundary-wrapper">
					<div className="">
						<h2>Something went wrong</h2>
						<p>We will fix it soon</p>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.object
};

export default ErrorBoundary;